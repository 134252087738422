import { captureException } from "@sentry/react";
import {
  UploadCancelledError,
  useUploadMultiPartFile,
} from "hooks/useUploadMultipartFile/useUploadMultiPartFile";
import { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { _useFileUploadStore } from "./useFileUploadStore";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { QUOTA_TYPES_BY_KEY } from "types/QuotaType";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { readCacheFragment } from "utils/cache-fragments";
import { isEmpty } from "lodash";

/**
 * Management logic for `FileUploadManager`
 */
export const useFileUploadManager = () => {
  const [isBusy, setIsBusy] = useState(false);
  const queue = _useFileUploadStore((s) => s.queue);
  const dequeueFile = _useFileUploadStore((s) => s.dequeueFile);
  const onUploadProgress = _useFileUploadStore((s) => s.onUploadProgress);
  const onUploadComplete = _useFileUploadStore((s) => s.onUploadComplete);
  const onUploadError = _useFileUploadStore((s) => s.onUploadError);
  const { uploadMultiPartFile } = useUploadMultiPartFile();
  const checkTenantQuota = useUserContext((s) => s.checkTenantQuota);

  /**
   * Uploads the next file in the queue
   */
  const uploadNextFile = useCallback(async () => {
    setIsBusy(true);
    const file = dequeueFile();

    try {
      const cachedFile = readCacheFragment({
        __typename: "File",
        id: file.drsFile.id,
      });

      // Check if file was deleted while in queue
      if (cachedFile === null || isEmpty(cachedFile)) {
        throw new UploadCancelledError();
      }

      await uploadMultiPartFile({
        file: {
          id: file.drsFile.id,
          blob: file.blob,
          partSize: file.drsFile.partSize,
          tenantId: file.tenantId,
        },
        onUploadProgress: (progress) => {
          onUploadProgress(file.drsFile.id, progress);
        },
      });
      onUploadComplete(file.drsFile.id);
    } catch (error) {
      onUploadError(file.drsFile.id, error as Error);
      const quotaType = QUOTA_TYPES_BY_KEY["storage"];
      void checkTenantQuota(file.project.tenantId, quotaType);
      if (!(error instanceof UploadCancelledError)) {
        captureException(error);
        addUtilityToastFailure("Failed to upload file");
      }
    }

    setIsBusy(false);
  }, [
    checkTenantQuota,
    dequeueFile,
    onUploadComplete,
    onUploadError,
    onUploadProgress,
    uploadMultiPartFile,
  ]);

  /* Manage the queue */

  useEffect(() => {
    if (queue.length > 0 && !isBusy) {
      void uploadNextFile();
    }
  }, [isBusy, queue.length, uploadNextFile]);

  /* Warn user before unloading page if there are still files that have not
     uploaded */

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (isBusy || queue.length > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [queue.length, isBusy]);
};
