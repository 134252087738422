/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import { memo } from "react";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { isDefined } from "utils/isDefined";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  `,
};

interface CellRendererBaseProps {
  value: CellValue | undefined;
}

/**
 * Cell renderer that renders any cell value
 */
export const CellRendererBase = ({ value }: CellRendererBaseProps) => {
  return (
    <span css={styles.root}>
      {isDefined(value) && <CellValueRendererBase value={value} />}
    </span>
  );
};

export const CellRendererBaseMemo = memo(CellRendererBase);
