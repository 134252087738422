import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ToolBooleanParam,
  ToolChoiceParam,
  ToolCroppingFrameParam,
  ToolFloatRangeParam,
  ToolIntRangeParam,
  ToolParamValue,
  ToolParamsGridRowDatum,
  ToolPathParam,
  ToolRoiFrameParam,
  ToolStringParam,
} from "./ToolParamsGrid.types";
import {
  ToolParamsGridContextValue,
  useToolParamsGridContext,
} from "./ToolParamsGridProvider";
import { parseCroppingFrameParamData } from "components/CroppingFrameSelector/CroppingFrameSelector.helpers";
import { validateToolRoiFrameParamValue } from "components/RoiEditor/RoiEditor.helpers";
import {
  isToolBooleanParam,
  isToolCellStatusParam,
  isToolChoiceParam,
  isToolCroppingFrameParam,
  isToolFloatRangeParam,
  isToolIntRangeParam,
  isToolPathParam,
  isToolRoiFrameParam,
  isToolStringParam,
} from "./ToolParamsGrid.helpers";
import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";
import { create, StoreApi, UseBoundStore, useStore } from "zustand";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { useGetProjectFilesByFileIds } from "./hooks/useGetProjectFilesByFileIds";
import { FileStatus } from "types/constants";
import { parseCellStatusParamData } from "components/CellStatusEditor/CellStatusEditor.helpers";

type GetProjectFilesByFileIds = ReturnType<
  typeof useGetProjectFilesByFileIds
>["getProjectFilesByFileIds"];

/**
 * Validates a value of a `ToolPathParam`
 * @param value
 * @param _param Unused
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolPathParam = (
  value: ToolParamValue,
  _param: ToolPathParam,
  getProjectFilesByFileIds: GetProjectFilesByFileIds,
) => {
  if (!Array.isArray(value)) {
    return new Error('Value must be of type "string array"');
  }
  const files = getProjectFilesByFileIds(value).drsFilesFound;
  if (
    !files.every((file: ProjectFile) => file.status === FileStatus.AVAILABLE)
  ) {
    return new Error("File must be available");
  }
};

/**
 * Validates a value of a `ToolStringParam`
 * @param value
 * @param _param Unused
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolStringParam = (
  value: ToolParamValue,
  _param: ToolStringParam,
) => {
  if (typeof value !== "string") {
    return new Error('Value must be of type "string"');
  }
};

/**
 * Validates a value of a `ToolIntRangeParam`
 * @param value
 * @param param
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolIntRangeParam = (
  value: ToolParamValue,
  param: ToolIntRangeParam,
) => {
  if (typeof value !== "number") {
    return new Error('Value must be of type "number"');
  }

  const { min, max } = param.type;

  if (min !== undefined && value < min) {
    return new Error(`Value must be greater than or equal to ${min}`);
  }

  if (max !== undefined && value > max) {
    return new Error(`Value must be less than or equal to ${max}`);
  }
};

/**
 * Validates a value of a `ToolFloatRangeParam`
 * @param value
 * @param param
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolFloatRangeParam = (
  value: ToolParamValue,
  param: ToolFloatRangeParam,
) => {
  if (typeof value !== "number") {
    return new Error('Value must be of type "number"');
  }

  const { min, max } = param.type;

  if (min !== undefined && value < min) {
    return new Error(`Value must be greater than or equal to ${min}`);
  }

  if (max !== undefined && value > max) {
    return new Error(`Value must be less than or equal to ${max}`);
  }
};

/**
 * Validates a value of a `ToolChoiceParam`
 * @param value
 * @param param
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolChoiceParam = (
  value: ToolParamValue,
  param: ToolChoiceParam,
) => {
  if (typeof value !== "string" && typeof value !== "number") {
    return new Error('Value must be of type "string" or "number"');
  }

  const { choices } = param.type;
  if (!choices.includes(value)) {
    const choicesList = choices.map((choice) => `"${choice}"`).join(", ");
    return new Error(`Value must be one of (${choicesList})`);
  }
};

/**
 * Validates a value of a `ToolBooleanParam`
 * @param value
 * @param _param Unused
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolBooleanParam = (
  value: ToolParamValue,
  _param: ToolBooleanParam,
) => {
  if (typeof value !== "boolean") {
    return new Error('Value must be of type "boolean"');
  }
};

/**
 * Validates a value of a `ToolCroppingFrameParam`
 * @param value
 * @param _param Unused
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolCroppingFrameParam = (
  value: ToolParamValue,
  _param: ToolCroppingFrameParam,
) => {
  try {
    parseCroppingFrameParamData(value);
  } catch (error) {
    return error as Error;
  }
};

/**
 * Validates a value of a `ToolRoiFrameParam`
 * @param value
 * @param param tool param
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolRoiFrameParam = (
  value: ToolParamValue,
  param: ToolRoiFrameParam,
) => {
  if (value !== undefined) {
    const validationState = validateToolRoiFrameParamValue(
      value,
      param.type.roi_settings,
    );
    /**
     * Note: As of 2024/04/18 the validation system on the tool params grid does not support
     * storing multiple errors for a given param key. Errors by param key should really be an array of
     * errors, not a single error, as complex parameter types like ROI entry can have multiple simultaneous errors
     * (shapes overlapping, minimum shapes not met, shapes outside of bounding box, too many shapes, etc).
     *
     * Ideally we would display all validation errors rather than just one at a time, but the extent of changes needed
     * for this at the moment are not worth the time, so for now we will display a single error message directing the user
     * to open the roi editor to see more detailed error state.
     */

    if (validationState.dataFormat !== undefined) {
      return validationState.dataFormat;
    }

    if (validationState.isValid === false) {
      return new Error(
        "Currently saved shapes do not meet tool requirements. Open ROI editor to make adjustments.",
      );
    }
  }
};

/**
 * Validates a value of a `ToolCellStatusParam`
 * @param value
 * @returns An error if the value is invalid, `undefined` otherwise
 */
const validateToolCellStatusParam = (value: ToolParamValue) => {
  if (value !== undefined) {
    try {
      parseCellStatusParamData(value);
    } catch (err) {
      return new Error(
        "Currently saved cell statuses do not meet tool requirements.",
      );
    }
  }
};

const getErrorsByRowId = (
  rowData: ToolParamsGridRowDatum[],
  toolSpec: ToolParamsGridContextValue["toolSpec"],
  getProjectFilesByFileIds: GetProjectFilesByFileIds,
) => {
  const errorsByRowId: Record<string, Record<string, Error | undefined>> = {};

  rowData.forEach((rowDatum) => {
    const errorsByParamKey: Record<string, Error | undefined> = {};

    toolSpec.params.forEach((param) => {
      const value = rowDatum.params[param.key];

      if (isToolPathParam(param)) {
        errorsByParamKey[param.key] = validateToolPathParam(
          value,
          param,
          getProjectFilesByFileIds,
        );
      }

      if (isToolStringParam(param)) {
        errorsByParamKey[param.key] = validateToolStringParam(value, param);
      }

      if (isToolIntRangeParam(param)) {
        errorsByParamKey[param.key] = validateToolIntRangeParam(value, param);
      }

      if (isToolFloatRangeParam(param)) {
        errorsByParamKey[param.key] = validateToolFloatRangeParam(value, param);
      }

      if (isToolChoiceParam(param)) {
        errorsByParamKey[param.key] = validateToolChoiceParam(value, param);
      }

      if (isToolBooleanParam(param)) {
        errorsByParamKey[param.key] = validateToolBooleanParam(value, param);
      }

      if (isToolCroppingFrameParam(param)) {
        errorsByParamKey[param.key] = validateToolCroppingFrameParam(
          value,
          param,
        );
      }

      if (isToolRoiFrameParam(param)) {
        errorsByParamKey[param.key] = validateToolRoiFrameParam(value, param);
      }

      if (isToolCellStatusParam(param)) {
        errorsByParamKey[param.key] = validateToolCellStatusParam(value);
      }

      if (value === undefined) {
        errorsByParamKey[param.key] = param.required
          ? new Error("Value is required")
          : undefined;
      }
    });

    errorsByRowId[rowDatum.id] = errorsByParamKey;
  });
  return errorsByRowId;
};

export type ToolParamsGridValueValidatorContextValue = {
  getRowDatumErrors: (
    rowId: string,
  ) => Record<ToolParamsGridRowDatum["id"], Error | undefined>;
  errorsByRowId: Record<string, Record<string, Error | undefined>>;
};

const ValueValidatorContext = createContext<
  UseBoundStore<StoreApi<ToolParamsGridValueValidatorContextValue>> | undefined
>(undefined);

export const ToolParamsGridValueValidatorContext = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { toolSpec } = useToolParamsGridContext();
  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);
  const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();

  const [store] = useState(() =>
    create<ToolParamsGridValueValidatorContextValue>((set, get) => {
      return {
        getRowDatumErrors: (rowId) => get().errorsByRowId[rowId],
        errorsByRowId: getErrorsByRowId(
          rowData,
          toolSpec,
          getProjectFilesByFileIds,
        ),
      };
    }),
  );

  /**
   * The validation errors by param key for each row datum in the grid
   */
  useEffect(() => {
    const errorsByRowId = getErrorsByRowId(
      rowData,
      toolSpec,
      getProjectFilesByFileIds,
    );

    store.setState({ ...store.getState(), errorsByRowId });
  }, [rowData, store, toolSpec, toolSpec.params, getProjectFilesByFileIds]);

  return (
    <ValueValidatorContext.Provider value={store}>
      {children}
    </ValueValidatorContext.Provider>
  );
};

export const useValueValidator = <S,>(
  selector: (state: ToolParamsGridValueValidatorContextValue) => S,
  comparator?: ((a: S, b: S) => boolean) | undefined,
): S => {
  const valueValidatorContextValue = useContext(ValueValidatorContext);
  assert(
    valueValidatorContextValue !== undefined,
    new ContextOutOfBoundsError("ToolParamsGridValueValidatorContext"),
  );

  return useStore(valueValidatorContextValue, selector, comparator);
};
