/** @jsxImportSource @emotion/react */

import { captureException } from "@sentry/react";
import { useTasksTableQuery } from "graphql/_Types";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import AppLoading from "components/AppLoading/AppLoading";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import {
  FlyoutDrsFileInfo,
  FlyoutDrsFileInfoProps,
} from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { ToolParamValue } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { orderBy } from "lodash";
import { Helmet } from "react-helmet-async";
import { useCallback, useMemo, useState } from "react";
import { formatInterval } from "utils/formatInterval";
import { isNonNull } from "utils/isNonNull";
import { roundToSignificant } from "utils/roundToSignificant";
import { useProjectDataContext } from "../ProjectDataProvider";
import { PageProjectTasksHeader } from "./PageProjectTasksHeader";
import { TasksTable, TasksTableProps, TasksTableTask } from "./TasksTable";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { usePageProjectTasksStyles } from "./usePageProjectTasksStyles";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";

export const PageProjectTasks = () => {
  const styles = usePageProjectTasksStyles();
  const [selectedFile, setSelectedFile] = useState<
    FlyoutDrsFileInfoProps["drsFile"] | undefined
  >();

  const { selectDrsFile, deselectDrsFile } = useSelectedDrsFileId();
  const { project } = useProjectDataContext();

  const onClickFile: NonNullable<TasksTableProps["onClickFile"]> = useCallback(
    (file) => {
      selectDrsFile(file.id);
      setSelectedFile(file);
    },
    [selectDrsFile],
  );

  const { loading, data } = useTasksTableQuery({
    variables: {
      filter: {
        projectId: { equalTo: project.id },
        cloned: {
          notEqualTo: true,
        },
      },
    },
    fetchPolicy: "cache-and-network",
    onError: (err) => captureException(err),
  });

  const tasks = useMemo(() => {
    const tasks = data?.allTasks?.nodes
      .map(
        ({
          id,
          analysisTableRow,
          outputGroup,
          credits,
          user,
          status,
          cloned,
          created,
          taskActivityByTaskId,
          toolVersion,
        }) => {
          const toolSpec = toolVersion?.toolSpec as ToolSpec | undefined | null;
          const table: TasksTableTask["table"] = (() => {
            const table = analysisTableRow?.table;

            const tableGroup = table?.group;

            if (
              analysisTableRow !== null &&
              isNonNullish(table) &&
              isNonNullish(toolSpec) &&
              isNonNullish(tableGroup)
            ) {
              const row = {
                ...analysisTableRow,
                data: analysisTableRow.data as Record<string, ToolParamValue>,
              };
              let name = `${tableGroup.name} (${table.name ?? ""})`.trim();
              if (row.deleted) {
                name += " (Row Deleted)";
              }

              return {
                ...table,
                toolSpec,
                group: tableGroup,
                name,
                resultsByKey: outputGroup?.outputGroupFiles.nodes
                  .flatMap(({ drsFile }) => drsFile)
                  .filter(isNonNull)
                  .reduce<Record<string, string>>((resultsByKey, file) => {
                    if (file.key !== null) {
                      resultsByKey[file.key] = file.id;
                    }
                    return resultsByKey;
                  }, {}),
                row,
              };
            }
          })();

          const interval = taskActivityByTaskId?.duration;

          const durationString = interval ? formatInterval(interval) : "";

          const isSystemUtil = toolSpec?.toolkit === "System Utilities";

          const toolName = (() => {
            if (isNullish(toolSpec)) {
              return "Unknown";
            } else if (isSystemUtil) {
              return toolSpec.name;
            } else {
              return `${toolSpec.name} ${toolSpec.version} (Table Deleted)`;
            }
          })();

          const formattedTask: TasksTableProps["tasks"][number] = {
            id,
            credits: roundToSignificant(credits ?? 0),
            cloned,
            status,
            user,
            created,
            table,
            toolName,
            isSystemUtil,
            duration:
              interval !== undefined
                ? {
                    readableString: durationString,
                    interval,
                  }
                : undefined,
          };
          return formattedTask;
        },
      )
      .filter(isNonNull);
    if (tasks !== undefined) {
      return orderBy([...tasks], [(task) => task.created], ["desc"]);
    }
  }, [data?.allTasks?.nodes]);

  const onCloseFlyout = useCallback(() => {
    setSelectedFile(undefined);
    if (selectedFile) {
      deselectDrsFile(selectedFile.id);
    }
  }, [deselectDrsFile, selectedFile]);

  if (tasks) {
    return (
      <>
        <Helmet title={`${project.name}: All Tasks`} />
        <div css={styles.page}>
          <EuiFlexGroup direction="column">
            <PageProjectTasksHeader />
            <EuiFlexGroup
              responsive={false}
              gutterSize={"none"}
              css={styles.gridAndFlyoutContainer}
            >
              <EuiFlexItem>
                <TasksTable
                  tasks={tasks}
                  project={project}
                  onClickFile={onClickFile}
                />
              </EuiFlexItem>
              {selectedFile && (
                <EuiFlexItem grow={false} css={styles.flyoutContainer}>
                  <FlyoutDrsFileInfo
                    onClose={onCloseFlyout}
                    drsFile={selectedFile}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexGroup>
        </div>
      </>
    );
  }

  if (loading) {
    return <AppLoading />;
  }

  return <CallOutError />;
};
