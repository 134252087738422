import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import {
  FlyoutDrsFileInfo,
  FlyoutDrsFileInfoProps,
} from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import {
  ModalArchiveDrsFile,
  ModalArchiveDrsFileProps,
} from "components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import {
  ModalBreakSeries,
  ModalBreakSeriesProps,
} from "components/ModalBreakSeries/ModalBreakSeries";
import {
  ModalConfirmDestructiveAction,
  ModalConfirmDestructiveActionProps,
} from "components/ModalConfirmDestructiveAction/ModalConfirmDestructiveAction";
import ModalCreateSeries, {
  ModalCreateSeriesProps,
} from "components/ModalCreateSeries/ModalCreateSeries";
import {
  ModalDeleteDrsFile,
  ModalDeleteDrsFileProps,
} from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import {
  ModalDownloadFiles,
  ModalDownloadFilesProps,
} from "components/ModalDownloadFiles/ModalDownloadFiles";
import {
  ModalRenameDrsFile,
  ModalRenameDrsFileProps,
} from "components/ModalRenameDrsFile/ModalRenameDrsFile";
import {
  ModalUnarchiveDrsFiles,
  ModalUnarchiveDrsFilesProps,
} from "components/ModalUnarchiveDrsFile/ModalUnarchiveDrsFile";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { FileStatus } from "types/constants";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { isDefined } from "utils/isDefined";

const ProjectLayoutContext = createContext<
  ProjectLayoutContextValue | undefined
>(undefined);

export const useProjectLayoutContext = () => {
  const value = useContext(ProjectLayoutContext);
  if (value === undefined) {
    throw new ContextOutOfBoundsError("ProjectLayoutContext");
  }
  return value;
};

type ProjectModal =
  | {
      type: "confirmAction";
      props: Pick<ModalConfirmDestructiveActionProps, "onConfirm" | "body"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "deleteDrsFile";
      props: Omit<ModalDeleteDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "archiveDrsFile";
      props: Omit<ModalArchiveDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "unarchiveDrsFile";
      props: Omit<ModalUnarchiveDrsFilesProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "createSeries";
      props: Omit<ModalCreateSeriesProps, "onClose"> & { onClose?: () => void };
    }
  | {
      type: "breakSeries";
      props: Omit<ModalBreakSeriesProps, "onClose"> & { onClose?: () => void };
    }
  | {
      type: "renameDrsFile";
      props: Omit<ModalRenameDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "downloadFiles";
      props: Omit<ModalDownloadFilesProps, "onClose"> & {
        onClose?: () => void;
      };
    };

type ProjectFlyout = {
  type: "fileInfo";
  props: Omit<FlyoutDrsFileInfoProps, "onClose"> & {
    drsFile: FlyoutDrsFileInfoProps["drsFile"] &
      ModalDeleteDrsFileProps["drsFiles"][number] &
      ModalArchiveDrsFileProps["drsFiles"][number];
  };
};

type FlyoutStateBase = {
  node: React.ReactNode;
  toggleSize: () => void;
};

type ProjectFlyoutState = Pick<ProjectFlyout, "type"> &
  FlyoutStateBase & {
    width:
      | {
          size: "s";
          value: 400;
        }
      | {
          size: "l";
          value: 600;
        };
  };

export interface ProjectLayoutContextValue {
  openModal: (modal: ProjectModal) => void;
  openFlyout: (flyout: ProjectFlyout) => void;
  rightFlyout: ProjectFlyoutState | null;
  modal: React.ReactNode | undefined;
}

export interface ProjectLayoutProviderProps {
  children: React.ReactNode;
}

export const ProjectLayoutProvider = ({
  children,
}: ProjectLayoutProviderProps) => {
  const { selectDrsFile, deselectDrsFile, deselectAll, selectedDrsFileId } =
    useSelectedDrsFileId(
      ({ selectDrsFile, deselectDrsFile, deselectAll, selectedDrsFileId }) => ({
        selectDrsFile,
        deselectDrsFile,
        deselectAll,
        selectedDrsFileId,
      }),
    );

  /**
   * Clear selection state on unmount
   */
  useEffect(() => {
    return () => deselectAll();
  }, [deselectAll]);

  const [modal, setModal] = useState<React.ReactNode>();

  const [rightFlyout, setRightFlyout] = useState<ProjectFlyout | null>(null);
  const [rightFlyoutSize, setRightFlyoutSize] = useState<
    ProjectFlyoutState["width"]
  >({ size: "s", value: 400 });

  /**
   * Close info flyout if file is deselected by external action (e.g. deleting a file)
   */
  useEffect(() => {
    if (
      rightFlyout?.type === "fileInfo" &&
      selectedDrsFileId !== rightFlyout.props.drsFile.id
    ) {
      setRightFlyout(null);
    }
  }, [rightFlyout?.props.drsFile.id, rightFlyout?.type, selectedDrsFileId]);

  const onClose = useCallback(() => {
    setRightFlyout(null);
  }, []);

  const openFlyout: ProjectLayoutContextValue["openFlyout"] = useCallback(
    (flyout) => {
      if (flyout.type === "fileInfo") {
        selectDrsFile(flyout.props.drsFile.id);
      }
      setRightFlyout(flyout);
    },
    [selectDrsFile],
  );

  /**
   * Opens a modal
   * @param modal
   */
  const openModal = (modal: ProjectModal) => {
    const { type } = modal;
    const handleClose = () => {
      const onClose = modal.props?.onClose;
      if (isDefined(onClose)) {
        onClose();
      }
      setModal(null);
    };

    switch (type) {
      case "confirmAction":
        setModal(
          <ModalConfirmDestructiveAction
            onCancel={handleClose}
            onConfirm={(e) => {
              modal.props.onConfirm(e);
              handleClose();
            }}
            body={modal.props.body}
          />,
        );
        break;
      case "deleteDrsFile":
        setModal(<ModalDeleteDrsFile {...modal.props} onClose={handleClose} />);
        break;
      case "archiveDrsFile":
        setModal(
          <ModalArchiveDrsFile {...modal.props} onClose={handleClose} />,
        );
        break;
      case "unarchiveDrsFile":
        setModal(
          <ModalUnarchiveDrsFiles {...modal.props} onClose={handleClose} />,
        );
        break;
      case "createSeries":
        setModal(<ModalCreateSeries {...modal.props} onClose={handleClose} />);
        break;

      case "breakSeries":
        setModal(<ModalBreakSeries {...modal.props} onClose={handleClose} />);
        break;
      case "renameDrsFile":
        setModal(<ModalRenameDrsFile {...modal.props} onClose={handleClose} />);
        break;
      case "downloadFiles":
        setModal(<ModalDownloadFiles {...modal.props} onClose={handleClose} />);
        break;
    }
  };

  const rightFlyoutState: ProjectFlyoutState | null = useMemo(() => {
    const toggleSize = () => {
      setRightFlyoutSize((prev) =>
        prev.size === "s"
          ? { size: "l", value: 600 }
          : { size: "s", value: 400 },
      );
    };

    if (rightFlyout === null) {
      return null;
    }

    const node = (() => {
      switch (rightFlyout.type) {
        case "fileInfo":
          return (
            <FlyoutDrsFileInfo
              {...rightFlyout.props}
              additionalControls={
                <>
                  {rightFlyout.props.drsFile.status ===
                  FileStatus["ARCHIVED"] ? (
                    <>
                      <ButtonIconPermissioned
                        aria-label="Unarchive file"
                        color="primary"
                        iconType="tear"
                        disabled={
                          !getDrsFileModifyPermissionByDrsFileAndAction(
                            rightFlyout.props.drsFile,
                            "UNARCHIVE",
                          ).isPermitted
                        }
                        onClick={() => {
                          openModal({
                            type: "unarchiveDrsFile",
                            props: { drsFiles: [rightFlyout.props.drsFile] },
                          });
                        }}
                        requiredPermission="edit"
                      />
                    </>
                  ) : (
                    <>
                      <ButtonIconPermissioned
                        aria-label="Archive file"
                        color="primary"
                        iconType="snowflake"
                        disabled={
                          !getDrsFileModifyPermissionByDrsFileAndAction(
                            rightFlyout.props.drsFile,
                            "ARCHIVE",
                          ).isPermitted
                        }
                        onClick={() => {
                          openModal({
                            type: "archiveDrsFile",
                            props: { drsFiles: [rightFlyout.props.drsFile] },
                          });
                        }}
                        requiredPermission="edit"
                      />
                    </>
                  )}{" "}
                  <ButtonIconPermissioned
                    aria-label="Delete file"
                    color="danger"
                    iconType="trash"
                    disabled={
                      !getDrsFileModifyPermissionByDrsFileAndAction(
                        rightFlyout.props.drsFile,
                        "DELETE",
                      ).isPermitted ||
                      !getDrsFileModifyPermissionByDrsFileAndAction(
                        rightFlyout.props.drsFile,
                        "DATA_DELETE",
                      ).isPermitted
                    }
                    onClick={() => {
                      openModal({
                        type: "deleteDrsFile",
                        props: {
                          drsFiles: [rightFlyout.props.drsFile],
                        },
                      });
                    }}
                    requiredPermission="edit"
                  />
                </>
              }
              onClose={() => {
                deselectDrsFile(rightFlyout.props.drsFile.id);
                onClose();
              }}
            />
          );
      }
    })();

    return { ...rightFlyout, node, toggleSize, width: rightFlyoutSize };
  }, [deselectDrsFile, onClose, rightFlyout, rightFlyoutSize]);

  return (
    <ProjectLayoutContext.Provider
      value={{
        openModal,
        openFlyout,
        modal,
        rightFlyout: rightFlyoutState,
      }}
    >
      {children}
    </ProjectLayoutContext.Provider>
  );
};
