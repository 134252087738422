/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiBottomBar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiThemeProvider,
} from "@inscopix/ideas-eui";
import { StatusPanelAddRow } from "components/ToolParamsGrid/hooks/useStatusBar";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { AnalysisTableStartJobs } from "./AnalysisTableStartJobs";

interface AnalysisTableBottomBarProps {
  selectedTableId: string | undefined;
}

export const AnalysisTableBottomBar = ({
  selectedTableId,
}: AnalysisTableBottomBarProps) => {
  const { project } = useProjectDataContext();

  return (
    <EuiBottomBar
      css={css`
        border: 1px solid #babfc7;
        border-top: 0px;
        background: #f8f8f8;
        box-shadow: none;
      `}
      position="static"
      bottom={0}
    >
      <EuiThemeProvider colorMode={"light"}>
        <EuiFlexGroup justifyContent="spaceBetween" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <StatusPanelAddRow />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" responsive={false}>
              <EuiFlexItem grow={false}>
                {selectedTableId !== undefined && (
                  <AnalysisTableStartJobs
                    projectId={project.id}
                    projectKey={project.key}
                    analysisTableId={selectedTableId}
                  />
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiThemeProvider>
    </EuiBottomBar>
  );
};
