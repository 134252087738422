/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiSelectable,
  EuiSelectableOption,
} from "@inscopix/ideas-eui";
import Plot, { PlotParams } from "react-plotly.js";
import { useState } from "react";
import { useCellStatusContext } from "../CellStatusEditorProvider";
import {
  CellStatusEditorCellMetrics,
  cellStatusEditorCellMetricTypeLabels,
} from "../CellStatusEditor.types";
import { isUndefined, omitBy } from "lodash";
import {
  getCellStatusColor,
  selectionColor,
} from "../CellStatusEditor.helpers";
import { isDefined } from "utils/isDefined";

type AxisOptions = {
  key: string;
  label: string;
  checked: "on" | undefined;
}[];

export const ScatterPlot = () => {
  const cells = useCellStatusContext((s) => s.cells);
  const cellStatuses = useCellStatusContext((s) => s.cellStatuses);
  const cellMetrics = useCellStatusContext((s) => s.cellMetrics);
  const selectedCells = useCellStatusContext((s) => s.selectedCells);
  const setSelectedCells = useCellStatusContext((s) => s.setSelectedCells);

  const [isXPopoverOpen, setIsXPopoverOpen] = useState(false);
  const [isYPopoverOpen, setIsYPopoverOpen] = useState(false);

  // only show metrics we have values for as options
  const metrics = isDefined(cellMetrics)
    ? omitBy(cellStatusEditorCellMetricTypeLabels, (_, key) =>
        isUndefined(cellMetrics[key as keyof CellStatusEditorCellMetrics]),
      )
    : {};
  const metricXOptions: AxisOptions = Object.entries(metrics).map(
    ([key, value], index) => ({
      key: key,
      label: value,
      checked: index === 0 ? "on" : undefined,
    }),
  );
  const metricYOptions: AxisOptions = Object.entries(metrics).map(
    ([key, value], index) => ({
      key: key,
      label: value,
      checked: index === 1 ? "on" : undefined,
    }),
  );
  const [xOptions, setXOptions] =
    useState<EuiSelectableOption[]>(metricXOptions);
  const [yOptions, setYOptions] =
    useState<EuiSelectableOption[]>(metricYOptions);

  // need at least two metrics to plot
  if (cellMetrics === undefined || Object.keys(cellMetrics).length <= 1) {
    return (
      <EuiCallOut
        title="Cell metrics not available"
        color="primary"
        iconType="alert"
        style={{ margin: "20px" }}
      />
    );
  }

  // EUI nonsense to figure out which options are checked
  const selectedX = xOptions.find((option) => option.checked === "on")?.key;
  const selectedY = yOptions.find((option) => option.checked === "on")?.key;

  // for scatter plots, plotly wants all colors in an array to index on
  const markerColors = cellStatuses.map((status) => {
    return getCellStatusColor(status, 0.8);
  });

  // this looks a little crazy - but all it's trying to do is either retrieve the values
  // for the selected metrics, or if the selected metric is not available, the first and second available metrics
  // remember we checked length > 1 above
  const x =
    cellMetrics[selectedX as keyof CellStatusEditorCellMetrics] ??
    cellMetrics[
      Object.keys(cellMetrics)[0] as keyof CellStatusEditorCellMetrics
    ];
  const y =
    cellMetrics[selectedY as keyof CellStatusEditorCellMetrics] ??
    cellMetrics[
      Object.keys(cellMetrics)[0] as keyof CellStatusEditorCellMetrics
    ];

  if (x === undefined || y === undefined) {
    return null;
  }

  const data: PlotParams["data"] = [
    {
      // plotly takes the list of cell names/labels to index against x, y, colors
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore the type definitions for plotly are not up to date
      name: cells.map((cell) => cell.name),
      text: cells.map((cell) => cell.name),
      x: x,
      y: y,
      type: "scatter",
      showLegend: false,
      mode: "markers",
      // style markers from selection data
      marker: {
        autocolorscale: false,
        color: markerColors,
        line: {
          color: cells.map((cell) =>
            selectedCells.has(cell.index) ? selectionColor : "transparent",
          ),
          width: cells.map((cell) => (selectedCells.has(cell.index) ? 3 : 0)),
        },
      },
      // style selected cells natively in plotly  - doing it both ways makes it so the style applies
      // during the selection drag and not just after release
      selectedpoints:
        selectedCells.size > 0 ? Array.from(selectedCells) : undefined,
      selected: {
        marker: {
          line: {
            color: selectionColor,
            width: 3,
          },
        },
      },
      // fade unselected cells - only appies when a selection exists
      unselected: {
        marker: {
          opacity: 0.5,
        },
      },
    },
  ];

  const handleSelectCell = (event: { points: { pointNumber: number }[] }) => {
    const cellId = event.points[0].pointNumber;
    setSelectedCells([cellId]);
  };

  return (
    <EuiFlexGroup gutterSize="none" responsive={false}>
      <EuiFlexItem
        grow={false}
        css={css`
          width: 30px;
          writing-mode: vertical-lr;
          justify-content: center;
          transform: rotate(180deg);
          align-items: center;
        `}
      >
        <EuiPopover
          panelPaddingSize="none"
          button={
            <EuiButtonEmpty
              iconType="arrowDown"
              iconSide="right"
              onClick={() => setIsYPopoverOpen(!isYPopoverOpen)}
            >
              {metrics[selectedY as string]}
            </EuiButtonEmpty>
          }
          isOpen={isYPopoverOpen}
          closePopover={() => setIsYPopoverOpen(false)}
        >
          <EuiSelectable
            aria-label="Set Y"
            searchable={false}
            singleSelection="always"
            options={yOptions}
            onChange={(newOptions) => {
              setYOptions(newOptions);
              setIsYPopoverOpen(false);
            }}
            css={css`
              width: 240px;
            `}
          >
            {(list) => list}
          </EuiSelectable>
        </EuiPopover>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          gutterSize="s"
          direction="column"
          justifyContent="center"
          alignItems="center"
          css={css`
            flex-grow: 0;
            padding-bottom: 10px;
          `}
          responsive={false}
        >
          <EuiFlexItem
            grow={true}
            css={css`
              min-width: 300px;
            `}
          >
            <Plot
              data={data}
              layout={{
                width: 500,
                height: 420,
                margin: { t: 0, r: 0, b: 25, l: 25 },
                font: {
                  family: `InterVariable, "Inter var", Inter, -apple-system, "system-ui", "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif`,
                },
                yaxis: {
                  ticks: "outside",
                },
                xaxis: {
                  ticks: "outside",
                },
                showlegend: false,
              }}
              onClick={handleSelectCell}
              onSelected={(event: { points: { pointNumber: number }[] }) => {
                if (event.points.length !== 0) {
                  setSelectedCells(
                    event.points.map((point) => point.pointNumber),
                  );
                }
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={true}>
            <EuiPopover
              panelPaddingSize="none"
              button={
                <EuiButtonEmpty
                  iconType="arrowDown"
                  iconSide="right"
                  onClick={() => setIsXPopoverOpen(!isXPopoverOpen)}
                  css={css`
                    width: 200px;
                  `}
                >
                  {metrics[selectedX as string]}
                </EuiButtonEmpty>
              }
              isOpen={isXPopoverOpen}
              closePopover={() => setIsXPopoverOpen(false)}
            >
              <EuiSelectable
                aria-label="Set X"
                searchable={false}
                singleSelection="always"
                options={xOptions}
                onChange={(newOptions) => {
                  setXOptions(newOptions);
                  setIsXPopoverOpen(false);
                }}
                css={css`
                  width: 240px;
                `}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
