import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiTab,
  EuiTabs,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { Task } from "graphql/_Types";
import { useEffect, useState } from "react";
import { LazyLog } from "react-lazylog";
import { TaskStatus } from "types/constants";
import { ButtonDownloadTaskLog } from "./ButtonDownloadTaskLog";
import { useTaskLogs } from "./useTaskLogs";

export interface ModalTaskLogsBodyLogsProps {
  task: Pick<Task, "id" | "status" | "created">;
}

export const ModalTaskLogsBodyLogs = ({ task }: ModalTaskLogsBodyLogsProps) => {
  const {
    loading: loadingTaskLogUrls,
    error: taskLogUrlError,
    data: taskLogUrlData,
  } = useTaskLogs(task);

  const [selectedTabId, setSelectedTabId] = useState<
    "log-stdout" | "log-stderr" | undefined
  >(undefined);

  // set selected tab as appropriate
  useEffect(() => {
    if (!loadingTaskLogUrls && taskLogUrlData !== undefined) {
      if (taskLogUrlData.stdOutUrl !== undefined) {
        setSelectedTabId("log-stdout");
      } else if (taskLogUrlData.stdErrUrl !== undefined) {
        setSelectedTabId("log-stderr");
      } else {
        captureException("Unexpected state in ModalTaskLogs");
      }
    }
  }, [loadingTaskLogUrls, taskLogUrlData]);

  if (loadingTaskLogUrls) {
    return <EuiSkeletonText lines={3} />;
  }
  if (taskLogUrlError) {
    return <CallOutError>Failed to fetch task logs</CallOutError>;
  }
  return (
    <>
      <EuiTabs>
        <EuiTab
          key={"log-stdout"}
          onClick={() => setSelectedTabId("log-stdout")}
          isSelected={"log-stdout" === selectedTabId}
        >
          stdout
        </EuiTab>
        <EuiTab
          key={"log-stderr"}
          onClick={() => setSelectedTabId("log-stderr")}
          isSelected={"log-stderr" === selectedTabId}
          disabled={task.status === TaskStatus["COMPLETE"]}
        >
          stderr
        </EuiTab>
      </EuiTabs>
      <EuiFlexGroup
        direction="column"
        style={{ height: "calc(100% - 40px)", minHeight: 100 }}
        gutterSize="none"
      >
        {taskLogUrlData?.stdOutUrl !== undefined &&
          "log-stdout" === selectedTabId && (
            <EuiFlexItem grow>
              <LazyLog
                selectableLines
                url={taskLogUrlData.stdOutUrl}
                containerStyle={{ height: "100%", overflow: "auto" }}
              />
            </EuiFlexItem>
          )}
        {taskLogUrlData?.stdErrUrl !== undefined &&
          "log-stderr" === selectedTabId && (
            <EuiFlexItem grow>
              <LazyLog
                selectableLines
                url={taskLogUrlData.stdErrUrl}
                containerStyle={{ height: "100%", overflow: "auto" }}
              />
            </EuiFlexItem>
          )}
        <EuiSpacer size="s" />
        <EuiFlexItem grow={false}>
          <EuiFlexGroup>
            {taskLogUrlData?.stdOutUrl !== undefined &&
              selectedTabId === "log-stdout" && (
                <EuiFlexItem grow={false}>
                  <ButtonDownloadTaskLog
                    downloadFileName={"stdout"}
                    downloadUrl={taskLogUrlData.stdOutUrl}
                  />
                </EuiFlexItem>
              )}

            {taskLogUrlData?.stdErrUrl !== undefined &&
              selectedTabId === "log-stderr" && (
                <EuiFlexItem grow={false}>
                  <ButtonDownloadTaskLog
                    downloadFileName={"stderr"}
                    downloadUrl={taskLogUrlData.stdErrUrl}
                  />
                </EuiFlexItem>
              )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
