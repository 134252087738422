import { gql } from "@apollo/client";
import { useModalToolRoiFrameParamQuery } from "graphql/_Types";
import { chain } from "lodash";
import { useMemo, useState } from "react";
import { DIMENSIONAL_METADATA_KEYS } from "types/MetadataMap";
import { isNonNull } from "utils/isNonNull";

gql`
  query ModalToolRoiFrameParam($fileIds: [UUID!]!, $metadatumKeys: [String!]!) {
    files: allFiles(filter: { id: { in: $fileIds } }) {
      nodes {
        id
        name
        source
        isSeries
        metadata: fileMetadataByFileId(
          filter: { metadatumByMetadataId: { key: { in: $metadatumKeys } } }
        ) {
          nodes {
            id
            metadatum: metadatumByMetadataId {
              id
              key
              value: activeValue
            }
          }
        }
        seriesMembers: filesBySeriesParentId {
          nodes {
            id
            name
            source
            metadata: fileMetadataByFileId(
              filter: { metadatumByMetadataId: { key: { in: $metadatumKeys } } }
            ) {
              nodes {
                id
                metadatum: metadatumByMetadataId {
                  id
                  key
                  value: activeValue
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useModalToolRoiFrameParamData = (fileIds: string[]) => {
  const [error, setError] = useState<Error>();
  const { data, loading } = useModalToolRoiFrameParamQuery({
    // Load times are not a concern so we can afford to omit caching and save on
    // memory and unnecessary rerenders
    fetchPolicy: "no-cache",
    variables: {
      fileIds,
      metadatumKeys: Object.values(DIMENSIONAL_METADATA_KEYS),
    },
    onCompleted: ({ files }) => {
      if (files === null) {
        setError(new Error("Missing query data"));
      }
    },
    onError: setError,
  });

  const formattedData = useMemo(() => {
    const files = chain(data?.files?.nodes ?? [])
      .map((file) => {
        return {
          id: file.id,
          name: file.name,
          source: file.source,
          isSeries: file.isSeries,
          metadata: file.metadata.nodes
            .map(({ metadatum }) => metadatum)
            .filter(isNonNull),
          seriesMembers: file.seriesMembers.nodes.map((member) => ({
            id: member.id,
            name: member.name,
            source: member.source,
            isSeries: false as const,
            metadata: member.metadata.nodes
              .map(({ metadatum }) => metadatum)
              .filter(isNonNull),
          })),
        };
      })
      .value();

    const fileIdsNotFound = fileIds.filter(
      (id) => !files.some((file) => file.id === id),
    );

    return { files, fileIdsNotFound };
  }, [data?.files?.nodes, fileIds]);

  return { ...formattedData, isLoading: loading, error };
};
