import { captureException } from "@sentry/react";
import assert from "assert";
import { Canvas } from "components/RoiEditor/RoiEditorCanvas";
import { ToolRoiFrameParam } from "components/ToolParamsGrid/ToolParamsGrid.types";
import * as fabric from "fabric";
import { chain, set } from "lodash";
import {
  ShapeJson,
  ToolRoiFrameParamValue,
} from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { isToolRoiFrameParamValue } from "types/ToolRoiFrameParamValue/isToolRoiFrameParamValue";
import { uuid } from "utils/uuid";
import { Point, ShapeType } from "./RoiEditor";

export const getPointFromMouseEvent = (
  e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
) => {
  return { x: e.absolutePointer.x, y: e.absolutePointer.y };
};

export interface IsxShapeOptions {
  groupKey?: string;
  name?: string;
}

export const defaultShapeProps = {
  strokeWidth: 2,
  strokeUniform: true,
  stroke: "#EB823B",
  fill: "transparent",
} satisfies Partial<fabric.FabricObjectProps>;

function applyDefaultShapeHandlers(object: fabric.Object) {
  let prevStroke: number;
  object.on("added", () => {
    prevStroke = object.strokeWidth;

    // adjust stroke width for scale so shape borders aren't thicker on a zoomed canvas
    const zoom = object.canvas?.getZoom() ?? 1;
    object.set({ strokeWidth: object.strokeWidth / zoom });
  });

  object.on("removed", () => {
    object.set({ strokeWidth: prevStroke });
  });
}

/**
 * Custom classes for fabric shapes to extend functionality and
 * narrow typing for our implementation
 * - applies default props
 * - adds id, groupKey, name as new properties
 * - adds shapeType to allow checking shape type in a switch statement where instanceof can't be used
 * - overwrites the type of canvas attached to an object to be our custom fabric canvas class
 * - constructor returns a proxy of the fabric object that calls a custom canvas onObjectPropertyChanged method
 *   which can be used watch objects for updates so we can have reactive state updates when objects are directly manipulated on the canvas
 */

export class Polyline extends fabric.Polyline {
  id: string;
  groupKey?: string;
  name?: string;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */

  jsonify() {
    /**
     * We don't currently support polylines as saved shapes
     */
    captureException("Not implemented");
    // void is treated as a separate type from undefined for class methods
    return undefined;
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Polyline>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;
    this.id = uuid();
    this.groupKey = groupKey;
    this.name = name;

    this.set({ ...defaultShapeProps, ...props });

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class Line extends fabric.Line {
  id: string;
  groupKey?: string;
  name?: string;
  shapeType = "line" as const;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */

  jsonify() {
    /**
     * We don't currently support lines as saved shapes
     */
    captureException("Not implemented");
    // void is treated as a separate type from undefined for class methods
    return undefined;
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Line>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;

    this.id = uuid();
    this.name = name;
    this.groupKey = groupKey;
    this.set({
      ...defaultShapeProps,
      // apply explicitly set props over defaults
      ...(props[1] satisfies
        | fabric.TOptions<fabric.SerializedObjectProps>
        | undefined),
    });

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class Rect extends fabric.Rect {
  id: string;
  shapeType = "rectangle" as const;
  isCurrentlyBeingDrawn = false;
  groupKey?: string;
  name?: string;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  jsonify(): ShapeJson {
    const json = {
      groupKey: this.groupKey ?? "",
      left: this.left,
      top: this.top,
      width: this.width,
      height: this.height,
      name: this.name ?? "",
      stroke: this.stroke,
      rotation: this.angle,
      type: "rectangle" as const,
    };

    // adjust for background image offset due to bounding box
    json.top -= this.canvas?.backgroundImage?.top ?? 0;
    json.left -= this.canvas?.backgroundImage?.left ?? 0;

    return json;
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Rect>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;
    this.id = uuid();
    this.name = name;
    this.groupKey = groupKey;

    this.set({
      ...defaultShapeProps,
      // apply explicitly set props over defaults
      ...(props[0] satisfies fabric.TOptions<fabric.RectProps>),
      name,
      groupKey,
    });

    this.on("modified", (e) => {
      // calculate new coordinates from scale factor
      this.set({
        height: this.getScaledHeight() - this.strokeWidth,
        width: this.getScaledWidth() - this.strokeWidth,
        top: this.top,
        left: this.left,
        scaleX: 1,
        scaleY: 1,
      });
    });

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class BoundingBox extends Rect {
  stroke: string = defaultShapeProps.stroke;
  static DEFAULT_STROKE_WIDTH = 6;
  static DEFAULT_STROKE_COLOR = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */

  jsonify() {
    return {
      groupKey: this.groupKey ?? "",
      left: this.left,
      top: this.top,
      width: this.width - this.strokeWidth,
      height: this.height - this.strokeWidth,
      name: this.name ?? "",
      stroke: this.stroke,
      type: "boundingBox" as const,
    };
  }

  constructor(...props: ConstructorParameters<typeof Rect>) {
    super(...props);

    this.strokeWidth = BoundingBox.DEFAULT_STROKE_WIDTH;
    this.lockRotation = true;

    /**
     * Make sure the bounding box is the last layer since it will usually enclose every other shape
     */
    this.on("added", () => {
      this.canvas?.sendObjectToBack(this);

      const onAdded = () => {
        this.canvas?.sendObjectToBack(this);
      };
      this.canvas?.on("object:added", onAdded);

      this.on("removed", () => {
        this.canvas?.off("object:added", onAdded);
      });
    });
  }
}

/**
 * Abstract class for functionality shared between shape types
 * that are fundamentally a list of points (Polygon, Polyline, Contour)
 */
abstract class ListOfPoints extends fabric.Polygon {
  id: string;
  abstract shapeType: Extract<ShapeType, "polygon" | "contour">;
  isCurrentlyBeingDrawn = false;
  groupKey?: string;
  name?: string;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */
  jsonify(): Omit<
    Extract<ShapeJson, { type: "polygon" } | { type: "contour" }>,
    "type"
  > {
    /**
     * Note about using set:
     * This method should really never modify the source object as it's meant to return
     * a representation in a ready-only manner
     *
     * Apparently being able to clone a shape is too much to ask of fabric
     *
     * I'm getting errors when trying to clone the polygon. For now just
     * going to set the offset then unset it after calculating new points
     *
     * There may also be a way to just adjust the transform matrix, but given
     * this works and I don't want to burn time messing with the matrix right now,
     * I'll leave as is, but adjusting the calculated matrix would be safer as it
     * avoids ever modifying the source object to begin with
     */

    // adjust for background image offset due to bounding box
    this.set({
      left: this.left - (this.canvas?.backgroundImage?.left ?? 0),
      top: this.top - (this.canvas?.backgroundImage?.top ?? 0),
    });
    // https://stackoverflow.com/questions/38999034/how-does-transforming-points-with-a-transformmatrix-work-in-fabricjs/53710375#53710375
    const matrix = this.calcTransformMatrix();

    const transformedPoints = this.points
      .map((p) => {
        return new fabric.Point(
          p.x - this.pathOffset.x,
          p.y - this.pathOffset.y,
        );
      })
      .map((p) => {
        return fabric.util.transformPoint(p, matrix);
      });

    // put offsets back since we just want to modify the output not what's drawn on the canvas
    // see top for explanation of why we are doing this annoying back and forth
    this.set({
      left: this.left + (this.canvas?.backgroundImage?.left ?? 0),
      top: this.top + (this.canvas?.backgroundImage?.top ?? 0),
    });

    return {
      groupKey: this.groupKey ?? "",
      name: this.name ?? "",
      stroke: this.stroke,
      // adjust for background image offset due to bounding box
      points: transformedPoints,
    };
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Polygon>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;
    this.id = uuid();
    this.groupKey = groupKey;

    this.set({
      ...defaultShapeProps,
      // apply explicitly set props over defaults
      ...(props[1] satisfies
        | fabric.TOptions<fabric.SerializedObjectProps>
        | undefined),
      name,
    });
  }
}

export class Polygon extends ListOfPoints {
  shapeType = "polygon" as const;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */
  jsonify() {
    const points = super.jsonify();
    return { ...points, type: "polygon" as const };
  }

  constructor(...props: ConstructorParameters<typeof ListOfPoints>) {
    super(...props);

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class Contour extends ListOfPoints {
  shapeType = "contour" as const;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */
  jsonify(): Extract<ShapeJson, { type: "contour" }> {
    const polygon = super.jsonify();
    return {
      ...polygon,
      type: "contour" as const,
      // all contours closed but want to leave this available
      // in case we have an open contour in the future
      closed: true,
    };
  }

  constructor(...props: ConstructorParameters<typeof ListOfPoints>) {
    super(...props);
    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class Circle extends fabric.Circle {
  id: string;
  isCurrentlyBeingDrawn = false;
  groupKey?: string;
  name?: string;
  shapeType: ShapeType;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */

  jsonify(): Extract<ShapeJson, { type: "circle" }> {
    const absoluteCenter = this.getCenterPoint();
    return {
      groupKey: this.groupKey ?? "",
      name: this.name ?? "",
      stroke: this.stroke,
      type: "circle",
      center: {
        // adjust for background image offset due to bounding box
        x: absoluteCenter.x - (this.canvas?.backgroundImage?.left ?? 0),
        y: absoluteCenter.y - (this.canvas?.backgroundImage?.top ?? 0),
      },
      radius: this.radius,
    };
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Circle>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;
    this.id = uuid();
    this.shapeType = "circle";

    this.name = name;
    this.groupKey = groupKey;

    /**
     * Fabric internally applies scale to the object when resizing
     * This parses the scale and instead converts it to new coordinates
     */
    this.on("modified", (e) => {
      const scaledHeight = e.transform.target.getScaledHeight();
      const newRadius = (scaledHeight - e.transform.target.strokeWidth) / 2;
      this.set({ radius: newRadius, scaleX: 1, scaleY: 1 });
    });

    this.set({ ...defaultShapeProps, ...props });

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export class Ellipse extends fabric.Ellipse {
  id: string;
  isCurrentlyBeingDrawn = false;
  groupKey?: string;
  name?: string;
  shapeType = "ellipse" as const;
  canvas: Canvas | undefined;
  stroke: string = defaultShapeProps.stroke;

  /**
   * Convert self to JSON representation
   * @returns a json representation of this shape in the IDEAS format expected by tools
   */

  jsonify(): Extract<ShapeJson, { type: "ellipse" }> {
    const absoluteCenter = this.getCenterPoint();

    return {
      groupKey: this.groupKey ?? "",
      name: this.name ?? "",
      stroke: this.stroke,
      type: "ellipse",
      center: {
        // adjust for background image offset due to bounding box
        x: absoluteCenter.x - (this.canvas?.backgroundImage?.left ?? 0),
        y: absoluteCenter.y - (this.canvas?.backgroundImage?.top ?? 0),
      },
      rx: this.rx,
      ry: this.ry,
      rotation: this.angle,
    };
  }

  constructor(
    options: IsxShapeOptions,
    ...props: ConstructorParameters<typeof fabric.Ellipse>
  ) {
    super(...props);
    applyDefaultShapeHandlers(this);

    const { groupKey, name } = options;
    this.id = uuid();

    this.name = name;
    this.groupKey = groupKey;

    this.set({
      ...defaultShapeProps,
      // apply explicitly set props over defaults
      ...(props[0] satisfies
        | fabric.TOptions<fabric.SerializedObjectProps>
        | undefined),
    });

    /**
     * Fabric internally applies scale to the object when resizing
     * This parses the scale and instead converts it to new coordinates
     */
    this.on("modified", () => {
      this.set({
        rx: this.rx * this.scaleX,
        ry: this.ry * this.scaleY,
        scaleX: 1,
        scaleY: 1,
      });
    });

    return new Proxy(this, {
      set: (target, p, v) => {
        const onObjectPropertyChanged = (this as Shape | undefined)?.canvas
          ?.onObjectPropertyChanged;

        if (onObjectPropertyChanged !== undefined) {
          onObjectPropertyChanged(
            target,
            p as keyof typeof target,
            v as (typeof target)[keyof typeof target],
          );
        }

        return Reflect.set(target, p, v);
      },
    });
  }
}

export const isPointInsideCircle = (point: Point, circle: fabric.Circle) => {
  const centerPoint = circle.getCenterPoint();
  return (
    (point.x - centerPoint.x) ** 2 + (point.y - centerPoint.y) ** 2 <=
    circle.radius ** 2
  );
};

export type Shape =
  | Circle
  | Rect
  | Ellipse
  | Polygon
  | Line
  | Polyline
  | BoundingBox
  | Contour;

export type RoiShape = Exclude<
  Shape,
  // Polyline is only used as an intermediate shape '
  // when actively drawing polygons
  Polyline
>;

type GroupKey = NonNullable<
  ToolRoiFrameParam["type"]["roi_settings"]["groups"]
>[number]["key"];

/**
 * Validates shape data against tool param
 * @param shapes JSON formatted shapes
 * @param toolParam roi param for tool
 * @returns object containing validation state
 */
export const validateToolRoiFrameParamValue = (
  paramValue: unknown,
  roiSettings: ToolRoiFrameParam["type"]["roi_settings"],
) => {
  const validationErrors: {
    isValid: boolean;
    dataFormat?: Error;
    boundingBox?: Error;
    groups?: {
      [key: GroupKey]: {
        minNotMet?: Error;
        maxExceeded?: Error;
      };
    };
  } = {
    isValid: true,
  };

  /**
   * Parses param value into ShapeJson object
   * @throws if data cannot be parsed as valid ShapeJson type
   * @returns validated ShapeJson formatted data
   */
  const parseParamValue = (paramValue: unknown) => {
    try {
      assert(typeof paramValue === "string");
      const jsonData: unknown = JSON.parse(paramValue);
      const isValidFormat = isToolRoiFrameParamValue(jsonData);
      assert(isValidFormat);
      return jsonData;
    } catch (err) {
      throw new Error("Saved data is incorrectly formatted");
    }
  };

  let shapes: ToolRoiFrameParamValue | undefined;

  /**
   * Validate data structure
   */
  try {
    shapes = parseParamValue(paramValue);
  } catch (err) {
    validationErrors.dataFormat = err as Error;
    validationErrors.isValid = false;
    return validationErrors;
  }

  /**
   * Validate roi groups
   */
  if (roiSettings.groups !== undefined) {
    const shapeCountByGroupKey: {
      [key: (typeof shapes)[number]["groupKey"]]: number | undefined;
    } = chain(shapes)
      .groupBy((shape) => shape.groupKey)
      .mapValues((shapesInGroup) => shapesInGroup.length)
      .value();

    /**
     * Note: no need to handle whether param data is entirely undefined because
     * absence of a required parameter is handled by the analysis table itself
     */

    /**
     * Validate bounding box
     */
    const isBoundingBoxRequired = roiSettings.bounding_box !== undefined;
    const isBoundingBoxSpecified = shapes.some(
      (shape) => shape.type === "boundingBox",
    );
    if (isBoundingBoxRequired && !isBoundingBoxSpecified) {
      validationErrors.boundingBox = new Error("Bounding box required");
    }

    /**
     * Validate shape groups
     */
    roiSettings.groups.reduce((validationErrors, group) => {
      const max = group.max;
      const min = group.min;

      if (max !== undefined) {
        if ((shapeCountByGroupKey[group.key] ?? 0) > max) {
          set(
            validationErrors,
            [
              "groups" satisfies keyof typeof validationErrors,
              group.key,
              "maxExceeded" satisfies keyof NonNullable<
                (typeof validationErrors)["groups"]
              >[string],
            ],
            new Error(
              `Maximum of ${max} shapes exceeded for group ${group.name}`,
            ) satisfies NonNullable<
              (typeof validationErrors)["groups"]
            >[string]["maxExceeded"],
          );
        }
      }

      if (min !== undefined) {
        if ((shapeCountByGroupKey[group.key] ?? 0) < min) {
          set(
            validationErrors,
            [
              "groups" satisfies keyof typeof validationErrors,
              group.key,
              "minNotMet" satisfies keyof NonNullable<
                (typeof validationErrors)["groups"]
              >[string],
            ],
            new Error(
              `Minimum of ${min} shapes not met for group ${group.name}`,
            ) satisfies NonNullable<
              (typeof validationErrors)["groups"]
            >[string]["minNotMet"],
          );
        }
      }

      /**
       * TODO overlap
       */

      if (
        validationErrors.boundingBox !== undefined ||
        validationErrors.groups !== undefined
      ) {
        validationErrors.isValid = false;
      }
      return validationErrors;
    }, validationErrors);
  }

  return validationErrors;
};
