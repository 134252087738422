import { AnalysisTable, useAnalysisTableTaskCountsQuery } from "graphql/_Types";
import { groupBy } from "lodash";
import { TaskStatus } from "types/constants";
import { isNonNull } from "utils/isNonNull";
import { TaskCountBadge } from "./TaskCountBadge";
import { EuiFlexGrid, EuiFlexItem } from "@inscopix/ideas-eui";

interface AnalysisTableTaskCountsProps {
  analysisTable: Pick<AnalysisTable, "id" | "projectId">;
}

export const AnalysisTableTaskCounts = ({
  analysisTable,
}: AnalysisTableTaskCountsProps) => {
  const { data } = useAnalysisTableTaskCountsQuery({
    variables: { projectId: analysisTable.projectId },
    fetchPolicy: "cache-and-network",
  });

  const numTasksByStatus = (() => {
    const allTables = data?.projectById?.analysisTables.nodes;
    const table = allTables?.find(({ id }) => id === analysisTable.id);
    const tableRows = table?.rows.nodes;
    const tasks = tableRows?.map((row) => row.task).filter(isNonNull);

    if (tasks === undefined) {
      return {
        [TaskStatus.COMPLETE]: 0,
        [TaskStatus.RUNNING]: 0,
        [TaskStatus.QUEUED]: 0,
        [TaskStatus.FAILED]: 0,
      };
    }

    const tasksByStatus = groupBy(tasks, ({ status }) => status);
    const numTasksComplete = tasksByStatus[TaskStatus.COMPLETE]?.length ?? 0;
    const numTasksRunning = tasksByStatus[TaskStatus.RUNNING]?.length ?? 0;
    const numTasksQueued = tasksByStatus[TaskStatus.QUEUED]?.length ?? 0;
    const numTasksFailed = tasksByStatus[TaskStatus.FAILED]?.length ?? 0;
    const numTasksErrored = tasksByStatus[TaskStatus.ERROR]?.length ?? 0;

    return {
      [TaskStatus.COMPLETE]: numTasksComplete,
      [TaskStatus.RUNNING]: numTasksRunning,
      [TaskStatus.QUEUED]: numTasksQueued,
      [TaskStatus.FAILED]: numTasksFailed + numTasksErrored,
    };
  })();

  return (
    <EuiFlexGrid
      columns={numTasksByStatus[TaskStatus.FAILED] > 0 ? 4 : 3}
      gutterSize={"none"}
      responsive={false}
    >
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={data === undefined}
          numTasksByStatus={numTasksByStatus}
          taskStatus={TaskStatus.COMPLETE}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={data === undefined}
          numTasksByStatus={numTasksByStatus}
          taskStatus={TaskStatus.RUNNING}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={data === undefined}
          numTasksByStatus={numTasksByStatus}
          marginLeft={1}
          taskStatus={TaskStatus.QUEUED}
        />
      </EuiFlexItem>
      {numTasksByStatus[TaskStatus.FAILED] > 0 && (
        <EuiFlexItem>
          <TaskCountBadge
            isLoading={data === undefined}
            marginLeft={1}
            numTasksByStatus={numTasksByStatus}
            taskStatus={TaskStatus.FAILED}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGrid>
  );
};
