import {
  EuiText,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
  EuiAvatar,
} from "@inscopix/ideas-eui";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { TUserWithAccessExternal } from "./ModalProjectSharing";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";
import { ProjectSharingInviteStatus } from "types/constants";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { captureException } from "@sentry/react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useProjectPermission } from "hooks/useProjectPermission";
import {
  USER_ACCESS_LEVELS_BY_KEY,
  UserAccessLevel,
} from "types/UserAccessLevels";

type TUserWithAccessExternalProps = {
  usersWithAccess: TUserWithAccessExternal;
  onRevoke: () => void;
  setUsersWithAccessExternal: Dispatch<
    SetStateAction<TUserWithAccessExternal[]>
  >;
};

const UserWithAccessExternal = ({
  usersWithAccess,
  onRevoke,
  setUsersWithAccessExternal,
}: TUserWithAccessExternalProps) => {
  const [isPopoverOpen, setPopover] = useState(false);
  const {
    revokeOpenInvitation,
    resendInvitation,
    updateInvitationAccessLevel,
  } = useProjectPermission();
  const [isWorking, setWorking] = useState(false);
  const usersById = useTenantContext((s) => s.usersById);
  const user = usersById.get(usersWithAccess.invitee ?? "");

  const closePopover = () => {
    setPopover(false);
  };

  const togglePopover = () => {
    setPopover((isOpen) => !isOpen);
  };

  /**
   * Updates the access level of a pending initiate.
   * @param projectAccessLevel The new access level.
   */
  const handleUpdateAccess = async (
    projectAccessLevel: UserAccessLevel["id"],
  ) => {
    setWorking(true);
    try {
      await updateInvitationAccessLevel({
        invitationId: usersWithAccess.id,
        projectAccessLevel,
      });
      // Update cache
      setUsersWithAccessExternal((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === usersWithAccess.id) {
            return { ...user, project_access_level: projectAccessLevel };
          } else {
            return user;
          }
        }),
      );
      addUtilityToastSuccess("Invitation updated");
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to update invitation");
    }
    setWorking(false);
  };

  const handleRevoke = async () => {
    setWorking(true);
    try {
      await revokeOpenInvitation(usersWithAccess.id);
      addUtilityToastSuccess("Invitation revoked");
      onRevoke();
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to revoke invitation");
    } finally {
      setWorking(false);
    }
  };

  const handleResend = async () => {
    setWorking(true);
    try {
      await resendInvitation(usersWithAccess.id);
      addUtilityToastSuccess("Invitation resent");
      setWorking(false);
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to resend invitation");
      setWorking(false);
    }
  };

  return (
    <Fragment key={usersWithAccess.id}>
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiFlexItem grow={false}>
          {user === undefined ? (
            <EuiAvatar size="m" name={usersWithAccess.email} />
          ) : (
            <UserAvatar userId={user.id} size="m" />
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiText size="xs">
            {user !== undefined ? (
              <b>{`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}</b>
            ) : (
              <b>{usersWithAccess.email}</b>
            )}
          </EuiText>
          <EuiText size="xs" color="subdued">
            {usersWithAccess.status === ProjectSharingInviteStatus.OPEN ? (
              <i>Pending Invite</i>
            ) : (
              usersWithAccess.email
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            id={htmlIdGenerator()()}
            button={
              <ButtonEmptyPermissioned
                isLoading={isWorking}
                size="xs"
                iconSide="right"
                iconType="arrowDown"
                onClick={() => togglePopover()}
                requiredPermission="grantAccess"
              >
                {usersWithAccess.project_access_level ===
                USER_ACCESS_LEVELS_BY_KEY["copier"].id
                  ? "View, Download, & Copy"
                  : "View Only"}
              </ButtonEmptyPermissioned>
            }
            isOpen={isPopoverOpen}
            closePopover={() => setPopover(false)}
            panelPaddingSize="none"
            anchorPosition="downCenter"
          >
            <EuiContextMenuPanel
              size="s"
              items={[
                <EuiContextMenuItem
                  key="change-access"
                  onClick={() => {
                    const newAccessLevel =
                      usersWithAccess.project_access_level ===
                      USER_ACCESS_LEVELS_BY_KEY["copier"].id
                        ? USER_ACCESS_LEVELS_BY_KEY["viewer"].id
                        : USER_ACCESS_LEVELS_BY_KEY["copier"].id;
                    void handleUpdateAccess(newAccessLevel);
                    closePopover();
                  }}
                >
                  <EuiText size="s" color="primary">
                    {usersWithAccess.project_access_level ===
                    USER_ACCESS_LEVELS_BY_KEY["copier"].id ? (
                      <span>
                        Change to <strong>View Only</strong>
                      </span>
                    ) : (
                      <span>
                        Change to <strong>View, Download, & Copy</strong>
                      </span>
                    )}
                  </EuiText>
                </EuiContextMenuItem>,
                usersWithAccess.status === ProjectSharingInviteStatus.OPEN && (
                  <EuiContextMenuItem
                    key="resend"
                    onClick={() => {
                      void handleResend();
                      closePopover();
                    }}
                  >
                    <EuiText size="s">Resend Invite</EuiText>
                  </EuiContextMenuItem>
                ),
                <EuiContextMenuItem
                  key="remove"
                  onClick={() => {
                    void handleRevoke();
                    closePopover();
                  }}
                >
                  <EuiText size="s" color="danger">
                    Revoke
                  </EuiText>
                </EuiContextMenuItem>,
              ]}
            />
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  );
};

export { UserWithAccessExternal };
