import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useCallback } from "react";
import { TRegion } from "providers/RegionsProvider";
import { NotificationAlertProjectClonedSucceeded } from "./NotificationAlertProjectClonedSucceeded";
import { NotificationAlertProjectClonedFailed } from "./NotificationAlertProjectClonedFailed";

interface NotificationAlertProjectClonedProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchExternalNotifications?: () => void,
  ) => void;
  refetchExternalNotifications: () => void;
}

/** Component that renders a notification that a project has finished cloning */
export const NotificationAlertProjectCloned = ({
  data,
  onNotificationRead,
  refetchExternalNotifications,
}: NotificationAlertProjectClonedProps) => {
  const { hasSeen, id: notificationId } = data;
  const attrs = data.projectClonedNotification;

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(
        notificationId,
        data.region,
        refetchExternalNotifications,
      );
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchExternalNotifications,
    data.region,
  ]);

  if (attrs?.succeeded) {
    return (
      <NotificationAlertProjectClonedSucceeded
        data={data}
        onNotificationRead={onNotificationReadMemoized}
      />
    );
  }

  return (
    <NotificationAlertProjectClonedFailed
      data={data}
      onNotificationRead={onNotificationReadMemoized}
    />
  );
};
