/** @jsxImportSource @emotion/react */
import { MenuBar } from "components/MenuBar/MenuBar";
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
} from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { isDefined } from "utils/isDefined";
import { sleep } from "utils/sleep";
import { useToolParamsGridContext } from "components/ToolParamsGrid/ToolParamsGridProvider";
import { exportAsCSV } from "utils/exportAsCSV";
import { AnalysisTableProps } from "./AnalysisTable";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { AnalysisTableJumpToColumnPopover } from "./AnalysisTableJumpToColumn";
import { getDeleteAnalysisTableRowModeFromRowTaskStatus } from "components/ToolParamsGrid/ModalDeleteRow";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { keyBy } from "lodash";
import { AnalysisTableGroup } from "graphql/_Types";
import { useToolParamsGridRowDataContext } from "components/ToolParamsGrid/ToolParamsGridRowDataProvider";

const styles = {
  buttonGroup: css`
    margin-right: 2px;
    padding-right: 2px;
    display: inline-block;
    border-right: 1px solid #eee;
  `,
};

interface AnalysisTableMenuBarProps {
  group: Pick<AnalysisTableGroup, "name">;
  table: AnalysisTableProps["tables"][number];
}

export const AnalysisTableMenuBar = ({
  group,
  table,
}: AnalysisTableMenuBarProps) => {
  const { gridApi, gridRef, toolSpec } = useToolParamsGridContext();

  const attachResults = useToolParamsGridRowDataContext((s) => s.attachResults);
  const addNewRow = useToolParamsGridRowDataContext((s) => s.addNewRow);
  const copyRow = useToolParamsGridRowDataContext((s) => s.copyRow);
  const removeRow = useToolParamsGridRowDataContext((s) => s.removeRow);
  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);

  const files = useProjectFilesStore((s) => s.files);
  const { openModal } = useAnalysisTableLayoutContext();
  const handleGotoResults = async () => {
    const fileResults =
      toolSpec?.results[0]?.files?.length > 0
        ? toolSpec?.results[0]?.files
        : undefined;

    if (isDefined(fileResults)) {
      const firstResultKey = fileResults[0].result_key;
      const allResultKeys = fileResults.map((result) => result.result_key);
      gridApi?.ensureColumnVisible(firstResultKey, "start");
      // https://github.com/ag-grid/ag-grid/issues/5085
      await sleep(0);
      gridApi?.flashCells({ columns: allResultKeys });
    }
  };
  const handleGotoTaskInfo = async () => {
    const taskInfoKeys = [
      "task_id",
      "task_logs",
      "task_date_created",
      "task_compute_credit",
      "task_duration",
      "task_user",
    ];
    gridApi?.ensureColumnVisible("task_id", "start");
    // https://github.com/ag-grid/ag-grid/issues/5085
    await sleep(0);
    gridApi?.flashCells({ columns: taskInfoKeys });
  };

  const selectedRows = rowData?.filter((row) => row.selected);
  const noActiveSelection = selectedRows?.length === 0;
  const isAlreadyAttached = selectedRows?.every((row) => row.attachResults);
  const isAlreadyDetached = selectedRows?.every((row) => !row.attachResults);

  const hasIneligibleRows = selectedRows?.some(
    (row) =>
      getDeleteAnalysisTableRowModeFromRowTaskStatus(row.task_status) ===
      "DISABLED",
  );

  const handleRemoveRow = () => {
    const hasRowsWithTask = selectedRows.some(
      (row) => row.task_status !== undefined,
    );

    if (hasRowsWithTask) {
      openModal({
        type: "deleteRowBulk",
        props: {
          rows: selectedRows,
        },
      });
    } else {
      selectedRows?.forEach((row) => {
        removeRow(row.id);
      });
    }
  };

  return (
    <>
      <EuiHorizontalRule margin="none" />
      <EuiPanel
        hasShadow={false}
        css={css({ paddingTop: "2px", paddingBottom: "2px" })}
      >
        <MenuBar>
          <EuiFlexGroup
            alignItems="center"
            justifyContent="spaceBetween"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <div>
                <div css={styles.buttonGroup}>
                  <ButtonIconPermissioned
                    aria-label="Add row"
                    requiredPermission="edit"
                    iconType={"plusInCircleFilled"}
                    defaultTooltip={"Add row"}
                    onClick={addNewRow}
                  >
                    Add row
                  </ButtonIconPermissioned>
                </div>
                <div css={styles.buttonGroup}>
                  <ButtonIconPermissioned
                    aria-label="Copy selected rows"
                    requiredPermission="edit"
                    iconType={"copy"}
                    defaultTooltip={"Duplicate selected rows"}
                    onClick={() => {
                      selectedRows?.forEach((row) => {
                        copyRow(row.id);
                      });
                    }}
                    disabled={noActiveSelection}
                  />
                  <ButtonIconPermissioned
                    aria-label="Attach results"
                    requiredPermission="edit"
                    iconType={"link"}
                    defaultTooltip={"Attach results"}
                    onClick={() => {
                      void attachResults(
                        selectedRows.map((row) => row.id),
                        true,
                      );
                    }}
                    disabled={noActiveSelection || isAlreadyAttached}
                  />
                  <ButtonIconPermissioned
                    aria-label="Unattach results"
                    requiredPermission="edit"
                    iconType={"unlink"}
                    defaultTooltip={"Unattach results"}
                    onClick={() => {
                      void attachResults(
                        selectedRows.map((row) => row.id),
                        false,
                      );
                    }}
                    disabled={noActiveSelection || isAlreadyDetached}
                  />
                  <ButtonIconPermissioned
                    aria-label="Delete selected rows"
                    requiredPermission="edit"
                    color="danger"
                    iconType={"minusInCircle"}
                    defaultTooltip={
                      hasIneligibleRows
                        ? "Some of the selected rows are not eligible for deletion because they have tasks in progress"
                        : "Delete selected rows"
                    }
                    onClick={handleRemoveRow}
                    disabled={noActiveSelection || hasIneligibleRows}
                  />
                </div>
                <ButtonIconPermissioned
                  aria-label="Download"
                  requiredPermission="view"
                  iconType={"download"}
                  defaultTooltip={"Download this table as CSV"}
                  onClick={() => {
                    void exportAsCSV(
                      gridRef,
                      `${group.name} (${table.name})`,
                      keyBy(files, (file) => file.id),
                      toolSpec,
                    );
                  }}
                />
              </div>
            </EuiFlexItem>
            <EuiFlexItem
              css={css`
                align-items: center;
              `}
              grow={false}
            >
              <AnalysisTableJumpToColumnPopover />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="s" responsive={false}>
                <EuiFlexItem grow={false}>
                  {" "}
                  <EuiButtonEmpty
                    data-test-subj="go-to-results-button"
                    key="go-to-results-button"
                    iconType="arrowEnd"
                    size={"xs"}
                    iconSize={"m"}
                    iconSide="right"
                    color={"success"}
                    onClick={() => void handleGotoResults()}
                  >
                    Go to Results
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    data-test-subj="go-to-task-info-button"
                    key="go-to-task-info-button"
                    iconType="arrowEnd"
                    size={"xs"}
                    iconSize={"m"}
                    iconSide="right"
                    color={"primary"}
                    onClick={() => void handleGotoTaskInfo()}
                  >
                    Go to Task Info
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </MenuBar>
      </EuiPanel>
    </>
  );
};
