import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import WorkflowSelectionModal from "components/WorkflowSelectionModal/WorkflowSelectionModal";

interface ModalCreateAnalysisTableProps {
  onClose: () => void;
}

/** Component that renders a modal for create a new analysis table */
export const ModalCreateAnalysisTable = ({
  onClose,
}: ModalCreateAnalysisTableProps) => {
  const createAnalysisTable = useDataTableContext((s) => s.createAnalysisTable);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    toolVersionId: number,
    name: string,
    key: string,
  ) => {
    setIsLoading(true);

    const { error } = await createAnalysisTable({
      toolVersionId,
      name,
      key,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Analysis table created");
    } else {
      addUtilityToastFailure("Failed to create analysis table");
    }

    onClose();
  };

  return (
    <WorkflowSelectionModal
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={(toolSpec, name, key) =>
        handleSubmit(toolSpec.toolVersionId, name, key)
      }
      selectedToolSpecKey={undefined}
      isKeyRequired
    />
  );
};
