import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckableCard,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { Dataset, FileSource } from "graphql/_Types";
import { Fragment, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { captureException } from "@sentry/react";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { drsDatasetDjango } from "django/drsDatasetDjango";

const OPTIONS_MAP = {
  DELETE_FILES: {
    label: "Delete dataset and all uploaded files",
    description:
      "All uploaded files that are assigned to this dataset will be removed, files will become permanently unavailable and will not count towards active or archived storage. This will not affect any analysis results that are displayed in analysis result columns in this dataset (delete the associated analysis table to remove these).",
    buttonColor: "danger" as const,
    buttonText: "Delete dataset & uploaded data",
  },
  UNASSIGN_FILES: {
    label: "Delete dataset and unassign files",
    description:
      "This will delete the table only and will assign any uploaded files to the current project. No file data will be lost.",
    buttonColor: "warning" as const,
    buttonText: "Delete dataset & unassign files",
  },
};

export interface ModalDeleteDatasetProps {
  datasetId: Dataset["id"];
  onClose: () => void;
}

export const ModalDeleteDataset = ({
  datasetId,
  onClose,
}: ModalDeleteDatasetProps) => {
  const [selectedOption, setSelectedOption] =
    useState<keyof typeof OPTIONS_MAP>("DELETE_FILES");
  const [isLoading, setIsLoading] = useState(false);
  const fileIds = useProjectFilesStore((s) =>
    s.files
      .filter(
        (file) =>
          file.source === FileSource.Uploaded &&
          file.datasets.length === 1 &&
          file.datasets[0].id === datasetId,
      )
      .map((file) => file.id),
  );

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (selectedOption === "DELETE_FILES") {
        await drsDatasetDjango.scheduleDataDelete({ datasetId, fileIds });
      } else if (selectedOption === "UNASSIGN_FILES") {
        await drsDatasetDjango.scheduleDelete({ datasetId });
      }
      addUtilityToastSuccess("Dataset deleted");
    } catch (error) {
      captureException(error);
      addUtilityToastFailure("Failed to delete dataset");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Delete Dataset</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {Object.entries(OPTIONS_MAP).map(
          ([id, { label, description }], idx, arr) => {
            const isLastOption = idx === arr.length - 1;
            return (
              <Fragment key={id}>
                <EuiCheckableCard
                  id={id}
                  label={
                    <EuiText>
                      <strong>{label}</strong>
                    </EuiText>
                  }
                  checked={selectedOption === id}
                  onChange={() =>
                    setSelectedOption(id as typeof selectedOption)
                  }
                >
                  <EuiText>{description}</EuiText>
                </EuiCheckableCard>
                {!isLastOption && <EuiSpacer size="s" />}
              </Fragment>
            );
          },
        )}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          color={OPTIONS_MAP[selectedOption].buttonColor}
          fill
          onClick={() => void handleSubmit()}
          isLoading={isLoading}
        >
          {OPTIONS_MAP[selectedOption].buttonText}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
