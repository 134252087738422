/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { validateToolRoiFrameParamValue } from "components/RoiEditor/RoiEditor.helpers";
import { useMemo } from "react";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { ModalToolRoiFrameParamProps } from "../ModalToolRoiFrameParam/ModalToolRoiFrameParam";
import {
  ToolParamsGridRowDatum,
  ToolParamValue,
} from "../ToolParamsGrid.types";
import { ButtonToolRoiFrameParam } from "./ButtonToolRoiFrameParam";
import { GridRendererToolRoiFrameParamShapes } from "./GridRendererToolRoiFrameParamShapes";
import { useValueValidator } from "../ToolParamsGridValueValidatorContext";
import { isDefined } from "utils/isDefined";
import { isEqual } from "lodash";

export interface GridRendererToolCroppingFrameParamProps
  extends Pick<ModalToolRoiFrameParamProps, "toolParam"> {
  rowId: ToolParamsGridRowDatum["id"];
  value: ToolParamValue;
}

export const GridRendererToolRoiFrameParam = ({
  toolParam,
  rowId,
  value,
}: GridRendererToolCroppingFrameParamProps) => {
  const validationError = useValueValidator(
    (s) => s.errorsByRowId[rowId][toolParam.key],
    isEqual,
  );

  /**
   * Right now manual entry is disabled so we control coordinates
   * In the future to enable copy/paste we will need a json schema validator
   * to ensure coordinates are in the correct format when parsing
   */

  const initialShapes = useMemo(() => {
    if (value !== undefined) {
      const validationErrors = validateToolRoiFrameParamValue(
        value,
        toolParam.type.roi_settings,
      );
      if (validationErrors.dataFormat !== undefined) {
        return validationErrors.dataFormat;
      }

      const shapes = JSON.parse(value as string) as ShapeJson[];
      return shapes;
    }
  }, [toolParam, value]);

  return (
    <div
      css={css`
        background-color: ${isDefined(validationError)
          ? "rgba(255, 0, 0, 0.05)"
          : "inherit"};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      `}
    >
      <div
        css={css`
          overflow: hidden;
          margin-right: 5px;
          align-items: center;
        `}
      >
        {(() => {
          if (initialShapes !== undefined) {
            if (initialShapes instanceof Error) {
              return <span>Invalid format</span>;
            } else {
              return (
                <GridRendererToolRoiFrameParamShapes
                  toolParam={toolParam}
                  shapes={initialShapes}
                />
              );
            }
          }
        })()}
      </div>
      <ButtonToolRoiFrameParam
        initialShapes={
          initialShapes instanceof Error ? undefined : initialShapes
        }
        toolParam={toolParam}
        rowId={rowId}
      />
    </div>
  );
};
