/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { EuiButtonEmpty, EuiButtonIcon, EuiPopover } from "@inscopix/ideas-eui";
import { IHeaderParams } from "ag-grid-community";
import { css } from "@emotion/react";
import { DataTableRowData } from "../layout/DataTable";
import { useMouseOver } from "hooks/useMouseOver";
import { ColumnHeaderActionRemoveColumn } from "./actions/ColumnHeaderActionRemoveColumn";
import { ColumnHeaderBaseName } from "./ColumnHeaderBaseName";
import { ModalDeleteColumn } from "../modals/ModelDeleteColumn";

/**
 * Gets the spreadsheet column address for column a specified position.
 * @param order
 * @returns The spreadsheet column address
 */
export const getColumnAddress = (order: number) => {
  let address = "";

  // Convert order to a base-64 number where A is 0 and Z is 25. Every loop we
  // reduce order by a power of 26 and calculate the character corresponding to
  // that power. Every character is offset from char code 65, which represents A.
  while (order >= 0) {
    const remainder = order % 26;
    address = String.fromCharCode(65 + remainder) + address;
    order = Math.floor(order / 26) - 1;
  }

  return address;
};

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  address: css`
    color: grey;
    margin-left: 5px;
  `,
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
};

interface ColumnHeaderBaseProps extends IHeaderParams<DataTableRowData> {
  index: number;
  tableId: string;
  tableKind: "data" | "analysis";
  isColumnDeletable: boolean;
}

/**
 * Component that renders a header for all column types.
 *
 * All all other header components should render this header and specify props
 * for displaying custom content.
 */
export const ColumnHeaderBase = ({
  displayName,
  eGridHeader,
  index,
  column,
  tableId,
  tableKind,
  isColumnDeletable,
}: ColumnHeaderBaseProps) => {
  const { isMouseOver } = useMouseOver(eGridHeader);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [visibleModal, setVisibleModal] = useState<"remove">();

  return (
    <div css={styles.root}>
      {visibleModal === "remove" && (
        <ModalDeleteColumn
          columnId={column.getColId()}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
      <ColumnHeaderBaseName
        tableId={tableId}
        columnId={column.getColId()}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        name={displayName}
      />
      <span css={styles.address}>{getColumnAddress(index)}</span>
      {(isMouseOver || isPopoverOpen) && (
        <EuiPopover
          button={
            <EuiButtonIcon
              aria-label="Context menu"
              color="text"
              iconType={() => (
                <span className="ag-icon ag-icon-menu" unselectable="on" />
              )}
              size="xs"
              onClick={togglePopover}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          anchorPosition="downLeft"
          panelPaddingSize="none"
          ownFocus={false}
        >
          <div css={styles.contextMenu}>
            <EuiButtonEmpty
              onClick={closePopover}
              iconType="iInCircle"
              color="text"
              size="s"
              disabled
            >
              Details
            </EuiButtonEmpty>
            <EuiButtonEmpty
              onClick={() => {
                closePopover();
                setIsRenaming(true);
              }}
              iconType="pencil"
              color="text"
              size="s"
            >
              Rename column
            </EuiButtonEmpty>
            {tableKind === "data" && (
              <ColumnHeaderActionRemoveColumn
                onClick={() => {
                  setVisibleModal("remove");
                  closePopover();
                }}
                isDisabled={!isColumnDeletable}
              />
            )}
          </div>
        </EuiPopover>
      )}
    </div>
  );
};
