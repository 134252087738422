import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
} from "@inscopix/ideas-eui";
import { useParams } from "react-router-dom";
import { DataTable } from "./layout/DataTable";
import { DataTableHeader } from "./layout/DataTableHeader";
import { DataTableMenuBar } from "./layout/DataTableMenuBar";
import { DataTableTabs } from "./layout/DataTableTabs";
import { DataTableProvider } from "./store/DataTableProvider";

export const PageGenericDataTables = () => {
  const projectKey = useParams<{ projectKey: string }>().projectKey;

  return (
    <EuiPageTemplate panelled bottomBorder="extended" grow>
      <DataTableProvider projectKey={projectKey}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem grow={false} style={{ marginLeft: 5 }}>
            <DataTableHeader />
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ marginLeft: 5 }}>
            <DataTableMenuBar />
          </EuiFlexItem>

          <EuiFlexItem>
            <DataTable />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <DataTableTabs />
          </EuiFlexItem>
        </EuiFlexGroup>
      </DataTableProvider>
    </EuiPageTemplate>
  );
};
