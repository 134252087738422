import {
  EuiBreadcrumbs,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiIcon,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ButtonToolDocs } from "components/ButtonToolDocs/ButtonToolDocs";
import { DatasetBreadcrumbMenu } from "components/DatasetBreadcrumbMenu/DatasetBreadcrumbMenu";
import { ToolBetaBadge } from "components/ToolBetaBadge/ToolBetaBadge";
import { StatusPanelSaveStatus } from "components/ToolParamsGrid/hooks/useStatusBar";
import { useRoutes } from "hooks/useRoutes";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { ToolSpecAndToolVersionId } from "providers/ToolSpecProvider/ToolSpecProvider";
import { Dispatch, SetStateAction } from "react";
import { Helmet } from "react-helmet-async";
import { ToolMaturity } from "types/constants";
import { roundToSignificant } from "utils/roundToSignificant";
import { AnalysisTableProps } from "./AnalysisTable";

interface AnalysisTableHeaderProps {
  tool: AnalysisTableProps["tool"];
  group: AnalysisTableProps["group"];
  latestToolSpec: ToolSpecAndToolVersionId;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
}

export const AnalysisTableHeader = ({
  tool,
  group,
  latestToolSpec,
  setShowEditModal,
}: AnalysisTableHeaderProps) => {
  const { routeMap } = useRoutes();
  const { project, datasets } = useProjectDataContext();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const totalUsage = roundToSignificant(group.usedCredits);

  return (
    <EuiPageHeader bottomBorder={false} paddingSize="m" alignItems={"bottom"}>
      <Helmet>
        <title>{`${project.name}: ${group.name}`}</title>
      </Helmet>

      <EuiPageHeaderSection>
        <EuiBreadcrumbs
          data-test-subj="analysis-table-breadcrumbs"
          truncate={false}
          aria-label="breadcrumbs"
          breadcrumbs={[
            {
              text: (
                <>
                  <EuiIcon size="s" type="arrowLeft" />
                  {project.name}
                </>
              ),
              "aria-current": false,
              href: routeMap["PROJECT"].dynamicPath({
                tenantKey: currentTenant.key,
                projectKey: project.key,
              }).path,
              onClick: (e) => {
                e.preventDefault();
                routeMap["PROJECT"]
                  .dynamicPath({
                    tenantKey: currentTenant.key,
                    projectKey: project.key,
                  })
                  .navigateTo();
              },
            },
            ...(datasets.length !== 0 //removes the tailing slash / when there is no dataset
              ? [
                  {
                    text: <DatasetBreadcrumbMenu />,
                    "aria-current": false,
                  },
                ]
              : []),
          ]}
        />
        <EuiTitle size="m">
          <h1 data-test-subj="dataset-header-title">
            <>
              {group.name}
              <ButtonIconPermissioned
                data-test-subj="edit-analysis-table-name"
                aria-label="Edit analysis table name"
                key="edit-analysis-table"
                size={"s"}
                iconSize={"m"}
                color="text"
                iconType="pencil"
                onClick={() => setShowEditModal(true)}
                requiredPermission="edit"
                defaultTooltip="Edit analysis table name"
              />
              <StatusPanelSaveStatus />
              <br />
              <EuiText data-test-subj="workflow-info" color="subdued" size="s">
                <EuiIcon type="indexMapping" />
                &nbsp; <strong>{latestToolSpec.spec.name} </strong>
                &nbsp;&nbsp;
                {latestToolSpec.maturity === ToolMaturity.DEVELOPMENT && (
                  <ToolBetaBadge />
                )}
                <ButtonToolDocs
                  data-test-subj="tool-docs-button"
                  tool={{
                    key: tool.key,
                  }}
                  EuiButtonComponent={EuiButtonEmpty}
                />
              </EuiText>
            </>
          </h1>
        </EuiTitle>
      </EuiPageHeaderSection>
      <EuiPageHeaderSection>
        <EuiFlexGroup justifyContent={"flexEnd"}>
          {/** Blocksize is used to visually align this with the subtitle of the page on the left*/}
          <EuiText size="s" style={{ blockSize: "26px" }}>
            Total usage: <b>{totalUsage} compute credits</b>
          </EuiText>
        </EuiFlexGroup>
      </EuiPageHeaderSection>
    </EuiPageHeader>
  );
};
