import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { DrsFileColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import { File as DrsFile, FileRecordingGroup } from "graphql/_Types";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { ReactNode, useCallback } from "react";
import { isDrsFileAllowedInColumn } from "utils/isDrsFileAllowedInColumn";
import { uuid } from "utils/uuid";
import { DatasetDragOverlay } from "./DatasetDragOverlay";

export type DraggableData = {
  drsFile: Pick<DrsFile, "id" | "fileType">;
  recordingId: FileRecordingGroup["id"] | undefined;
  columnId: DrsFileColumn["id"] | undefined;
};

export type DroppableData = {
  recordingId: FileRecordingGroup["id"];
  column: Pick<DrsFileColumn, "id" | "colDef">;
};

/**
 * Previously we used data fed to the context from DraggableFileBadge via the useDraggable hook,
 * However, because DraggableFileBadge was rendered in a virtualized eui data grid (File Browser),
 * it could unmount once a drag commenced, resulting in the draggable data being lost while a drag event was still in flight.
 * This resulted in the drag overlay disappearing, and a drop event being captured with undefined data and a subsequent application crash.
 *
 * The interim solution is to encode our draggable data in an id, which is static and fixed throughout
 * the entire lifecycle of a drag event.
 *
 * Alternative solutions:
 * 1) Prevent the grid from scrolling to prevent unmounting the source draggable badge via preventing default
 * on mouseDown. While this worked, it feels dangerous and could have unintended side effects. Also since virtualization is not within our control
 * we can't necessarily guarantee the component will always stay mounted through the entire drag event.
 *
 * 2) Manually manage draggable data in state via events like dragStart and dragEnd. This felt a bit more complex and hard to follow while not really
 * providing a ton of benefit. This data would also need to be managed in a separate context because things like the recordings grid cell renderer
 * need access to the draggable data
 */

/**
 * encodes information in the draggable data id because data set by useDraggable
 * is lost mid drag if the source component unmounts
 * @param data Draggable data to turn into a parsable unique id
 * @returns
 */

export const generateDatasetDraggableId = (data: DraggableData) =>
  JSON.stringify({ id: uuid(), data });

/**
 * Parse information encoded in draggable ids as a way around a bug where draggable data set useDraggable
 * is lost mid drag if the source component unmounts
 * @param draggableId id from dnd active
 * @returns Draggable data
 */

export const parseDatasetDraggableId = (draggableId: string) =>
  (JSON.parse(draggableId) as { data: DraggableData }).data;

interface DatasetDndProviderProps {
  children: ReactNode;
}

export const DatasetDndProvider = ({ children }: DatasetDndProviderProps) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      // Mouse must move at least 1 pixel before a drag start event is emitted
      // This allows click events to be detected
      distance: 1,
    },
  });
  const sensors = useSensors(mouseSensor);
  const { datasetMode } = useDatasetDataContext();
  const assignFileAction = useDatasetAction("assignFile");

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      if (datasetMode.type !== "current") {
        throw new Error(
          "Caught prohibited action fallback when viewing dataset history",
        );
      }
      const { active, over } = e;

      // Do nothing if drag does not end over a drop zone
      if (over === null) {
        return;
      }

      const activeData = parseDatasetDraggableId(active.id as string);
      const overData = over.data.current as DroppableData;

      if (!isDrsFileAllowedInColumn(activeData.drsFile, overData.column)) {
        return;
      }

      const isOverNewCell =
        activeData.columnId !== overData.column.id ||
        activeData.recordingId !== overData.recordingId;

      if (isOverNewCell) {
        void assignFileAction.enqueue({
          drsFileId: activeData.drsFile.id,
          recordingId: overData.recordingId,
          columnId: overData.column.id,
        });
      }
    },
    [assignFileAction, datasetMode.type],
  );

  return (
    <DndContext
      collisionDetection={pointerWithin}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      {children}
      <DatasetDragOverlay />
    </DndContext>
  );
};
