import {
  EuiText,
  EuiSpacer,
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
  EuiTextColor,
  EuiHorizontalRule,
} from "@inscopix/ideas-eui";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { capitalize } from "lodash";
import { Fragment, useState } from "react";
import { captureException } from "@sentry/react";
import { TUserRole, TUserWithAccess } from "./ModalProjectSharing";
import { useProjectPermission } from "hooks/useProjectPermission";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";

type TUserWithAccessProps = {
  usersWithAccess: TUserWithAccess;
  onRoleChange: (role: TUserWithAccess["role"]) => void;
  onRemove: () => void;
};

const UserWithAccess = ({
  usersWithAccess,
  onRoleChange,
  onRemove,
}: TUserWithAccessProps) => {
  const [isPopoverOpen, setPopover] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const { updateIndividualUserAccessLevel, removeIndividualUserAccessLevel } =
    useProjectPermission();
  const usersById = useTenantContext((s) => s.usersById);
  const user = usersById.get(usersWithAccess.userId);

  if (!user) {
    captureException(
      new Error(
        `User with id ${usersWithAccess.userId} not found in ModalProjectSharing > UserWithAccess component`,
      ),
    );
    return null;
  }

  const closePopover = () => {
    setPopover(false);
  };
  const togglePopover = () => {
    setPopover((isOpen) => !isOpen);
  };

  const handleRoleChange = async (role: TUserRole) => {
    if (
      role !== "owner" && //Owner cannot be changed
      role !== "custom" && // Setting to custom role is not supported yet
      role !== usersWithAccess.role //no need to change the role if the role is the same
    ) {
      setWorking(true);
      try {
        await updateIndividualUserAccessLevel(
          usersWithAccess.id,
          usersWithAccess.userId,
          role,
        );
        addUtilityToastSuccess("User role updated");
        onRoleChange(role);
      } catch (e) {
        captureException(e as Error);
        addUtilityToastFailure("Failed to update user role");
      } finally {
        setWorking(false);
      }
    }
  };

  const handleRemove = async () => {
    setWorking(true);
    try {
      await removeIndividualUserAccessLevel(
        usersWithAccess.id,
        usersWithAccess.userId,
      );
      addUtilityToastSuccess("User removed");
      onRemove();
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to remove user");
      setWorking(false);
    }
  };

  return (
    <Fragment key={usersWithAccess.userId}>
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiFlexItem grow={false}>
          <UserAvatar userId={user.id} size="m" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiText size="xs">
            <b>{`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}</b>
          </EuiText>
          <EuiText size="xs" color="subdued">
            {user.email}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {usersWithAccess.role === "owner" ? (
            <EuiButtonEmpty
              size="xs"
              iconSide="right"
              iconType={"empty"}
              disabled
            >
              {capitalize(usersWithAccess.role)}
            </EuiButtonEmpty>
          ) : (
            <EuiPopover
              id={htmlIdGenerator()()}
              button={
                <ButtonEmptyPermissioned
                  isLoading={isWorking}
                  size="xs"
                  iconSide="right"
                  iconType="arrowDown"
                  onClick={() => togglePopover()}
                  requiredPermission="grantAccess"
                >
                  {usersWithAccess.role === "custom"
                    ? "Custom"
                    : USER_ACCESS_LEVELS_BY_KEY[usersWithAccess.role].name}
                </ButtonEmptyPermissioned>
              }
              isOpen={isPopoverOpen}
              closePopover={() => setPopover(false)}
              panelPaddingSize="none"
              anchorPosition="downRight"
            >
              <EuiContextMenuPanel
                size="s"
                items={[
                  <EuiContextMenuItem
                    key="viewer"
                    onClick={() => {
                      void handleRoleChange("viewer");
                      closePopover();
                    }}
                  >
                    Viewer
                  </EuiContextMenuItem>,
                  <EuiContextMenuItem
                    key="editor"
                    onClick={() => {
                      void handleRoleChange("editor");
                      closePopover();
                    }}
                  >
                    Editor
                  </EuiContextMenuItem>,
                  <EuiContextMenuItem
                    key="admin"
                    onClick={() => {
                      void handleRoleChange("admin");
                      closePopover();
                    }}
                  >
                    Admin
                  </EuiContextMenuItem>,
                ]}
              />
              <EuiHorizontalRule margin="none" />
              <EuiContextMenuPanel
                size="s"
                items={[
                  <EuiContextMenuItem
                    key="remove"
                    onClick={() => {
                      void handleRemove();
                      closePopover();
                    }}
                  >
                    <EuiTextColor color="danger">Remove</EuiTextColor>
                  </EuiContextMenuItem>,
                ]}
              />
            </EuiPopover>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
    </Fragment>
  );
};

export { UserWithAccess };
