/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { EuiFlexGroup, EuiFlexItem, useEuiFontSize } from "@inscopix/ideas-eui";
import { FlyoutDrsFileInfoProps } from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { memo } from "react";
import {
  TasksTableTask,
  TASK_TABLE_DETAIL_RENDERER_HEIGHT,
} from "./TasksTable";
import { TasksTableDetailRendererParamsGrid } from "./TasksTableDetailRendererParamsGrid";

const PADDING = 12;
const MARGIN = 10;

export interface TasksTableDetailRendererProps {
  data: TasksTableTask & { table: NonNullable<TasksTableTask["table"]> };
  onClickFile?: (file: FlyoutDrsFileInfoProps["drsFile"]) => void;
}
export const TasksTableDetailRenderer = memo(function TasksTableDetailRenderer(
  props: TasksTableDetailRendererProps,
) {
  const { fontSize } = useEuiFontSize("l");

  return (
    <div
      css={css`
        padding: ${PADDING}px;
      `}
    >
      <EuiFlexGroup
        css={css`
          height: 100%;
        `}
        gutterSize="none"
      >
        <EuiFlexItem
          css={css`
            margin-top: ${MARGIN}px;
          `}
        >
          <div
            css={css`
              height: calc(
                ${TASK_TABLE_DETAIL_RENDERER_HEIGHT - 2 * PADDING - MARGIN}px -
                  ${fontSize}
              );
            `}
          >
            <TasksTableDetailRendererParamsGrid {...props} />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
});
