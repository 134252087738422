import {
  ToolRoiFrameParam,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ModalToolRoiFrameParamWrapper } from "./ModalToolRoiFrameParamWrapper";
import { useProjectPermission } from "hooks/useProjectPermission";
import { ModalToolRoiFrameParamNoSourceFile } from "./ModalToolRoiFrameParamNoSourceFile";
import { RoiEditorProps } from "../../RoiEditor/RoiEditor";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import assert from "assert";
import { ModalToolRoiFrameParamBase } from "./ModalToolRoiFrameParamBase";

export type ModalToolRoiFrameParamProps = {
  toolParam: ToolRoiFrameParam;
  rowId: ToolParamsGridRowDatum["id"];
  onClose: () => void;
} & Pick<RoiEditorProps, "initialShapes">;

/**
 * A component for editing any `ToolRoiFrameParam` grid cells
 */
export const ModalToolRoiFrameParam = ({
  toolParam,
  initialShapes,
  onClose,
  rowId,
}: ModalToolRoiFrameParamProps) => {
  const data = useToolParamsGridRowDataContext((s) =>
    s.rowData.find((row) => row.id === rowId),
  );
  assert(
    data !== undefined,
    "Expected row data to be defined in ModalToolRoiFrameParam",
  );

  const { hasPermission } = useProjectPermission();
  const readOnly = data.task_id !== undefined || !hasPermission("edit");

  const { toolSpec } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const sourceFileParamKey = toolParam.type.source_file.key;

  const sourceFileParam = toolSpec.params.find(
    (param) => param.key === sourceFileParamKey,
  );

  // handle invalid linked param
  if (sourceFileParam === undefined) {
    captureException(
      "Source file param key points to param that does not exist",
      {
        extra: {
          sourceFileParamKey,
        },
      },
    );
    return (
      <ModalToolRoiFrameParamWrapper
        paramName={toolParam.name}
        onClose={onClose}
      >
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  if (!isToolPathParam(sourceFileParam)) {
    captureException(
      "Unexpected param type for ToolRoiFrameParam source file",
      {
        extra: {
          sourceFileParam,
        },
      },
    );
    return (
      <ModalToolRoiFrameParamWrapper
        paramName={toolParam.name}
        onClose={onClose}
      >
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  const onAccept = (shapes: ShapeJson[]) => {
    const newParamValue = JSON.stringify(shapes);

    updateRowDatum(data.id, {
      params: {
        [toolParam.key]: newParamValue,
      },
    });
    onClose();
  };

  const NoSourceFiles = () => (
    <ModalToolRoiFrameParamNoSourceFile
      toolParam={toolParam}
      rowDatum={data}
      sourceFileParam={sourceFileParam}
      onClose={onClose}
      readOnly={readOnly}
    />
  );

  return (
    <ModalToolRoiFrameParamBase
      fileIds={(data.params[sourceFileParamKey] ?? []) as string[]}
      paramName={toolParam.name}
      onSave={onAccept}
      initialShapes={initialShapes}
      onClose={onClose}
      NoSourceFiles={NoSourceFiles}
      isReadOnly={readOnly}
      sourceFile={toolParam.type.source_file}
      roiSettings={toolParam.type.roi_settings}
    />
  );
};
