import { EuiResizableContainer } from "@inscopix/ideas-eui";
import { useResizablePanels } from "components/Project/useResizablePanels";
import { ToolParamsGrid } from "components/ToolParamsGrid/ToolParamsGrid";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";

/**
 * Component that renders the main content of an analysis table. This includes
 * the analysis table and all flyouts.
 */
export const AnalysisTableContent = () => {
  const { rightFlyout } = useAnalysisTableLayoutContext();
  const isRightFlyoutOpen = rightFlyout !== null;
  const { panels, handlePanelWidthChange } = useResizablePanels({
    isBottomFlyoutOpen: false,
    isRightFlyoutOpen,
  });

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={panels.MAIN_AND_FLYOUT_BOTTOM.id}
            panelRef={panels.MAIN_AND_FLYOUT_BOTTOM.ref}
            size={panels.MAIN_AND_FLYOUT_BOTTOM.size}
            paddingSize="none"
          >
            <ToolParamsGrid />
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isRightFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={panels.FLYOUT_RIGHT.id}
            panelRef={panels.FLYOUT_RIGHT.ref}
            size={panels.FLYOUT_RIGHT.size}
            paddingSize="none"
            minSize="300px"
          >
            {rightFlyout?.node}
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
