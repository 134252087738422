import axios from "axios";
import {
  File as DrsFile,
  Dataset,
  ProjectFilesManagerDocument,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { evictCacheFragment } from "utils/cache-fragments";
import { getRequestHeaders } from "utils/getRequestHeaders";

type ScheduleDataDeleteOptions = {
  datasetId: Dataset["id"];
  fileIds: DrsFile["id"][];
};

type ScheduleDeleteOptions = {
  datasetId: Dataset["id"];
};

export const drsDatasetDjango = {
  /**
   * Deletes a dataset and deletes the files in it.
   * @param options
   */
  scheduleDataDelete: async ({
    datasetId,
    fileIds,
  }: ScheduleDataDeleteOptions) => {
    // Execute request
    const baseUrl = getEnvVar("URL_DRS_DATASET_SCHEDULE_DATA_DELETE");
    const url = `${baseUrl}${datasetId}/`;
    const body = { date_data_deleted: new Date().toISOString() };
    const headers = await getRequestHeaders();
    await axios.patch(url, body, { headers });

    // Remove dataset from cache
    evictCacheFragment({
      __typename: "Dataset",
      id: datasetId,
    });

    // Remove dataset files from cache
    fileIds.forEach((fileId) => {
      evictCacheFragment({
        __typename: "File",
        id: fileId,
      });
    });
  },

  /**
   * Deletes a dataset and assigns the files in it back to the project.
   * @param options
   */
  scheduleDelete: async ({ datasetId }: ScheduleDeleteOptions) => {
    // Execute request
    const baseUrl = getEnvVar("URL_DRS_DATASET_SCHEDULE_DELETE");
    const url = `${baseUrl}${datasetId}/`;
    const body = { date_deleted: new Date().toISOString() };
    const headers = await getRequestHeaders();
    await axios.patch(url, body, { headers });

    // Remove dataset from cache
    evictCacheFragment({
      __typename: "Dataset",
      id: datasetId,
    });

    // Refetch project file assignments
    await client.refetchQueries({
      include: [ProjectFilesManagerDocument],
    });
  },
};
