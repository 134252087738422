import { EuiCallOut, EuiCallOutProps, EuiText } from "@inscopix/ideas-eui";

interface CalloutErrorProps extends Pick<EuiCallOutProps, "color" | "title"> {
  children?: React.ReactNode;
}
export const CallOutError = ({ children, ...props }: CalloutErrorProps) => {
  return (
    <EuiCallOut
      title="Sorry, there was an error"
      color="danger"
      iconType="warning"
      {...props}
    >
      {children ?? (
        <p>
          <EuiText>Please try again later or contact an administrator </EuiText>
        </p>
      )}
    </EuiCallOut>
  );
};
