import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";

import { AnalysisTableGroup, Project } from "graphql/_Types";
import { evictCacheFragment } from "utils/cache-fragments";
import { getEnvVar } from "ideas.env";

export type LibraryAnalysisTableRowDeleteInput = {
  analysisTableGroupId: AnalysisTableGroup["id"];
};

export type LibraryAnalysisTableRowDeleteResponse = {
  data: { project: Project["id"] };
};

const DELETE = async (analysisTableGroupId: AnalysisTableGroup["id"]) => {
  const url = `${getEnvVar(
    "URL_LIBRARY_ANALYSIS_TABLE_GROUP",
  )}${analysisTableGroupId}/`;
  const headers = await getRequestHeaders();
  const res = await axios.delete(url, { headers });

  if (res.status !== 204) {
    throw new Error("Failed to delete analysis table group");
  }

  evictCacheFragment({
    __typename: "AnalysisTableGroup",
    id: analysisTableGroupId,
  });

  return res;
};

export const libraryAnalysisTableGroupDjango = {
  DELETE,
};
