import { EuiFlexGroup, EuiLink } from "@inscopix/ideas-eui";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { NotificationAlert } from "./NotificationAlert";
import { useFetchAndSetProjectAccess } from "hooks/useFetchAndSetProjectAccess";
import { useCallback, useState } from "react";
import { TRegion } from "providers/RegionsProvider";
import { FileBadge } from "components/FileBadge/FileBadge";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { isUndefined } from "lodash";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { ModalTaskLogs } from "components/ButtonViewTaskLogs/ModalTaskLogs";
import assert from "assert";
import { isNonNull } from "utils/isNonNull";

export interface NotificationAlertFileProcessingProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchExternalNotifications?: () => void,
  ) => void;
  refetchExternalNotifications: () => void;
}

/** Component that renders a notification that a project was shared with the user */
export const NotificationAlertFileProcessing = ({
  data,
  onNotificationRead,
  refetchExternalNotifications,
}: NotificationAlertFileProcessingProps) => {
  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.fileProcessingNotification;
  assert(isNonNull(attrs));
  const { file } = attrs;
  assert(isNonNull(file));
  const project = file.project;
  assert(isNonNull(project));

  const { failedTask } = useGetDrsFileProcessingStatus(file);
  const { refetchCurrentUserProjectAccesses } = useFetchAndSetProjectAccess();
  const { routeMap } = useRoutes();
  const [showModalTaskLogs, setShowModalTaskLogs] = useState(false);

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(
        notificationId,
        data.region,
        refetchExternalNotifications,
      );
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchExternalNotifications,
    data.region,
  ]);

  const actionProjectLink = (() => {
    if (project.tenant === null) {
      return null;
    }

    const tenantKey = project.tenant.key;
    const projectPath = routeMap["PROJECT"].dynamicPath({
      projectKey: project.key,
      tenantKey: tenantKey,
    }).path;

    const handleClick = async () => {
      await refetchCurrentUserProjectAccesses();
      onNotificationReadMemoized();
    };

    return isUndefined(data.region) ? (
      <EuiLink to={projectPath} onClick={() => void handleClick()}>
        Go to project
      </EuiLink>
    ) : (
      <EuiLink
        href={`${data.region.url}${projectPath}`}
        target="_blank"
        onClick={() => void handleClick()}
      >
        Go to project <RegionBadge regionKey={data.region.key} />
      </EuiLink>
    );
  })();

  return (
    <>
      {showModalTaskLogs && failedTask !== undefined && (
        <ModalTaskLogs
          task={failedTask}
          onClose={() => setShowModalTaskLogs(false)}
        />
      )}
      <NotificationAlert
        dateCreated={dateCreated}
        hasSeen={hasSeen}
        title="Failed to process file"
        onNotificationRead={onNotificationReadMemoized}
        text={
          <span>
            An error occurred when reading metadata and generating previews for
            the file <FileBadge drsFile={file} /> in the project{" "}
            <strong>{project.name}</strong>
          </span>
        }
        action={
          <EuiFlexGroup>
            {actionProjectLink}
            {failedTask !== undefined && (
              <EuiLink onClick={() => setShowModalTaskLogs(true)}>
                View log
              </EuiLink>
            )}
          </EuiFlexGroup>
        }
      />
    </>
  );
};
