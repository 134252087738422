import { FileBadge } from "components/FileBadge/FileBadge";
import { FileSource } from "graphql/_Types";
import { IdeasFile } from "@inscopix/ideas-hyperformula";
import { useDataTableContext } from "../store/DataTableProvider";

interface CellValueRendererFileProps {
  file: IdeasFile;
}

/** Component that renders an `IdeasFile` cell value` */
export const CellValueRendererFile = ({ file }: CellValueRendererFileProps) => {
  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const isSelected = useDataTableContext(
    (s) => s.selectedFileId === file.attrs.id,
  );

  return (
    <FileBadge
      drsFile={{
        id: file.attrs.id,
        name: file.attrs.name,
        status: file.attrs.status,
        fileType: file.attrs.fileType,
        isSeries: file.attrs.isSeries,
        source: FileSource.Uploaded,
        processingStatus: file.attrs.processingStatus,
        seriesParentId: file.attrs.seriesParentId,
      }}
      isSelected={isSelected}
      onClick={() => {
        if (isSelected) {
          setSelectedFileId(undefined);
        } else {
          setSelectedFileId(file.attrs.id);
        }
      }}
    />
  );
};
