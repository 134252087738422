/** @jsxImportSource @emotion/react */
import { EuiText, EuiTreeView, EuiTreeViewProps } from "@inscopix/ideas-eui";
import { ModalToolRoiFrameParamWrapper } from "./ModalToolRoiFrameParamWrapper";
import { css } from "@emotion/react";
import { useModalToolRoiFrameParamData } from "./useModalToolRoiFrameParamData";
import { SelectedSourceFile } from "./ModalToolRoiFrameParamBase";

type SourceFile = ReturnType<
  typeof useModalToolRoiFrameParamData
>["files"][number];

interface ModalToolRoiFrameParamFileSelectProps {
  paramName: string;
  files: SourceFile[];
  setSelectedSourceFile: (file: SelectedSourceFile) => void;
  onClose: () => void;
}

/**
 * Component that renders a modal for selecting the source file used by the ROI
 * editor.
 */
export const ModalToolRoiFrameParamFileSelect = ({
  paramName,
  files,
  setSelectedSourceFile,
  onClose,
}: ModalToolRoiFrameParamFileSelectProps) => {
  return (
    <ModalToolRoiFrameParamWrapper paramName={paramName} onClose={onClose}>
      <EuiText>
        Select which input file you would like to use in the ROI editor. For
        series input, a single child of the series must be selected.
      </EuiText>
      <br />
      <EuiTreeView
        style={{
          border: "1px solid lightgrey",
          borderRadius: 5,
          padding: 10,
        }}
        css={css`
          .euiTreeView__nodeLabel {
            width: 100%;
          }
        `}
        aria-label="File tree"
        showExpansionArrows
        expandByDefault
        items={files.map((file) => {
          const treeItem: EuiTreeViewProps["items"][number] = {
            id: file.id,
            label: "",
          };
          if (file.isSeries) {
            treeItem.label = (
              <EuiText color="GrayText" size="s">
                {file.name + " (series)"}
              </EuiText>
            );
            treeItem.children = file.seriesMembers.map((seriesFile) => {
              const drsFile = {
                ...seriesFile,
                isSeries: false,
                source: file.source,
              };
              return {
                id: seriesFile.id,
                label: (
                  <EuiText
                    onClick={() => setSelectedSourceFile(drsFile)}
                    size="s"
                  >
                    {drsFile.name}
                  </EuiText>
                ),
              };
            });
          } else {
            treeItem.label = (
              <EuiText onClick={() => setSelectedSourceFile(file)} size="s">
                {file.name}
              </EuiText>
            );
          }
          return treeItem;
        })}
      />
    </ModalToolRoiFrameParamWrapper>
  );
};
