import { memo } from "react";
import { ApplicationUser } from "graphql/_Types";
import { UserAvatar } from "components/UserAvatar/UserAvatar";

export interface GridRendererTaskUserProps {
  userId: ApplicationUser["id"] | undefined;
}

/**
 * A component for rendering a user avatar for a submitted task
 */
export const GridRendererTaskUser = memo(function GridRendererTaskUser({
  userId,
}: GridRendererTaskUserProps) {
  return userId !== undefined ? <UserAvatar size="s" userId={userId} /> : null;
});
