import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { ModalCreateDataTable } from "../modals/ModalCreateDataTable";
import { ModalCreateAnalysisTable } from "../modals/ModalCreateAnalysisTable";

/**
 * Component that renders a button for creating a new data table or analysis
 * table
 */
export const ButtonCreateTable = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [visibleModal, setVisibleModal] = useState<"data" | "analysis">();

  return (
    <>
      {visibleModal === "data" && (
        <ModalCreateDataTable onClose={() => setVisibleModal(undefined)} />
      )}
      {visibleModal === "analysis" && (
        <ModalCreateAnalysisTable onClose={() => setVisibleModal(undefined)} />
      )}
      <EuiPopover
        id={htmlIdGenerator()()}
        button={
          <EuiButtonIcon
            aria-label="Create table"
            iconType="plus"
            onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={() => setIsPopoverOpen(false)}
        anchorPosition="upCenter"
      >
        <EuiFlexGroup
          direction="column"
          gutterSize="none"
          alignItems="flexStart"
        >
          <EuiFlexItem>
            <EuiButtonEmpty
              iconType="tableDensityNormal"
              onClick={() => {
                setVisibleModal("data");
                setIsPopoverOpen(false);
              }}
            >
              Data table
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonEmpty
              iconType="indexMapping"
              onClick={() => {
                setVisibleModal("analysis");
                setIsPopoverOpen(false);
              }}
            >
              Analysis table
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPopover>
    </>
  );
};
