import { keyBy } from "lodash";

/***********
  BASE TYPE
 ***********/
export type ResourceTier = (typeof RESOURCE_TIERS)[keyof typeof RESOURCE_TIERS];

/************************
 RESOURCE TIER DEFINITIONS
 ************************/
const TIER_1 = {
  id: 1,
  cpu: 4,
  gpu: 0,
  memory: 8000,
  credits: 0.5,
} as const;

const TIER_2 = {
  id: 2,
  cpu: 8,
  gpu: 0,
  memory: 32000,
  credits: 1,
} as const;

const TIER_3 = {
  id: 3,
  cpu: 16,
  gpu: 0,
  memory: 128000,
  credits: 2,
} as const;

const TIER_4 = {
  id: 4,
  cpu: 32,
  gpu: 0,
  memory: 256000,
  credits: 4,
} as const;

const TIER_5 = {
  id: 5,
  cpu: 4,
  gpu: 1,
  memory: 8000,
  credits: 2,
} as const;

export const RESOURCE_TIERS = {
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_4,
  TIER_5,
};

export const RESOURCE_TIERS_BY_ID = keyBy(
  RESOURCE_TIERS,
  (resourceTier) => resourceTier.id,
) as {
  [id in ResourceTier["id"]]: ResourceTier;
} & {
  [unknown: number]: ResourceTier | undefined;
};
