import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import {
  UserPermission,
  USER_ACCESS_LEVELS_BY_ID,
  UserAccessLevelKey,
  USER_ACCESS_LEVELS_BY_KEY,
  USER_PERMISSIONS,
  UserProjectAccess,
  UserAccessLevel,
} from "types/UserAccessLevels";
import {
  camelCase,
  cloneDeep,
  fill,
  includes,
  mapKeys,
  snakeCase,
  zipObject,
} from "lodash";
import { useLibraryProjectPatchDjango } from "hooks/useLibraryProjectPatchDjango";
import { ApplicationUser, Project } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import axios, { AxiosResponse } from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import {
  extractPermissionsList,
  getRoleFromPermissions,
} from "utils/permissions";
import { updateCacheFragment } from "utils/cache-fragments";
import { SnakeCase } from "type-fest";
import { ProjectSharingInviteStatus } from "types/constants";
import { useCallback } from "react";
import { useFetchAndSetProjectAccess } from "./useFetchAndSetProjectAccess";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export type UserProjectAccessDjango = {
  copy: boolean;
  dateGranted: string;
  download: boolean;
  edit: boolean;
  execute: boolean;
  grantAccess: boolean;
  grantedBy: string;
  id: string;
  project: Project["id"];
  upload: boolean;
  user: ApplicationUser["id"];
  view: boolean;
};

export type UserProjectAccessDjangoResponse = UserProjectAccessDjango[];

export type ProjectSharingExternalDjango = {
  date_accepted: string | null;
  date_created: string;
  date_revoked: string | null;
  email: string;
  id: string;
  invitee: ApplicationUser["id"] | null;
  inviter: ApplicationUser["id"];
  project: Project["id"];
  status: ProjectSharingInviteStatus;
  project_access_level: UserAccessLevel["id"];
};

export type ProjectSharingExternalResponse = ProjectSharingExternalDjango[];

export type CreateIndividualUserAccessLevelsInput = {
  project: Project["id"];
  user: ApplicationUser["id"];
} & Record<SnakeCase<UserPermission>, boolean>;

export type CreateIndividualUserAccessLevelsResponse = {
  id: UserProjectAccess["id"];
  project: Project["id"];
  date_granted: UserProjectAccess["dateGranted"];
  granted_by: ApplicationUser["id"];
  user: ApplicationUser["id"];
} & Record<SnakeCase<UserPermission>, boolean>;

/**
 * Hook to get the current user's permission level for the current project for performing a given action.
 * A user's permission level is determined by the following hierarchy:
 * 1. Project owner always has all permissions
 * 2. If the user has a default user access level, they have the permissions associated with that level
 * 3. If the user has an individual user access level, they have the permissions associated with that level
 * 4. The use will have the most permissive permissions of all the above
 *
 * @param userPermission The permission to check for
 * @returns hasPermission Method that returns whether the current user has the given permission. updateOrganizationUserAccessLevel Method to update the default user access level for the project.
 * @example
 * const { hasPermission } = useProjectPermission();
 * const canEdit = hasPermission("edit");
 * const canView = hasPermission("view");
 */

export const useProjectPermission = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const tenants = useUserContext((s) => s.tenants);
  const { project } = useProjectDataContext();
  const { libraryProjectPatch } = useLibraryProjectPatchDjango();
  const tenantUsers = useTenantContext((s) => s.tenantUsers);
  const {
    fetchCurrentUserProjectAccesses,
    permissions,
    updatePermission,
    removePermission,
    refetchCurrentUserProjectAccesses,
  } = useFetchAndSetProjectAccess();

  //
  // determining if the user a certain permission
  //

  const isCurrentUserInTenantUsers = tenantUsers.some(
    (user) => user.id === currentUser.id,
  );
  // If a user does not belong to the tenant, the organization does not apply to them
  // and they are considered a viewer
  const orgUserAccessLevel = isCurrentUserInTenantUsers
    ? project.defaultUserAccessLevel
    : USER_ACCESS_LEVELS_BY_KEY["viewer"].id;

  const hasOrganizationalPermission = useCallback(
    (userPermission: UserPermission) =>
      includes(
        USER_ACCESS_LEVELS_BY_ID[orgUserAccessLevel]?.permissions,
        userPermission,
      ),
    [orgUserAccessLevel],
  );

  const hasIndividualPermission = useCallback(
    (userPermission: UserPermission) => {
      const currentUserPermission = permissions?.find(
        (permission) =>
          permission.user === currentUser.id &&
          permission.project === project.id,
      );

      if (currentUserPermission === undefined) {
        return false;
      }

      return currentUserPermission[userPermission];
    },
    [currentUser.id, permissions, project.id],
  );

  const hasPermission = useCallback(
    (userPermission: UserPermission) => {
      const isOrganizationAdmin = tenants.some(
        (tenant) =>
          tenant.id === project.tenantId && tenant.role.key === "admin",
      );

      /**
       * Org admins have grant access permission
       */
      if (userPermission === "grantAccess" && isOrganizationAdmin) {
        return true;
      }

      return (
        currentUser.id === project.userId ||
        hasOrganizationalPermission(userPermission) ||
        hasIndividualPermission(userPermission)
      );
    },
    [
      currentUser.id,
      hasIndividualPermission,
      hasOrganizationalPermission,
      project.tenantId,
      project.userId,
      tenants,
    ],
  );

  //
  // Fetch user access level for a project
  //
  const fetchCurrentProjectAccesses = useCallback(async () => {
    const permissions = await fetchCurrentUserProjectAccesses(project.id);
    return permissions;
  }, [fetchCurrentUserProjectAccesses, project.id]);

  //
  // Fetch external user access for a project
  //
  const fetchCurrentProjectAccessesExternal = useCallback(async () => {
    const url = getEnvVar("URL_LIBRARY_PROJECT_SHARE");
    const params = { project: project.id };
    const headers = await getRequestHeaders();
    const response = await axios.get<ProjectSharingExternalResponse>(url, {
      headers,
      params,
    });
    return response.data.filter(
      (item) => item.status !== ProjectSharingInviteStatus.REVOKED,
    ); // do not show revoked invitations
  }, [project.id]);

  //
  // Fetch user access level for all project that a user has access to
  //

  //
  // Update Organization User Access Level
  //
  const updateOrganizationUserAccessLevel = async (
    projectId: Project["id"],
    defaultUserAccessLevel: Project["defaultUserAccessLevel"],
  ) => {
    await libraryProjectPatch(projectId, {
      defaultUserAccessLevel,
    });

    updateCacheFragment({
      __typename: "Project",
      id: projectId,
      update: (data) => {
        const newData = cloneDeep(data);
        newData.defaultUserAccessLevel = defaultUserAccessLevel;
        return newData;
      },
    });
  };

  //
  // Create Individual User Access Level
  //
  const createIndividualUserAccessLevels = async (
    users: {
      userId: ApplicationUser["id"];
      userAccessLevel: UserAccessLevelKey;
    }[],
  ) => {
    const url = `${getEnvVar("URL_LIBRARY_PROJECT_ACCESS")}`;
    const headers = await getRequestHeaders();
    const requests = users.map((user) => {
      const body: CreateIndividualUserAccessLevelsInput = {
        project: project.id,
        user: user.userId,
        download: false,
        edit: false,
        execute: false,
        grant_access: false,
        upload: false,
        view: false,
      };

      const permissions =
        USER_ACCESS_LEVELS_BY_KEY[user.userAccessLevel].permissions;
      permissions.forEach((p) => {
        const snakeCasedPermission = snakeCase(p) as SnakeCase<UserPermission>;
        body[snakeCasedPermission] = true;
      });

      return axios.post<
        CreateIndividualUserAccessLevelsResponse,
        AxiosResponse<CreateIndividualUserAccessLevelsResponse>,
        CreateIndividualUserAccessLevelsInput
      >(url, body, { headers });
    });

    const responses = await axios.all(requests);

    const usersAdded = responses.map((res) => {
      return {
        id: res.data.id,
        userId: res.data.user,
        accessLevel: getRoleFromPermissions(
          extractPermissionsList(mapKeys(res.data, (_, key) => camelCase(key))),
        ),
      };
    });

    // Refetch the updated permissions (This will automatically sync the store with the new permissions)
    void refetchCurrentUserProjectAccesses();

    return usersAdded;
  };

  //
  // Update Individual User Access Level
  //
  const updateIndividualUserAccessLevel = async (
    accessId: UserProjectAccess["id"],
    userId: ApplicationUser["id"],
    userAccessLevel: UserAccessLevelKey,
  ) => {
    const url = `${getEnvVar("URL_LIBRARY_PROJECT_ACCESS")}${accessId}/`;

    const permissionKeys =
      USER_ACCESS_LEVELS_BY_KEY[userAccessLevel].permissions;

    // Create an object with all permissions set to false
    const permissionObj = zipObject(
      USER_PERMISSIONS,
      fill(Array(USER_PERMISSIONS.length), false),
    );

    // Set the permissions that are required by the access level to true
    permissionKeys.forEach((key) => {
      permissionObj[key] = true;
    });

    const snakeCasedPayload = mapKeys(permissionObj, (_, key) =>
      snakeCase(key),
    );
    const response = await axios.patch(
      url,
      {
        project: project.id,
        user: userId,
        ...snakeCasedPayload,
      },
      {
        headers: await getRequestHeaders(),
      },
    );

    const camelCasedResponse = mapKeys(response.data, (_, key) =>
      camelCase(key),
    ) as UserProjectAccessDjango;

    // Update the store with the new permissions
    updatePermission(camelCasedResponse);

    return camelCasedResponse;
  };

  //
  // Remove individual user access level
  //

  const removeIndividualUserAccessLevel = async (
    accessId: UserProjectAccess["id"],
    userId: ApplicationUser["id"],
  ) => {
    const url = `${getEnvVar("URL_LIBRARY_PROJECT_ACCESS")}${accessId}/`;
    const headers = await getRequestHeaders();
    const response = await axios.delete(url, {
      headers,
      data: {
        project: project.id,
        user: userId,
      },
    });

    removePermission(accessId);

    return mapKeys(response.data, (_, key) => camelCase(key));
  };

  //
  // Invite external user
  //
  const inviteExternalUser = async (email: string) => {
    const url = `${getEnvVar("URL_LIBRARY_PROJECT_SHARE")}`;

    const headers = await getRequestHeaders();
    const response = await axios.post<ProjectSharingExternalDjango>(
      url,
      {
        email,
        project: project.id,
        project_access_level: USER_ACCESS_LEVELS_BY_KEY["copier"].id,
      },
      {
        headers,
      },
    );
    return response.data;
  };

  //
  // Revoke open invitation for external user
  //
  const revokeOpenInvitation = async (
    invitationId: ProjectSharingExternalDjango["id"],
  ) => {
    const url = `${getEnvVar(
      "URL_LIBRARY_PROJECT_SHARE",
    )}revoke/${invitationId}/`;

    const headers = await getRequestHeaders();
    const response = await axios.post<ProjectSharingExternalDjango>(
      url,
      {}, // empty body
      {
        headers,
      },
    );
    return response.data;
  };

  //
  // Resend invitation for external user
  //
  const resendInvitation = async (
    invitationId: ProjectSharingExternalDjango["id"],
  ) => {
    const url = `${getEnvVar(
      "URL_LIBRARY_PROJECT_SHARE",
    )}resend/${invitationId}/`;

    const headers = await getRequestHeaders();
    const response = await axios.post<ProjectSharingExternalDjango>(
      url,
      {}, // empty body
      {
        headers,
      },
    );
    return response.data;
  };

  /**
   * Updates the project access level of an existing invitation.
   * @param options
   * @returns The updated invitation.
   */
  const updateInvitationAccessLevel = useCallback(
    async ({
      invitationId,
      projectAccessLevel,
    }: {
      invitationId: ProjectSharingExternalDjango["id"];
      projectAccessLevel: UserAccessLevel["id"];
    }) => {
      const baseUrl = getEnvVar("URL_LIBRARY_PROJECT_SHARE");
      const url = `${baseUrl}update_access_level/${invitationId}/`;
      const headers = await getRequestHeaders();
      const body = { project_access_level: projectAccessLevel };
      const response = await axios.patch<ProjectSharingExternalDjango>(
        url,
        body,
        { headers },
      );
      return response.data;
    },
    [],
  );

  return {
    hasPermission,
    fetchCurrentProjectAccesses,
    fetchCurrentProjectAccessesExternal,
    updateOrganizationUserAccessLevel,
    createIndividualUserAccessLevels,
    updateIndividualUserAccessLevel,
    removeIndividualUserAccessLevel,
    inviteExternalUser,
    revokeOpenInvitation,
    resendInvitation,
    updateInvitationAccessLevel,
  };
};
