/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
} from "@inscopix/ideas-eui";
import { Fragment, useCallback, useMemo, useState } from "react";

import { ShapeState, useRoiEditorContext } from "./RoiEditorProvider";
import * as fabric from "fabric";
import { ToolRoiFrameParam } from "../ToolParamsGrid/ToolParamsGrid.types";
import {
  Rect,
  Shape,
  validateToolRoiFrameParamValue,
} from "./RoiEditor.helpers";
import { captureException } from "@sentry/react";
import { isDefined } from "utils/isDefined";
import { RoiEditorVideoPlaybackControls } from "components/RoiEditor/RoiEditorVideoPlaybackControls";
import { RoiEditorBoundingBoxControls } from "components/RoiEditor/RoiEditorBoundingBoxControls";
import { RoiEditorRoiGroup } from "components/RoiEditor/RoiEditorRoiGroup";
import { debounce } from "lodash";
import { calculateDefaultScale } from "components/CroppingFrameSelector/CroppingFrameSelector.helpers";
import { RoiEditorScaleControls } from "./RoiEditorScaleControls";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { Canvas, RoiEditorCanvas } from "./RoiEditorCanvas";

export type Point = { x: number; y: number };

export type ShapeType = NonNullable<
  ToolRoiFrameParam["type"]["roi_settings"]["groups"]
>[number]["shapes"][number];

export const ALLOWED_SHAPES: ShapeType[] = [
  "point",
  "line",
  "rectangle",
  "polygon",
  "ellipse",
  "circle",
  "contour",
];

const getFittedScale = (image: fabric.Image) => {
  return calculateDefaultScale(
    image,
    // These numbers reduce the target canvas size by the indicated number of pixels
    // to account for other layout elements
    // can we tie these numbers to a constant used elsewhere in the UI?
    600,
    400,
  );
};

export interface RoiEditorProps {
  initialShapes?: ShapeJson[];
  readOnly?: boolean;
  onAccept: (shapes: ShapeJson[]) => void;
  onCancel: () => void;
  image: fabric.Image;
  roiSettings: ToolRoiFrameParam["type"]["roi_settings"];
}

export const RoiEditor = ({
  initialShapes,
  image,
  onAccept,
  onCancel,
  readOnly,
  roiSettings,
}: RoiEditorProps) => {
  const [canvas, setCanvas] = useState<Canvas>();
  const applyCanvasListeners = useRoiEditorContext(
    (s) => s.applyCanvasListeners,
  );
  const selectedShapeIds = useRoiEditorContext((s) => s.selectedShapeIds);
  const isDrawing = useRoiEditorContext((s) => s.isDrawing);
  const shapes = useRoiEditorContext((s) => s.shapes);
  const [scale, setScale] = useState(getFittedScale(image));
  const shouldRenderBoundingBoxControls =
    useRoiEditorContext((s) => s.boundingBox?.id) !== undefined;

  const initializeShapes = useCallback(
    // this is a param instead of reading from state because this needs to be called
    // before the state is set in onCanvasReady
    (canvas: Canvas) => {
      const boundingBoxSettings = roiSettings.bounding_box;

      const shapes = initialShapes ?? [];

      /**
       * Draw existing shapes on canvas and bounding box if applicable
       */
      if (boundingBoxSettings !== undefined) {
        if (shapes.length === 0) {
          canvas.drawBoundingBox({
            groupKey: boundingBoxSettings.key,
            name: boundingBoxSettings.name,
          });
        }
      }
      shapes.forEach((shape) => {
        canvas.drawShapeFromJson(shape);
      });
    },
    [initialShapes, roiSettings.bounding_box],
  );

  /**
   * Initialize canvas
   */
  const onCanvasReady = useCallback(
    (canvas: Canvas) => {
      applyCanvasListeners(canvas, roiSettings);
      canvas.preserveObjectStacking = true;

      canvas.setZoom(scale);

      initializeShapes(canvas);

      if (readOnly) {
        /**
         * This will lock interactions on the canvas through fabric controls when in read only mode.
         * This necessary, but does not cover 100% of cases safely because you could still interact
         * with the canvas or objects programmatically.
         * The safest solution would be to set a flag internally on the canvas and prevent modifications
         * by any means there, but the time and complexity to implement that isn't quite worth it at the moment.
         */
        const readOnlyProps: Partial<fabric.TFabricObjectProps> = {
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasControls: false,
        };
        canvas.getObjects().forEach((obj) => obj.set({ ...readOnlyProps }));
      }

      setCanvas(canvas);
      canvas.renderAll();
    },
    [applyCanvasListeners, initializeShapes, readOnly, roiSettings, scale],
  );

  const handleResetAll = useCallback(() => {
    if (canvas !== undefined) {
      canvas.getObjects().forEach((obj) => canvas.remove(obj));
      initializeShapes(canvas);
    }
  }, [canvas, initializeShapes]);

  const handleResetBoundingBox = useCallback(
    (key: string, name: string) => {
      if (isDefined(canvas)) {
        canvas.drawBoundingBox({
          groupKey: key,
          name: name,
        });
      }
    },
    [canvas],
  );

  const handleChangeBoundingBoxDimensions = useCallback(
    (
      newDimensions: {
        left?: number;
        top?: number;
        width?: number;
        height?: number;
      },
      key: string,
      name: string,
    ) => {
      if (isDefined(canvas)) {
        canvas.drawBoundingBox(
          {
            groupKey: key,
            name: name,
          },
          newDimensions,
        );
      }
    },
    [canvas],
  );

  const handleClickShape = useCallback(
    (shape: Shape) => {
      if (isDefined(canvas)) {
        canvas.setActiveObject(shape);
        canvas.renderAll();
      }
    },
    [canvas],
  );

  const handleDrawShape = useCallback(
    (shape: ShapeType, groupKey: string) => {
      if (isDefined(canvas)) {
        switch (shape) {
          case "circle":
          case "ellipse":
          case "rectangle":
            return canvas.dragToDraw(shape, groupKey);
          case "polygon":
            return canvas.drawPolygon(groupKey);
          case "point":
            return captureException("Point not implemented");
          case "contour":
            return canvas.drawContour(groupKey);
          case "line":
            return captureException("Line not implemented");
        }
      }
    },
    [canvas],
  );

  const handleAccept = useCallback(() => {
    onAccept(
      shapes.map((shape) => (shape.object as Rect).jsonify()).filter(isDefined),
    );
  }, [onAccept, shapes]);

  const handleChangeShapeColor = useMemo(() => {
    return debounce(
      (shape: Shape, color: string) => {
        shape.set({ stroke: color });
        shape.canvas?.renderAll();
      },
      10,
      { trailing: true },
    );
  }, []);

  const handleRemoveShape = useCallback((shape: ShapeState) => {
    const canvas = shape.object.canvas;
    canvas?.remove(shape.object);
  }, []);

  const handleDeselectShape = useCallback((shape: Shape) => {
    // TODO: Implement
  }, []);

  const handleChangeShapeName = useCallback((shape: Shape, newName: string) => {
    shape.set({ name: newName });
  }, []);

  const validationState = useMemo(() => {
    const jsonShapes = shapes
      .map(({ object }) => object.jsonify())
      .filter(isDefined);
    return validateToolRoiFrameParamValue(jsonShapes, roiSettings);
  }, [roiSettings, shapes]);

  const handleChangeScale = useCallback(
    (newScale: number | "fit") => {
      if (newScale === "fit") {
        newScale = getFittedScale(image);
      }

      setScale(newScale);
      if (!isNaN(newScale) && newScale > 0) {
        canvas?.setZoom(newScale);
        canvas?.renderAll();
      }
    },
    [canvas, image],
  );

  return (
    <EuiFlexGroup style={{ height: "100%" }}>
      <EuiFlexItem grow={false}>
        <div style={{ width: 420, overflow: "scroll", paddingRight: 20 }}>
          {shouldRenderBoundingBoxControls && (
            <>
              <RoiEditorBoundingBoxControls
                onChange={handleChangeBoundingBoxDimensions}
                onReset={handleResetBoundingBox}
                isReadOnly={readOnly}
              />
              <EuiHorizontalRule />
            </>
          )}

          {roiSettings.groups?.map((group, idx, arr) => {
            const isLastGroup = idx === arr.length - 1;
            return (
              <Fragment key={group.key}>
                <RoiEditorRoiGroup
                  validationState={validationState}
                  isDrawing={isDrawing}
                  onCreateShape={handleDrawShape}
                  onSelectShape={handleClickShape}
                  onDeselectShape={handleDeselectShape}
                  group={group}
                  shapes={shapes.filter((s) => s.object.groupKey === group.key)}
                  selectedShapeIds={selectedShapeIds}
                  onRemoveShape={handleRemoveShape}
                  onChangeShapeColor={handleChangeShapeColor}
                  onChangeShapeName={handleChangeShapeName}
                  isReadOnly={readOnly}
                />
                {!isLastGroup && <EuiHorizontalRule />}
              </Fragment>
            );
          })}
        </div>

        <EuiHorizontalRule />

        <EuiFlexGroup gutterSize="s">
          <EuiFlexItem>
            <EuiButton
              color="text"
              onClick={handleResetAll}
              disabled={readOnly}
            >
              Reset all
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              color="danger"
              fill
              onClick={onCancel}
              disabled={readOnly}
            >
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton fill onClick={handleAccept} disabled={readOnly}>
              Save
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem style={{ height: "100%", overflow: "hidden" }}>
        <EuiPanel hasBorder hasShadow={false} style={{ height: "100%" }}>
          <EuiFlexGroup direction="column" style={{ height: "100%" }}>
            <EuiFlexItem grow={false}>
              <RoiEditorScaleControls
                scale={scale}
                onChangeScale={handleChangeScale}
              />
            </EuiFlexItem>
            <EuiFlexItem
              style={{
                alignItems: "safe center",
                justifyContent: "safe center",
                overflow: "scroll",
              }}
            >
              <RoiEditorCanvas
                backgroundImage={image}
                onCanvasReady={onCanvasReady}
              />
            </EuiFlexItem>
            {isDefined(canvas) && (
              <EuiFlexItem grow={false}>
                <RoiEditorVideoPlaybackControls canvas={canvas} image={image} />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
