import { EuiButtonIcon, EuiButtonIconProps } from "@inscopix/ideas-eui";
import { Task } from "graphql/_Types";
import { useModalContext } from "providers/ModalProvider/ModalProvider";
import { TaskStatus } from "types/constants";
import { Tooltip } from "../Tooltip/Tooltip";
import { ModalTaskLogs } from "./ModalTaskLogs";
import { memo } from "react";

export interface ButtonViewTaskLogsProps
  extends Pick<EuiButtonIconProps, "color"> {
  taskId: Task["id"];
  taskStatus: Task["status"];
  taskCreated: Task["created"];
}

export const ButtonViewTaskLogs = memo(function ButtonViewTaskLogs({
  taskId,
  taskStatus,
  taskCreated,
  ...buttonIconProps
}: ButtonViewTaskLogsProps) {
  const buttonDisabled =
    taskStatus !== TaskStatus["COMPLETE"] &&
    taskStatus !== TaskStatus["ERROR"] &&
    taskStatus !== TaskStatus["FAILED"];

  const toolTip = buttonDisabled
    ? "Task log available upon completion"
    : "View task logs";

  const { openModal, closeModal } = useModalContext();
  const showModal = () =>
    openModal(
      <ModalTaskLogs
        task={{ id: taskId, status: taskStatus, created: taskCreated }}
        onClose={closeModal}
      />,
    );

  return (
    <>
      <Tooltip content={toolTip}>
        <EuiButtonIcon
          aria-label="View task logs"
          isDisabled={buttonDisabled}
          iconType="tableDensityExpanded"
          onClick={showModal}
          {...buttonIconProps}
        />
      </Tooltip>
    </>
  );
});
