import {
  CellValue,
  DetailedCellError,
  Group,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { CellErrorLoading } from "../store/engine";
import { CellValueRendererLoading } from "./CellValueRendererLoading";
import { CellValueRendererError } from "./CellValueRendererError";
import { CellValueRendererFile } from "./CellValueRendererFile";
import { CellValueRendererRoiFrame } from "./CellValueRendererRoiFrame";
import { CellValueRendererRowIdentifier } from "./CellValueRendererRowIdentifier";
import {
  EuiFlexGroup,
  EuiFlexItem,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";

interface CellValueRendererBaseProps {
  value: CellValue;
}

/** Component that renders any cell value */
export const CellValueRendererBase = ({
  value,
}: CellValueRendererBaseProps) => {
  if (value instanceof DetailedCellError) {
    if (value.message === CellErrorLoading.message) {
      return <CellValueRendererLoading />;
    } else {
      return <CellValueRendererError error={value} />;
    }
  }

  if (value instanceof IdeasFile) {
    return <CellValueRendererFile file={value} />;
  }

  if (value instanceof RoiFrame) {
    return <CellValueRendererRoiFrame roiFrame={value} />;
  }

  if (value instanceof RowIdentifier) {
    return <CellValueRendererRowIdentifier rowIdentifier={value} />;
  }

  if (value instanceof Group) {
    return (
      <EuiFlexGroup alignItems="center" gutterSize="xs">
        {value.cellValues.map((cellValue) => (
          <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
            <CellValueRendererBase value={cellValue} />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    );
  }

  // Empty cell value
  if (value === null) {
    return null;
  }

  return <span>{value.toString()}</span>;
};
