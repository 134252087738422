/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "../store/DataTableProvider";
import { DataTableSyncStatusBadge } from "./DataTableSyncStatusBadge";
import { useState } from "react";
import { ModalEditDataTable } from "../modals/ModalEditDataTable";
import { ModalEditAnalysisTable } from "../modals/ModalEditAnalysisTable";

/**
 * Component that renders information and actions for a data table or analysis
 * table
 */
export const DataTableHeader = () => {
  const [visibleModal, setVisibleModal] = useState<"data" | "analysis">();
  const selectedTable = useDataTableContext((s) => {
    const { selectedTableId, tables } = s;
    return tables.find((table) => table.id === selectedTableId);
  });

  if (selectedTable === undefined) {
    return (
      <EuiTitle
        size="m"
        css={css`
          padding: 5px;
        `}
      >
        <h1>No table selected</h1>
      </EuiTitle>
    );
  }

  return (
    <>
      {visibleModal === "data" && (
        <ModalEditDataTable
          table={selectedTable}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
      {visibleModal === "analysis" && (
        <ModalEditAnalysisTable
          table={selectedTable}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
      <EuiFlexGroup
        alignItems="center"
        gutterSize="s"
        style={{ padding: 5 }}
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiTitle size="m">
            <h1>
              {selectedTable.name}{" "}
              <span
                css={css`
                  color: grey;
                `}
              >
                ({selectedTable.key})
              </span>
            </h1>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label="Edit table"
            iconType="gear"
            onClick={() => setVisibleModal(selectedTable.kind)}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <DataTableSyncStatusBadge />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
