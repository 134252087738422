import { EuiBadge, EuiLoadingSpinner } from "@inscopix/ideas-eui";
import { useCallback, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { useDataTableContext } from "../store/DataTableProvider";

/**
 * Component that renders a badge to indicate whether the project is syncing.
 *
 * This component all handles displaying a browser prompt when the user tries
 * to leaves while changes are still being persisted to the server.
 */
export const DataTableSyncStatusBadge = () => {
  const syncStatus = useDataTableContext((s) => s.syncStatus);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (syncStatus.status !== "idle") {
      window.addEventListener("beforeunload", handleBeforeUnload);
      setIsPromptVisible(true);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setIsPromptVisible(false);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload, syncStatus.status]);

  const { color, icon, statusText } = (() => {
    switch (syncStatus.status) {
      case "idle":
        return {
          color: "hollow",
          icon: "check",
          statusText: "Changes synced",
        };
      case "syncing":
        return {
          color: "hollow",
          icon: () => <EuiLoadingSpinner size="s" style={{ marginRight: 4 }} />,
          statusText: "Syncing changes...",
        };
      case "error":
        return {
          color: "danger",
          icon: "error",
          statusText: "Error syncing changes",
        };
    }
  })();

  return (
    <>
      <Prompt
        when={isPromptVisible}
        message="Leaving the page will erase unsaved changes. Continue anyway?"
      />
      <EuiBadge color={color} iconType={icon}>
        {statusText}
      </EuiBadge>
    </>
  );
};
