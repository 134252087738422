/** @jsxImportSource @emotion/react */
import {
  EuiText,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiLoadingLogo,
} from "@inscopix/ideas-eui";
import { ToolRoiFrameParam } from "../ToolParamsGrid.types";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ModalToolRoiFrameParamWrapper } from "./ModalToolRoiFrameParamWrapper";
import { FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { RoiEditorProps } from "../../RoiEditor/RoiEditor";
import { ModalToolRoiFrameParamWithFile } from "./ModalToolRoiFrameParamWithFile";
import { ComponentType, useState } from "react";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { useModalToolRoiFrameParamData } from "./useModalToolRoiFrameParamData";
import { ModalToolRoiFrameParamFileSelect } from "./ModalToolRoiFrameParamFileSelect";
import { css } from "@emotion/react";

export type SelectedSourceFile = Pick<
  ReturnType<typeof useModalToolRoiFrameParamData>["files"][number],
  "id" | "metadata"
>;

export type ModalToolRoiFrameParamBaseProps = {
  fileIds: string[];
  paramName: string;
  isReadOnly: boolean;
  onSave: (shapes: ShapeJson[]) => void;
  onClose: () => void;
  sourceFile: ToolRoiFrameParam["type"]["source_file"];
  roiSettings: ToolRoiFrameParam["type"]["roi_settings"];
  // Grids using this modal will allow users to jump to the file input column
  // when no files are found. This requires access to a grid API instance and
  // should be decoupled from the base modal implementation.
  NoSourceFiles: ComponentType;
} & Pick<RoiEditorProps, "initialShapes">;

export const ModalToolRoiFrameParamBase = ({
  fileIds,
  paramName,
  initialShapes,
  isReadOnly,
  onSave,
  onClose,
  sourceFile,
  roiSettings,
  NoSourceFiles,
}: ModalToolRoiFrameParamBaseProps) => {
  const [selectedSourceFile, setSelectedSourceFile] =
    useState<SelectedSourceFile>();

  const { files, fileIdsNotFound, isLoading, error } =
    useModalToolRoiFrameParamData(fileIds);

  if (isLoading) {
    return (
      <ModalToolRoiFrameParamWrapper
        paramName={paramName}
        onClose={onClose}
        readOnly={isReadOnly}
        fullScreen
      >
        <EuiEmptyPrompt
          icon={<EuiLoadingLogo logo="vector" size="xl" />}
          title={<h3>Loading...</h3>}
          titleSize="s"
          css={css`
            height: 100%;
            .euiEmptyPrompt__main {
              height: 100%;
            }
          `}
        />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  // Failed to fetch file data
  if (error !== undefined) {
    return (
      <ModalToolRoiFrameParamWrapper
        paramName={paramName}
        onClose={onClose}
        readOnly={isReadOnly}
        fullScreen
      >
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  /**
   * None of the selected files could be found (i.e. all have been deleted after they were selected)
   */
  const isFileNotFound = files.length === 0 && fileIdsNotFound.length > 0;

  if (isFileNotFound) {
    return (
      <ModalToolRoiFrameParamWrapper paramName={paramName} onClose={onClose}>
        <EuiCallOut>
          <EuiText>
            <p>File not found.</p>
            <p>
              The selected file could not be located. It may have been deleted.{" "}
            </p>
          </EuiText>
        </EuiCallOut>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  /**
   * No file selected
   */
  if (files.length === 0) {
    return <NoSourceFiles />;
  }

  /**
   * Auto pick if only one file
   */
  if (
    files.length === 1 &&
    !files[0].isSeries &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(files[0]);
  }

  /**
   * Multiple valid files, allow user to select one
   */

  if (selectedSourceFile === undefined) {
    return (
      <ModalToolRoiFrameParamFileSelect
        paramName={paramName}
        files={files}
        setSelectedSourceFile={setSelectedSourceFile}
        onClose={onClose}
      />
    );
  }

  const previewFormat = FILE_FORMATS_BY_KEY[sourceFile.data.file_format]?.id;

  if (previewFormat === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper paramName={paramName} onClose={onClose}>
        <CallOutError>Improper tool configuration.</CallOutError>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  return (
    <ModalToolRoiFrameParamWrapper
      paramName={paramName}
      onClose={onClose}
      readOnly={isReadOnly}
      fullScreen
    >
      <ModalToolRoiFrameParamWithFile
        roiSettings={roiSettings}
        onCancel={onClose}
        file={selectedSourceFile}
        previewType={{
          fileFormat: previewFormat,
          key: sourceFile.data.key,
        }}
        onAccept={onSave}
        readOnly={isReadOnly}
        initialShapes={initialShapes}
      />
    </ModalToolRoiFrameParamWrapper>
  );
};
