import {
  EuiButtonEmpty,
  EuiButtonIconProps,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import {
  ModalDataset,
  ModalDatasetProps,
} from "components/ModalDataset/ModalDataset";
import { ModalDeleteDataset } from "components/ModalDeleteDataset/ModalDeleteDataset";
import { useState } from "react";

export interface ButtonEditDatasetProps
  extends Omit<EuiButtonIconProps, "onClick"> {
  dataset: NonNullable<ModalDatasetProps["edit"]>["dataset"];
}

export const ButtonEditDataset = ({
  dataset,
  ...props
}: ButtonEditDatasetProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [modal, setModal] = useState<"edit" | "delete">();

  return (
    <>
      {modal === "edit" && (
        <ModalDataset edit={{ dataset }} onClose={() => setModal(undefined)} />
      )}

      {modal === "delete" && (
        <ModalDeleteDataset
          datasetId={dataset.id}
          onClose={() => setModal(undefined)}
        />
      )}

      <EuiPopover
        button={
          <ButtonIconPermissioned
            aria-label="edit dataset"
            type="gear"
            {...props}
            onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
            requiredPermission="edit"
          />
        }
        anchorPosition="leftCenter"
        panelPaddingSize="none"
        isOpen={isPopoverOpen}
        closePopover={() => setIsPopoverOpen(false)}
        display="block"
      >
        <EuiFlexGrid gutterSize="none">
          <EuiFlexItem>
            <div>
              <EuiButtonEmpty
                color="text"
                iconType="pencil"
                onClick={() => {
                  setIsPopoverOpen(false);
                  setModal("edit");
                }}
              >
                Edit
              </EuiButtonEmpty>
            </div>
          </EuiFlexItem>

          <EuiFlexItem>
            <div>
              <EuiButtonEmpty
                color="text"
                iconType="trash"
                onClick={() => {
                  setIsPopoverOpen(false);
                  setModal("delete");
                }}
              >
                Delete
              </EuiButtonEmpty>
            </div>
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiPopover>
    </>
  );
};
