import { useCallback, useEffect, useRef, useState } from "react";

import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  EuiContextMenuItemIcon,
} from "@inscopix/ideas-eui";
import {
  AnalysisTableGroup,
  useUpdateAnalysisTableGroupByIdMutation,
} from "graphql/_Types";
import ModalDeleteAnalysisTableGroup from "../ModalDeleteAnalysisTableGroup/ModalDeleteAnalysisTableGroup";
import { ModalEditAnalysisTableGroup } from "components/AnalysisTable/ModalEditAnalysisTableGroup";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";

type ModalOptions = "delete" | "edit";

type AnalysisTableActionsPropsType = {
  analysisTableGroupId: AnalysisTableGroup["id"];
  analysisTableGroupName: AnalysisTableGroup["name"];
  isHidden: AnalysisTableGroup["hidden"];
  hasTasks: boolean;
};

export const AnalysisTableActions = ({
  analysisTableGroupId,
  analysisTableGroupName,
  isHidden,
  hasTasks,
}: AnalysisTableActionsPropsType) => {
  const [activeModal, setActiveModal] = useState<ModalOptions>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [
    isTogglingAnalysisTableVisibility,
    setIsTogglingAnalysisTableVisibility,
  ] = useState(false);

  const [updateGroup] = useUpdateAnalysisTableGroupByIdMutation();

  const handlePopoverToggle = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  // This is used to prevent setting state on an unmounted component
  // Scenario: User clicks on the "Hide" button while the "Show hidden tables" is toggled off
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAnalysisTableVisibility = useCallback(async () => {
    setIsTogglingAnalysisTableVisibility(true);
    try {
      await updateGroup({
        variables: {
          id: analysisTableGroupId,
          patch: {
            hidden: !isHidden,
          },
        },
      });

      addUtilityToastSuccess(
        `Analysis table is ${isHidden ? "unhidden" : "hidden"} successfully`,
      );
    } catch (error) {
      addUtilityToastFailure("Failed to toggle Analysis table visibility");
    } finally {
      isMounted.current && setIsTogglingAnalysisTableVisibility(false);
    }
  }, [analysisTableGroupId, isHidden, updateGroup]);

  // Building up the actions for the EUI popover
  const actions: {
    key: string;
    name: string;
    icon: EuiContextMenuItemIcon;
    disabled?: boolean;
    toolTipContent?: string;
    handleClick: () => void;
  }[] = [
    {
      key: "edit",
      name: "Edit",
      icon: "pencil",
      handleClick: () => {
        setActiveModal("edit");
      },
    },
    {
      key: "toggleHiddenTable",
      ...(isHidden ? { name: "Unhide" } : { name: "Hide" }),
      ...(isHidden ? { icon: "eye" } : { icon: "eyeClosed" }),
      handleClick: () => {
        void handleAnalysisTableVisibility();
      },
    },
    {
      key: "delete",
      name: "Delete",
      icon: "trash",
      handleClick: () => setActiveModal("delete"),
    },
  ];

  return (
    <>
      <EuiPopover
        data-test-subj="analysis-table-actions-popover"
        button={
          <ButtonIconPermissioned
            isLoading={isTogglingAnalysisTableVisibility}
            key="analysis-table-actions"
            aria-label="Analysis Table Actions"
            iconType={"gear"}
            size={"s"}
            onClick={handlePopoverToggle}
            requiredPermission="edit"
          />
        }
        isOpen={isPopoverOpen}
        closePopover={() => setIsPopoverOpen(false)}
        panelPaddingSize="none"
        anchorPosition="leftCenter"
      >
        <EuiContextMenuPanel
          size="s"
          items={actions.map(
            ({
              key,
              name,
              icon,
              disabled = false,
              toolTipContent,
              handleClick,
            }) => (
              <Tooltip
                key={`tooltip-${key}`}
                content={toolTipContent}
                place={"left"}
              >
                <EuiContextMenuItem
                  disabled={disabled}
                  key={key}
                  icon={icon}
                  onClick={() => {
                    setIsPopoverOpen(false);
                    handleClick();
                  }}
                >
                  {name}
                </EuiContextMenuItem>
              </Tooltip>
            ),
          )}
        />
      </EuiPopover>

      {activeModal === "edit" && (
        <ModalEditAnalysisTableGroup
          onClose={() => setActiveModal(undefined)}
          onError={() => addUtilityToastFailure("Failed to edit table")}
          onSuccess={() => addUtilityToastSuccess("Successfully edited table")}
          analysisTableGroup={{
            id: analysisTableGroupId,
            name: analysisTableGroupName,
          }}
        />
      )}

      {activeModal === "delete" && (
        <ModalDeleteAnalysisTableGroup
          analysisTableGroupId={analysisTableGroupId}
          onClose={() => setActiveModal(undefined)}
        />
      )}
    </>
  );
};
