/** @jsxImportSource @emotion/react */
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { ProjectHeader } from "./ProjectHeader";
import { useCallback, useState } from "react";
import { ProjectBottomBar } from "./ProjectBottomBar";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";
import { ProjectContent } from "./ProjectContent";
import { css } from "@emotion/react";

export const Project = () => {
  const [isFileBrowserActive, setIsFileBrowserActive] = useState(false);
  const { modal } = useProjectLayoutContext();

  const handleCloseFileBrowser = useCallback(() => {
    setIsFileBrowserActive(false);
  }, []);

  return (
    <>
      {/* PAGE CONTAINER */}
      <EuiFlexGroup
        direction="column"
        gutterSize="none"
        css={css`
          height: 100%;
          min-width: 650px;
        `}
      >
        {/* HEADER */}
        <EuiFlexItem grow={false}>
          <ProjectHeader />
        </EuiFlexItem>

        <EuiFlexItem
          css={css`
            min-height: 0;
          `}
        >
          <ProjectContent
            isFileBrowserActive={isFileBrowserActive}
            onCloseFileBrowser={handleCloseFileBrowser}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <ProjectBottomBar
            isFileBrowserActive={isFileBrowserActive}
            onClickFiles={() => setIsFileBrowserActive((isActive) => !isActive)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {modal}
    </>
  );
};
