/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTab,
  EuiTabs,
} from "@inscopix/ideas-eui";
import { ButtonCreateTable } from "./ButtonCreateTable";
import { useDataTableContext } from "../store/DataTableProvider";

/**
 * Component that renders a set of tabs for navigating between data tables and
 * analysis tables
 */
export const DataTableTabs = () => {
  const tables = useDataTableContext((s) => s.tables);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const setSelectedTableId = useDataTableContext((s) => s.setSelectedTableId);

  return (
    <EuiFlexGroup
      alignItems="center"
      gutterSize="s"
      justifyContent="flexStart"
      css={css`
        padding: 0px 5px;
        min-height: 40px;
      `}
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <ButtonCreateTable />
      </EuiFlexItem>

      <EuiFlexItem
        grow={false}
        css={css`
          overflow: scroll;
        `}
      >
        <EuiTabs bottomBorder={false}>
          {tables.map((table) => (
            <EuiTab
              key={table.id}
              onClick={() => setSelectedTableId(table.id)}
              isSelected={table.id === selectedTableId}
              prepend={
                <EuiIcon
                  type={
                    table.kind === "analysis"
                      ? "indexMapping"
                      : "tableDensityNormal"
                  }
                />
              }
              css={css`
                box-shadow: ${table.id === selectedTableId
                  ? "inset 0 2px 0 #07C"
                  : undefined};
              `}
            >
              {table.name}{" "}
              <span
                css={css`
                  color: grey;
                `}
              >
                ({table.key})
              </span>
            </EuiTab>
          ))}
        </EuiTabs>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
