import { CSSProperties, memo } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { DatasetHistoryMenu } from "../DatasetHistoryMenu/DatasetHistoryMenu";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ButtonDeleteAnalysisTableRow } from "./ButtonDeleteAnalysisTableRow";
import { ButtonStartTask } from "./ButtonStartTask";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";
import { isDefined } from "utils/isDefined";
import { TaskStatusIcon } from "components/TaskStatusIcon/TaskStatusIcon";

interface GridRendererRowControlsProps {
  data: ToolParamsGridRowDatum;
}

const style: CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
};

export const GridRendererRowControls = memo(function GridRendererRowControls({
  data,
}: GridRendererRowControlsProps) {
  const copyRow = useToolParamsGridRowDataContext((s) => s.copyRow);
  const taskStatus = data.task_status;
  const taskId = data.task_id;
  const taskCreated = data.task_date_created;

  return (
    <div style={style}>
      {isDefined(taskStatus) && isDefined(taskId) && isDefined(taskCreated) ? (
        <TaskStatusIcon
          taskStatus={taskStatus}
          taskId={taskId}
          taskCreated={taskCreated}
        />
      ) : (
        <ButtonStartTask rowData={data} />
      )}

      <ButtonDeleteAnalysisTableRow
        row={data}
        task={{ status: data.task_status }}
      />
      <ButtonIconPermissioned
        aria-label="Copy row"
        color="primary"
        iconType="copy"
        onClick={() => copyRow(data.id)}
        requiredPermission="edit"
        defaultTooltip="Copy row"
      />
      <DatasetHistoryMenu
        datasetIds={data.datasets}
        taskDateCreated={data.task_date_created}
      />
    </div>
  );
});
