import { QueryHookOptions } from "@apollo/client";
import { captureException } from "@sentry/react";
import {
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables,
  ProjectsOrderBy,
  Tenant,
  useGetAllProjectsQuery,
} from "graphql/_Types";
import { isUndefined } from "lodash";
import { useMemo } from "react";
import { ProjectStatus } from "types/constants";
import { isDefined } from "utils/isDefined";
import { useGetUserRole } from "utils/permissions";
import { useGetExternalProjects } from "./useGetExternalProjects";
export const projectCardsQueryOptions: QueryHookOptions<
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables
> = {
  variables: {
    filter: {
      and: [
        {
          or: [
            {
              status: {
                in: [ProjectStatus["AVAILABLE"], ProjectStatus["CLONING"]],
              },
            },
          ],
        },
      ],
    },
    orderBy: [ProjectsOrderBy.DateCreatedDesc],
  },
};

type Options = { tenantId?: Tenant["id"]; showRestrictedProjects?: boolean };

export const useGetProjects = (options?: Options) => {
  const tenantId = options?.tenantId;
  const showRestrictedProjects = options?.showRestrictedProjects ?? false;

  const { data: internalData, loading: internalLoading } =
    useGetAllProjectsQuery({
      ...projectCardsQueryOptions,
      onError: (err) => captureException(err),
    });

  const { getUserRole } = useGetUserRole();

  const { projects: externalProjectsData, loading: externalLoading } =
    useGetExternalProjects();

  // need to sort despite queries already being sorted because we are merging
  const projects = useMemo(() => {
    const internalProjects = internalData?.projects?.nodes
      .map((project) => ({
        ...project,
        /* FIXME: Necessary property to enable filtering through the search bar
       downstream. */
        user: project.user?.username,
        tenantKey: project.tenantByTenantId?.key,
        tenantName: project.tenantByTenantId?.name,
      }))
      .filter((project) =>
        isDefined(tenantId) ? project.tenantId === tenantId : true,
      );

    const externalProjects =
      // don't show any external projects if a tenantId is defined
      // tenantId is defined when viewing an org page, which can only be internal to the region
      isUndefined(tenantId)
        ? externalProjectsData
            .filter((project) => isDefined(project))
            .map((project) => ({
              ...project,
              /* FIXME: Necessary property to enable filtering through the search bar
       downstream. */
              user: project.user?.username,
              tenantKey: project.tenantByTenantId?.key,
              tenantName: project.tenantByTenantId?.name,
            }))
        : [];

    if (internalProjects === undefined || externalProjects === undefined) {
      return undefined;
    }

    return (
      [...internalProjects, ...externalProjects]
        // tenant key is required for routing to the project
        .filter(
          (project): project is typeof project & { tenantKey: string } => {
            if (project.tenantKey === undefined) {
              captureException("Project missing access to tenant key", {
                extra: {
                  project,
                },
              });
            }
            return project.tenantKey !== undefined;
          },
        )
        .sort((a, b) => {
          if (a.dateCreated < b.dateCreated) {
            return 1;
          }
          if (a.dateCreated > b.dateCreated) {
            return -1;
          }
          return 0;
        })
        .map((project) => {
          const { role, userPermissions } = getUserRole(project);
          return { ...project, role, userPermissions };
        })
        .filter(({ role }) => {
          if (showRestrictedProjects) {
            return true;
          }
          return role !== "restricted";
        })
    );
  }, [
    externalProjectsData,
    getUserRole,
    internalData?.projects?.nodes,
    showRestrictedProjects,
    tenantId,
  ]);

  if (internalLoading || externalLoading) {
    return { loading: true, projects: undefined, error: undefined };
  }

  if (!internalLoading && !externalLoading) {
    if (projects === undefined) {
      return {
        error: new Error("Failed to retrieve projects"),
        projects: undefined,
        loading: false,
      };
    }
  }

  return { projects, loading: false, error: undefined };
};
