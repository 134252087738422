import { EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { File as DrsFile } from "graphql/_Types";
import {
  DrsFilePreview,
  useDrsFileDownloadPreviewDjango,
} from "hooks/useDrsFileDownloadPreviewDjango";
import { Fragment, useEffect, useState } from "react";
import { FILE_FORMATS_BY_ID, FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { isNonNull } from "utils/isNonNull";
import {
  PresignedFilePreview,
  FilePreviewComponentMap,
} from "./FilePreview.types";
import { FilePreviewEmpty } from "./FilePreviewEmpty";
import { FilePreviewError } from "./FilePreviewError";
import { FilePreviewLoading } from "./FilePreviewLoading";

interface FilePreviewProps {
  file: Pick<DrsFile, "id" | "fileType" | "preview">;
}

/**
 * Component that renders the file's preview(s)
 */
export const FilePreview = ({ file }: FilePreviewProps) => {
  const [previews, setPreviews] = useState<PresignedFilePreview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { downloadFilePreview } = useDrsFileDownloadPreviewDjango();

  /* Fetch the previews when the component mounts. The only time previews should
     be re-fetched is if the file ID changes. */
  useEffect(() => {
    const resetState = () => {
      setPreviews([]);
      setIsLoading(true);
      setError(undefined);
    };

    const fetchPreviews = async () => {
      try {
        resetState();
        const previews = file.preview as DrsFilePreview;
        const hasHtmlPreview = previews?.some((preview) => {
          const { file_format } = preview;
          return file_format === FILE_FORMATS_BY_KEY["html"].id;
        });
        const { data } = await downloadFilePreview(
          file.id,
          hasHtmlPreview ? { "content-disposition": "inline" } : undefined,
        );
        setPreviews(data.previews ?? []);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchPreviews();
  }, [downloadFilePreview, file]);

  if (isLoading) {
    return <FilePreviewLoading />;
  }

  if (error !== undefined) {
    return <FilePreviewError />;
  }

  if (file.preview === null) {
    return <FilePreviewEmpty />;
  }

  /* Map each preview object to a rendered component. If we encounter preview
     object without a corresponding component, report it to Sentry and do not
     render anything. */
  return (
    <div>
      {previews
        .map((preview, idx) => {
          const fileFormat = FILE_FORMATS_BY_ID[preview.file_format];
          const PreviewComponent = FilePreviewComponentMap[fileFormat.key];

          // Report any file previews we didn't expect to receive
          if (PreviewComponent === undefined) {
            captureException("Unsupported file preview type", {
              extra: {
                fileFormat: fileFormat.key,
              },
            });
          }

          return PreviewComponent !== undefined ? (
            <Fragment key={`${file.id}-${preview.name}`}>
              <PreviewComponent file={file} preview={preview} />
              {preview.help !== "" && (
                <EuiText color="subdued" size="s" textAlign="center">
                  <figcaption>{preview.help}</figcaption>
                </EuiText>
              )}
              {idx !== previews.length - 1 && <EuiSpacer />}
            </Fragment>
          ) : null;
        })
        .filter(isNonNull)}
    </div>
  );
};
