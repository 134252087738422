import { DataTableStoreState } from "./DataTableProvider.types";
import { immer } from "zustand/middleware/immer";
import PQueue from "p-queue";
import assert from "assert";

/** Represents the `set` function used to modify a Zustand store */
type SetFn = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[0];

/** Represents the `get` function used to read a Zustand store */
type GetFn = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[1];

/** Represents the options passed to `enqueueAction` */
type EnqueueActionOptions<T> = {
  onEnqueue: () => void;
  onDequeue: () => Promise<T>;
};

/** Represents the return value of `enqueueAction` */
export type EnqueueActionResult<T> =
  | { data: T; error: undefined }
  | { data: undefined; error: Error };

/**
 * Creates a new queue for executing project actions in order.
 * @param set `set` function provided by the Zustand store.
 * @param get `get` function provided by the Zustand store.
 * @returns `enqueueAction`
 */
export const createProjectActionQueue = (set: SetFn, get: GetFn) => {
  // Queue of all unexecuted actions
  const queue = new PQueue({
    autoStart: true,
    concurrency: 1,
  });

  // Controller that allows us to remotely abort the current action
  const controller = new AbortController();

  // Notify the store when actions in the queue are executing
  queue.on("active", () => {
    set((state) => {
      state.syncStatus = {
        status: "syncing",
      };
    });
  });

  // Notify the store when all actions in the queue have been executed
  queue.on("completed", () => {
    set((state) => {
      state.syncStatus = {
        status: "idle",
      };
    });
  });

  // Notify the store when an action fails
  queue.on("error", (error) => {
    set((state) => {
      state.syncStatus = {
        status: "error",
        error: error as Error,
      };
    });
  });

  /**
   * Enqueues a new action for execution
   * @param options
   * @returns A promise the resolves with the result of executing the action or
   * the error that occurred during execution.
   */
  const enqueueAction = async <T>({
    onEnqueue,
    onDequeue,
  }: EnqueueActionOptions<T>): Promise<EnqueueActionResult<T>> => {
    // Run the action enqueue logic. If an error occurs, abort the current action.
    try {
      assert(
        get().syncStatus.status !== "error",
        "Upstream action failed while executing",
      );
      onEnqueue();
    } catch (error) {
      set((state) => {
        state.syncStatus = {
          status: "error",
          error: error as Error,
        };
      });
      controller.abort();
      return {
        data: undefined,
        error: error as Error,
      };
    }

    // Run the action dequeue logic or throw an error if a previous action failed
    const action = () => {
      if (get().syncStatus.status === "error") {
        throw new Error("Upstream action failed while executing");
      } else {
        return onDequeue();
      }
    };

    // Add the action to the queue
    try {
      return {
        data: (await queue.add(action, {
          signal: controller.signal,
        })) as T,
        error: undefined,
      };
    } catch (error) {
      if (error instanceof DOMException) {
        return {
          data: undefined,
          error: new Error("Downstream action failed while queuing"),
        };
      } else {
        return {
          data: undefined,
          error: error as Error,
        };
      }
    }
  };

  return { enqueueAction };
};
