import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiHeaderSectionItemButton,
  EuiIcon,
  useEuiTheme,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { NotificationsFeedBody } from "components/NotificationsFeed/NotificationsFeedBody";
import { useNotificationsFeedUnreadCountQuery } from "graphql/_Types";
import { useGetExternalNotificationsFeedUnreadCount } from "hooks/useGetExternalNotificationsFeedUnreadCount";
import { useUpdateNotificationDjango } from "hooks/useUpdateNotificationDjango";
import { cloneDeep } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { updateCacheFragment } from "utils/cache-fragments";
import { isDefined } from "utils/isDefined";

export const Notifications = () => {
  const theme = useEuiTheme();
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const { updateNotification } = useUpdateNotificationDjango();
  const { data, refetch } = useNotificationsFeedUnreadCountQuery({
    fetchPolicy: "network-only",
    pollInterval: 60 * 1000, // 1 minute
  });
  const {
    externalNotificationsCount,
    refetchExternalNotificationsFeedUnreadCount,
  } = useGetExternalNotificationsFeedUnreadCount();

  /** Event handler called when an unread notification is read */
  const handleNotificationRead = useCallback(
    async (
      notificationId: string,
      region?: TRegion,
      refetchExternalNotifications?: () => void,
    ) => {
      // Update cache
      updateCacheFragment({
        __typename: "Notification",
        id: notificationId,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.hasSeen = true;
          return newData;
        },
      });

      // Mark notification as read
      try {
        await updateNotification({
          id: notificationId,
          has_seen: true,
          region,
        });
      } catch (error) {
        captureException("Failed to mark notification as read", {
          extra: { notificationId },
        });
      }

      // Update local unread notification count
      void refetch();
      // Update external unread notification count and refetch external notifications
      if (isDefined(region)) {
        void refetchExternalNotificationsFeedUnreadCount();
        // external notifications must be explicitly refetched because we can't update cross-region
        // data in the cache yet, refetching is the only way to display the updated read status
        refetchExternalNotifications && void refetchExternalNotifications();
      }
    },
    [refetch, updateNotification, refetchExternalNotificationsFeedUnreadCount],
  );

  /**
   * Flyout visibility state:
   * - Flyout is visible by default on larger screens on homepage
   * - Flyout is hidden by default on smaller screens
   * - Flyout visibility is toggled by user action on all screens
   * - Flyout visibility is toggled by screen size if user has not closed it on larger screens
   */

  const match = useRouteMatch("/");
  const isHomePage = match?.isExact ?? false;

  const totalCount =
    (data?.notifications?.totalCount ?? 0) + externalNotificationsCount;

  const hasNotifications = isDefined(data) && totalCount !== 0;

  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      if (isHomePage && !userInteracted) {
        if (window.innerWidth < theme.euiTheme.breakpoint.xxl) {
          setIsFlyoutVisible(false);
        } else if (hasNotifications) {
          setIsFlyoutVisible(true);
        }
      }
    };
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, [
    isHomePage,
    match?.isExact,
    theme.euiTheme.breakpoint.xxl,
    userInteracted,
    hasNotifications,
  ]);

  useEffect(() => {
    setIsFlyoutVisible(
      isHomePage && window.innerWidth >= theme.euiTheme.breakpoint.xxl,
    );
  }, [isHomePage, theme.euiTheme.breakpoint.xxl]);

  const handleToggle = () => {
    setIsFlyoutVisible((prev) => !prev);
    setUserInteracted(true);
  };

  return (
    <>
      <EuiHeaderSectionItemButton
        onClick={handleToggle}
        notification={totalCount}
        aria-label="Notifications"
        aria-expanded={isFlyoutVisible}
        data-test-subj="notifications-button"
      >
        <EuiIcon type="bell" />
      </EuiHeaderSectionItemButton>
      {isFlyoutVisible && (
        <EuiFlyout
          type={isHomePage ? "push" : "overlay"}
          size="340px"
          onClose={() => ""}
          aria-labelledby={"homePagePushedFlyout"}
          hideCloseButton={true}
          pushMinBreakpoint="xxl"
        >
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="s">
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem>
                  <EuiTitle size="xs">
                    <h2>Notifications</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiSwitch
                    label="Only show unread"
                    checked={onlyShowUnread}
                    onChange={(e) => setOnlyShowUnread(e.target.checked)}
                    compressed
                    labelProps={{ style: { fontWeight: "normal" } }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiTitle>
          </EuiFlyoutHeader>

          <EuiFlyoutBody>
            <NotificationsFeedBody
              onlyShowUnread={onlyShowUnread}
              onNotificationRead={(
                notificationId,
                region,
                refetchExternalNotifications,
              ) =>
                void handleNotificationRead(
                  notificationId,
                  region,
                  refetchExternalNotifications,
                )
              }
            />
          </EuiFlyoutBody>
        </EuiFlyout>
      )}
    </>
  );
};
