import { useLocalStorage } from "hooks/useLocalStorage";
import { createRef, RefObject, useCallback, useEffect, useState } from "react";

enum PanelId {
  "MAIN" = "MAIN",
  "MAIN_AND_FLYOUT_BOTTOM" = "MAIN_AND_FLYOUT_BOTTOM",
  "FLYOUT_BOTTOM" = "FLYOUT_BOTTOM",
  "FLYOUT_RIGHT" = "FLYOUT_RIGHT",
}

type PanelState = {
  [panelId in PanelId]: {
    id: panelId;
    ref: RefObject<HTMLDivElement>;
    size: number;
    cachedSize: number;
  };
};

interface UseResizablePanelsProps {
  isBottomFlyoutOpen: boolean;
  isRightFlyoutOpen: boolean;
}

export const useResizablePanels = ({
  isBottomFlyoutOpen,
  isRightFlyoutOpen,
}: UseResizablePanelsProps) => {
  const [flyoutBottomCachedSize, setFlyoutBottomCachedSize] = useLocalStorage(
    "isx.flyoutBottom.size",
    300,
  );

  const [flyoutRightCachedSize, setFlyoutRightCachedSize] = useLocalStorage(
    "isx.flyoutRight.size",
    400,
  );

  const [panels, setPanels] = useState<PanelState>({
    [PanelId.MAIN]: {
      id: PanelId.MAIN,
      ref: createRef(),
      size: 100,
      cachedSize: 0,
    },
    [PanelId.MAIN_AND_FLYOUT_BOTTOM]: {
      id: PanelId.MAIN_AND_FLYOUT_BOTTOM,
      ref: createRef(),
      size: 100,
      cachedSize: 0,
    },
    [PanelId.FLYOUT_BOTTOM]: {
      id: PanelId.FLYOUT_BOTTOM,
      ref: createRef(),
      size: 0,
      cachedSize: flyoutBottomCachedSize,
    },
    [PanelId.FLYOUT_RIGHT]: {
      id: PanelId.FLYOUT_RIGHT,
      ref: createRef(),
      size: 0,
      cachedSize: flyoutRightCachedSize,
    },
  });

  /**
   * Updates panel state after panel sizes change.
   * @param newPanelSizes
   */
  const handlePanelWidthChange = useCallback(
    (newPanelSizes: Partial<Record<PanelId, number>>) => {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };

        // Update current panel sizes
        Object.entries(newPanelSizes).forEach(([panelId, size]) => {
          if (
            panelId === PanelId.FLYOUT_BOTTOM ||
            panelId === PanelId.FLYOUT_RIGHT
          ) {
            newPanels[panelId as PanelId].size = Math.min(size, 90);
          } else {
            newPanels[panelId as PanelId].size = Math.max(size, 10);
          }
        });

        // Cache bottom flyout size in pixels
        const flyoutBottomRef = newPanels.FLYOUT_BOTTOM.ref.current;
        if ("FLYOUT_BOTTOM" in newPanelSizes && flyoutBottomRef !== null) {
          newPanels.FLYOUT_BOTTOM.cachedSize = flyoutBottomRef.clientHeight;
          setFlyoutBottomCachedSize(newPanels.FLYOUT_BOTTOM.cachedSize);
        }

        // Cache right flyout size in pixels
        const flyoutRightRef = newPanels.FLYOUT_RIGHT.ref.current;
        if ("FLYOUT_RIGHT" in newPanelSizes && flyoutRightRef !== null) {
          newPanels.FLYOUT_RIGHT.cachedSize = flyoutRightRef.clientWidth;
          setFlyoutRightCachedSize(newPanels.FLYOUT_RIGHT.cachedSize);
        }

        return newPanels;
      });
    },
    [setFlyoutBottomCachedSize, setFlyoutRightCachedSize],
  );

  // Sets the size of each panel affected by the bottom flyout opening or closing
  useEffect(() => {
    if (isBottomFlyoutOpen) {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };

        // TODO: Explain
        const mainRef = newPanels.MAIN.ref.current;
        if (mainRef !== null) {
          newPanels.FLYOUT_BOTTOM.size = Math.min(
            90,
            (prevPanels.FLYOUT_BOTTOM.cachedSize / mainRef.clientHeight) * 100,
          );
          newPanels.MAIN.size = 100 - newPanels.FLYOUT_BOTTOM.size;
        }

        return newPanels;
      });
    } else {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };
        newPanels.MAIN.size = 100;
        newPanels.FLYOUT_BOTTOM.size = 0;
        return newPanels;
      });
    }
  }, [isBottomFlyoutOpen]);

  // Sets the size of each panel affected by the right flyout opening or closing
  useEffect(() => {
    if (isRightFlyoutOpen) {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };

        // TODO: Explain
        const mainRef = newPanels.MAIN_AND_FLYOUT_BOTTOM.ref.current;

        if (mainRef !== null) {
          newPanels.FLYOUT_RIGHT.size = Math.min(
            (newPanels.FLYOUT_RIGHT.cachedSize / mainRef.clientWidth) * 100,
            90,
          );
          newPanels.MAIN_AND_FLYOUT_BOTTOM.size =
            100 - newPanels.FLYOUT_RIGHT.size;
        }

        return newPanels;
      });
    } else {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };
        newPanels.MAIN_AND_FLYOUT_BOTTOM.size = 100;
        newPanels.FLYOUT_RIGHT.size = 0;
        return newPanels;
      });
    }
  }, [isRightFlyoutOpen]);

  useEffect(() => {
    const handleResize = () => {
      setPanels((prevPanels) => {
        const newPanels = { ...prevPanels };

        const mainRef = newPanels.MAIN.ref.current;
        const flyoutBottomRef = newPanels.FLYOUT_BOTTOM.ref.current;

        if (
          mainRef !== null &&
          flyoutBottomRef !== null &&
          newPanels.FLYOUT_BOTTOM.size > 0
        ) {
          const availableHeight =
            mainRef.clientHeight + flyoutBottomRef.clientHeight;

          newPanels.FLYOUT_BOTTOM.size = Math.min(
            90,
            (newPanels.FLYOUT_BOTTOM.cachedSize / availableHeight) * 100,
          );
          newPanels.MAIN.size = 100 - newPanels.FLYOUT_BOTTOM.size;
        }

        const otherMainRef = newPanels.MAIN_AND_FLYOUT_BOTTOM.ref.current;
        const flyoutRightRef = newPanels.FLYOUT_RIGHT.ref.current;

        if (
          otherMainRef !== null &&
          flyoutRightRef !== null &&
          newPanels.FLYOUT_RIGHT.size > 0
        ) {
          const availableWidth =
            otherMainRef.clientWidth + flyoutRightRef.clientWidth;

          newPanels.FLYOUT_RIGHT.size = Math.min(
            90,
            (newPanels.FLYOUT_RIGHT.cachedSize / availableWidth) * 100,
          );
          newPanels.MAIN_AND_FLYOUT_BOTTOM.size =
            100 - newPanels.FLYOUT_RIGHT.size;
        }

        return newPanels;
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { panels, handlePanelWidthChange };
};
