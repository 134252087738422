import { EuiBadge } from "@inscopix/ideas-eui";
import { RowIdentifier } from "@inscopix/ideas-hyperformula";

interface CellValueRendererRowIdentifierProps {
  rowIdentifier: RowIdentifier;
}

/** Component that renders a `RowIdentifier` cell value` */
export const CellValueRendererRowIdentifier = ({
  rowIdentifier,
}: CellValueRendererRowIdentifierProps) => {
  return (
    <EuiBadge
      color="#ddd"
      style={{ fontFamily: "monospace", border: "1px solid grey" }}
    >
      {rowIdentifier.tableKey}-{rowIdentifier.rowIndex}
    </EuiBadge>
  );
};
