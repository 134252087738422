import { captureException } from "@sentry/react";
import assert from "assert";
import { AnalysisTable, Project } from "graphql/_Types";
import {
  TaskInput,
  useCreateTaskBulkDjango,
} from "hooks/useCreateTaskBulkDjango";
import { useGetToolVersionsByToolIdAndVersions } from "hooks/useGetToolVersionByToolKeyAndVersion";
import { useCallback, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { isDefined } from "utils/isDefined";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { ToolParamsGridRowDatum as RowDatum } from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { QUOTA_TYPES_BY_KEY } from "types/QuotaType";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";

type ToolVersion = Awaited<
  ReturnType<
    ReturnType<typeof useGetToolVersionsByToolIdAndVersions>["getToolVersions"]
  >
>[number];

/**
 * A hook for executing jobs from the tool params grid
 */
export const useJobExecutor = ({
  projectId,
  analysisTableId,
  projectKey,
}: {
  projectId: Project["id"];
  analysisTableId: AnalysisTable["id"];
  projectKey: Project["key"];
}) => {
  const { toolSpec, toolId } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const checkTenantQuota = useUserContext((s) => s.checkTenantQuota);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const { getToolVersions } = useGetToolVersionsByToolIdAndVersions();
  const { createTaskBulk } = useCreateTaskBulkDjango();
  const [loading, setLoading] = useState(false);

  const getTaskInput = useCallback(
    (rowDatum: RowDatum, toolVersion: ToolVersion) => {
      const taskInput: TaskInput = {
        projectId: projectId,
        taskParameters: [],
        taskSources: [],
        toolVersionId: toolVersion.id,
        analysisTableRowId: rowDatum.id,
      };

      toolSpec.params.forEach((toolParam) => {
        const paramValue = rowDatum.params[toolParam.key];

        // Do not include empty param values
        if (paramValue === undefined) {
          return;
        }

        const isToolSource =
          isToolPathParam(toolParam) && toolParam.type.is_source;

        if (isToolSource) {
          const { toolSourceIdsByToolSourceKey } = toolVersion;
          taskInput.taskSources.push(
            ...(paramValue as string[]).map((fileId) => ({
              fileId,
              toolSourceId: toolSourceIdsByToolSourceKey[toolParam.key],
            })),
          );
        } else {
          const { toolParameterIdsByToolParameterKey } = toolVersion;
          taskInput.taskParameters.push({
            toolParameterId: toolParameterIdsByToolParameterKey[toolParam.key],
            value: paramValue,
          });
        }
      });

      return { taskInput };
    },
    [projectId, toolSpec.params],
  );

  const startJobs = useCallback(
    async (jobs: RowDatum[]) => {
      setLoading(true);
      const toolSemVers = jobs.map((job) => job.toolVersion.version);
      const toolVersions = await getToolVersions(toolId, toolSemVers);
      const taskInputs = jobs.map((job) => {
        const toolVersion = toolVersions.find(
          ({ id }) => id === job.toolVersion.id,
        );
        assert(isDefined(toolVersion), "Failed to fetch tool version");
        return getTaskInput(job, toolVersion);
      });
      try {
        // if saving succeeds, create the tasks and update our local copy of the row with the task ID
        try {
          const tasks = await createTaskBulk(
            taskInputs.map(({ taskInput }) => taskInput),
          );
          jobs.forEach((job, idx) => {
            const task = tasks[idx];

            updateRowDatum(
              job.id,
              {
                // TODO: ID-2472 once backend sets recordings and datasets, don't need to actually save this
                // save each row before task submission to preserve most recent recordings and datasets
                datasets: job.datasets,
                recordings: job.recordings,

                selected: false,
                task_status: task.status,
                task_id: task.id,
                task_date_created: task.created,
                task_user_id: task.userId,
              },
              { forceUpdateLockedRow: true, skipSave: false },
            );
          });
        } catch (err) {
          captureException(err);
          addUtilityToastFailure("Failed to execute jobs");
          const quotaType = QUOTA_TYPES_BY_KEY["credits"];
          void checkTenantQuota(currentTenant.id, quotaType);
        }
      } catch (err) {
        captureException(err);
        addUtilityToastFailure("Failed to save inputs before task submission");
      }

      setLoading(false);
    },
    [
      getToolVersions,
      toolId,
      getTaskInput,
      updateRowDatum,
      createTaskBulk,
      checkTenantQuota,
      currentTenant.id,
    ],
  );

  return {
    loading,
    startJobs,
  };
};
