import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ICellEditorParams } from "ag-grid-community";
import { filesize } from "utils/filesize";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { ToolParamsGridRowDatum, ToolSpec } from "./ToolParamsGrid.types";
import { ResourceTier, RESOURCE_TIERS_BY_ID } from "types/ResourceTiers";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";

/**
 * Formats tool resource tiers into a readable string
 * @param toolSpec Tool Spec
 * @param resourceTierId Tier choice id; null uses tool spec defaults
 * @returns Friendly readable resources string
 */
export const formatToolResources = (
  toolSpec: ToolSpec,
  resourceTierId: ResourceTier["id"] | null,
) => {
  const resourceTier =
    resourceTierId !== null
      ? RESOURCE_TIERS_BY_ID[resourceTierId]
      : { id: null, ...toolSpec.resources, credits: toolSpec.credits };

  const cpu =
    resourceTier?.cpu !== undefined
      ? `${resourceTier.cpu} vCPU${resourceTier.cpu === 1 ? "" : "s"}, `
      : "";
  const gpu =
    resourceTier?.gpu !== undefined && resourceTier.gpu !== 0 ? `GPU, ` : "";
  const mem =
    resourceTier?.memory !== undefined
      ? `${filesize(resourceTier.memory * 1_000_000) as string} RAM, `
      : "";
  const credits = `${resourceTier.credits} credits/hr`;
  return cpu + gpu + mem + credits;
};

const DEFAULT_KEY = "DEFAULT";

interface GridEditorTierResourceChoiceParamProps extends ICellEditorParams {
  data: ToolParamsGridRowDatum;
  value: boolean;
}

/**
 * A component for editing any `TierResourceChoiceParam` grid cells
 */
export const GridEditorTierResourceChoiceParam = forwardRef(
  function GridEditorTierResourceChoiceParam(
    {
      stopEditing,
      value,
      column,
      node,
      data,
    }: GridEditorTierResourceChoiceParamProps,
    ref,
  ) {
    const { toolSpec } = useToolParamsGridContext();
    const resourceChoice = data.resource_tier ?? null;

    const [choiceId, setChoiceId] = useState(resourceChoice ?? null);

    useImperativeHandle(ref, () => ({
      getValue: () => choiceId,
    }));

    const items = useMemo(
      () => [
        <EuiListGroupItem
          css={"border-bottom: 1px solid #e5e5e5"}
          id={DEFAULT_KEY}
          key={DEFAULT_KEY}
          iconType={choiceId === null ? "check" : "empty"}
          label={`${formatToolResources(toolSpec, null)} (Default)`}
          onClick={() => setChoiceId(null)}
        />,
        ...Object.keys(RESOURCE_TIERS_BY_ID).map((id) => {
          return (
            <EuiListGroupItem
              id={id}
              key={id}
              iconType={choiceId === Number(id) ? "check" : "empty"}
              label={formatToolResources(
                toolSpec,
                Number(id) as ResourceTier["id"],
              )}
              onClick={() => setChoiceId(Number(id) as ResourceTier["id"])}
            />
          );
        }),
      ],
      [choiceId, toolSpec],
    );

    return (
      <EuiPopover
        anchorPosition="downLeft"
        button={
          <div
            style={{
              width: column.getActualWidth(),
              height: node.rowHeight ?? 0,
              display: "flex",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <span
              style={{
                paddingLeft: 17,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatToolResources(toolSpec, choiceId)}
            </span>
          </div>
        }
        className="Popover PopoverAnchor"
        closePopover={stopEditing}
        display="block"
        isOpen
        panelPaddingSize="none"
        repositionOnScroll={false}
      >
        <EuiListGroup className="ContextMenu" maxWidth="100%">
          {items}
        </EuiListGroup>
      </EuiPopover>
    );
  },
);
