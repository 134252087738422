import { EuiResizableContainer, EuiSpacer } from "@inscopix/ideas-eui";
import { Fragment, memo } from "react";
import { FlyoutProjectFiles } from "./FlyoutProjectFiles";
import { ProjectDescription } from "./ProjectDescription";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";
import { ProjectPanels } from "./ProjectPanels";
import { useResizablePanels } from "./useResizablePanels";

const ProjectInfo = memo(function ProjectInfo() {
  return (
    <Fragment>
      <ProjectDescription />
      <EuiSpacer size="s" />
      <ProjectPanels />
    </Fragment>
  );
});

interface ProjectContentProps {
  isFileBrowserActive: boolean;
  onCloseFileBrowser: () => void;
}

/**
 * Component that renders the main content of a project. This includes the
 * project description, dataset panel, analysis table panel and all flyouts.
 */
export const ProjectContent = ({
  isFileBrowserActive,
  onCloseFileBrowser,
}: ProjectContentProps) => {
  const { rightFlyout } = useProjectLayoutContext();
  const isRightFlyoutOpen = rightFlyout !== null;
  const { panels, handlePanelWidthChange } = useResizablePanels({
    isBottomFlyoutOpen: isFileBrowserActive,
    isRightFlyoutOpen: isRightFlyoutOpen,
  });

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={panels.MAIN_AND_FLYOUT_BOTTOM.id}
            panelRef={panels.MAIN_AND_FLYOUT_BOTTOM.ref}
            size={panels.MAIN_AND_FLYOUT_BOTTOM.size}
            paddingSize="none"
          >
            <EuiResizableContainer
              direction="vertical"
              onPanelWidthChange={handlePanelWidthChange}
              style={{ width: "100%", height: "100%" }}
            >
              {(EuiResizablePanel, EuiResizableButton) => (
                <>
                  <EuiResizablePanel
                    id={panels.MAIN.id}
                    panelRef={panels.MAIN.ref}
                    size={panels.MAIN.size}
                    paddingSize="none"
                  >
                    <ProjectInfo />
                  </EuiResizablePanel>

                  <EuiResizableButton
                    indicator="border"
                    style={{
                      display: isFileBrowserActive ? undefined : "none",
                    }}
                  />

                  <EuiResizablePanel
                    id={panels.FLYOUT_BOTTOM.id}
                    panelRef={panels.FLYOUT_BOTTOM.ref}
                    size={panels.FLYOUT_BOTTOM.size}
                    paddingSize="none"
                    minSize="300px"
                  >
                    <FlyoutProjectFiles onClose={onCloseFileBrowser} />
                  </EuiResizablePanel>
                </>
              )}
            </EuiResizableContainer>
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isRightFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={panels.FLYOUT_RIGHT.id}
            panelRef={panels.FLYOUT_RIGHT.ref}
            size={panels.FLYOUT_RIGHT.size}
            paddingSize="none"
            minSize="300px"
          >
            {rightFlyout?.node}
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
