import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldColumn } from "../../fields/FieldColumn";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { getColumnAddress } from "pages/gdt/column-headers/ColumnHeaderBase";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  left_column: {
    value:
      | {
          id: string;
          order: number;
          kind: "data_table_column" | "gdt_analysis_table_column";
          table: { id: string; key: string };
        }
      | undefined;
    isValid: boolean;
  };
  right_column: {
    value:
      | {
          id: string;
          order: number;
          kind: "data_table_column" | "gdt_analysis_table_column";
          table: { id: string; key: string };
        }
      | undefined;
    isValid: boolean;
  };
  source_column: {
    value:
      | {
          id: string;
          order: number;
          kind: "data_table_column" | "gdt_analysis_table_column";
          table: { id: string; key: string };
        }
      | undefined;
    isValid: boolean;
  };
};

interface PanelCreateColumnJoinProps {
  onClose: () => void;
}

export const PanelCreateColumnJoin = ({
  onClose,
}: PanelCreateColumnJoinProps) => {
  const createColumn = useDataTableContext((s) => s.createColumn);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: "",
      isValid: false,
    },
    left_column: {
      value: undefined,
      isValid: false,
    },
    right_column: {
      value: undefined,
      isValid: false,
    },
    source_column: {
      value: undefined,
      isValid: false,
    },
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    const leftColumn = fields.left_column.value;
    const rightColumn = fields.right_column.value;
    const sourceColumn = fields.source_column.value;
    assert(
      isDefined(leftColumn) &&
        isDefined(rightColumn) &&
        isDefined(sourceColumn),
    );

    const leftAddress = `${leftColumn.table.key}!${getColumnAddress(
      leftColumn.order,
    )}:${getColumnAddress(leftColumn.order)}`;

    const rightAddress = `${rightColumn.table.key}!${getColumnAddress(
      rightColumn.order,
    )}:${getColumnAddress(rightColumn.order)}`;

    const sourceAddress = `${sourceColumn.table.key}!${getColumnAddress(
      sourceColumn.order,
    )}:${getColumnAddress(sourceColumn.order)}`;

    setIsLoading(true);
    assert(selectedTableId !== undefined);
    const { error } = await createColumn({
      tableId: selectedTableId,
      name: fields.name.value,
      defaultFormula: `=JOIN(${leftAddress}, ${rightAddress}, ${sourceAddress})`,
      definition: {
        kind: "join",
        left_column: {
          id: leftColumn.id,
          kind: leftColumn.kind,
        },
        right_column: {
          id: rightColumn.id,
          kind: rightColumn.kind,
        },
        source_column: {
          id: sourceColumn.id,
          kind: sourceColumn.kind,
        },
      },
      editable: false,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column created");
    } else {
      addUtilityToastFailure("Failed to create column");
    }

    onClose();
  };

  // Reset source column when right column changes
  useEffect(() => {
    handleFieldChange("source_column", {
      value: undefined,
      isValid: false,
    });
  }, [fields.right_column.value?.table.id]);

  return (
    <EuiForm style={{ padding: 16 }}>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => handleFieldChange("name", field)}
      />

      <FieldColumn
        filter={{ tableId: selectedTableId }}
        label="Left column"
        helpText="Compared against values in the right column."
        value={fields.left_column.value}
        onChange={(field) => handleFieldChange("left_column", field)}
      />

      <FieldColumn
        label="Right column"
        helpText="Compared against values in the left column."
        value={fields.right_column.value}
        onChange={(field) => handleFieldChange("right_column", field)}
      />

      <FieldColumn
        disabled={fields.right_column.value === undefined}
        filter={{ tableId: fields.right_column.value?.table.id }}
        label="Source column"
        helpText="Display rows from this column when values in the left and right columns match."
        value={fields.source_column.value}
        onChange={(field) => handleFieldChange("source_column", field)}
      />

      <EuiSpacer />

      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            isLoading={isLoading}
            fill
            onClick={() => void handleSubmit()}
            disabled={Object.values(fields).some((field) => !field.isValid)}
          >
            Insert
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
};
