import axios from "axios";
import { keyBy, orderBy } from "lodash";
import {
  AnalysisTableRow,
  File as DrsFile,
  Project,
  Task,
  ToolParameter,
  ToolSource,
  ToolVersion,
  useGetAllTasksLazyQuery,
} from "../graphql/_Types";
import { getEnvVar } from "../ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useCallback } from "react";
import { updateCacheFragment } from "utils/cache-fragments";

export interface TaskInput {
  projectId: Project["id"];
  taskParameters: {
    toolParameterId: ToolParameter["id"];
    value: number | string | string[] | boolean | undefined;
  }[];
  taskSources: {
    fileId: DrsFile["id"];
    toolSourceId: ToolSource["id"];
  }[];
  toolVersionId: ToolVersion["id"];
  analysisTableRowId: AnalysisTableRow["id"];
}

/**
 * A hook for creating tasks in bulk using the Django API
 * @returns A function to trigger the creation
 */
export const useCreateTaskBulkDjango = () => {
  const [getAllTasks] = useGetAllTasksLazyQuery();
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  /**
   * Creates the tasks from the inputs
   * @param inputs
   * @returns The ids of the new tasks
   */
  const createTasks = useCallback(
    async (inputs: TaskInput[]) => {
      const url = getEnvVar("URL_TES_CREATE_BULK");

      const body = inputs.map((input) => ({
        user: currentUser.id,
        tenant: currentTenant.id,
        tool_version: input.toolVersionId,
        task_parameters: input.taskParameters.map(
          ({ toolParameterId, value }) => ({
            tool_parameter: toolParameterId,
            value,
          }),
        ),
        task_sources: input.taskSources.map(({ fileId, toolSourceId }) => ({
          file: fileId,
          tool_source: toolSourceId,
        })),
        project: input.projectId,
        analysis_table_row: input.analysisTableRowId,
      }));

      type ResponseData = { id: string }[];
      const headers = await getRequestHeaders();
      const { data } = await axios.post<ResponseData>(url, body, { headers });
      const taskIds = data.map(({ id }) => id);

      return { taskIds };
    },
    [currentTenant.id, currentUser.id],
  );

  /**
   * Gets a list of tasks by their ids
   * @param taskIds
   * @returns The fetched tasks
   */
  const getTasksByIds = useCallback(
    async (taskIds: Task["id"][]) => {
      const { data } = await getAllTasks({
        variables: {
          filter: {
            id: { in: taskIds },
          },
        },
      });

      const tasks = data?.tasks?.nodes;

      if (tasks === undefined || tasks.length !== taskIds.length) {
        throw new Error("Failed to create tasks");
      }

      // Sort tasks by the order of task ids argument
      const tasksById = keyBy(tasks, ({ id }) => id);
      const sortedTasks = taskIds.map((taskId) => tasksById[taskId]);

      return { tasks: sortedTasks };
    },
    [getAllTasks],
  );

  /**
   * Creates tasks in bulk
   * @param inputs
   * @returns The new tasks
   */
  const createTaskBulk = useCallback(
    async (inputs: TaskInput[]) => {
      const { taskIds } = await createTasks(inputs);
      const { tasks } = await getTasksByIds(taskIds);

      const mostRecentTask = orderBy(
        tasks,
        [(task) => task.created],
        ["asc"],
      ).pop();
      if (mostRecentTask !== undefined) {
        const { projectId } = mostRecentTask;
        if (projectId !== null) {
          updateCacheFragment({
            __typename: "Project",
            id: projectId,
            update: (data) => {
              return { ...data, dateLastActive: mostRecentTask.created };
            },
          });
        }
      }
      return tasks;
    },
    [createTasks, getTasksByIds],
  );

  return { createTaskBulk };
};
