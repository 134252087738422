import { useFragment } from "@apollo/client";
import { captureException } from "@sentry/react";
import { DocumentNode } from "graphql";
import {
  AnalysisTableRowCacheFragment,
  AnalysisTableRowCacheFragmentDoc,
  DatasetCacheFragment,
  DatasetCacheFragmentDoc,
  DatasetRecordingsTableCacheFragment,
  DatasetRecordingsTableCacheFragmentDoc,
  DatasetRecordingsTableColumnCacheFragment,
  DatasetRecordingsTableColumnCacheFragmentDoc,
  DatasetRecordingsTableColumnOrderCacheFragment,
  DatasetRecordingsTableColumnOrderCacheFragmentDoc,
  FileCacheFragment,
  FileCacheFragmentDoc,
  ProjectCacheFragment,
  ProjectCacheFragmentDoc,
  FileRecordingGroupCacheFragment,
  FileRecordingGroupCacheFragmentDoc,
  RecordingGroupFileCacheFragment,
  RecordingGroupFileCacheFragmentDoc,
  MetadatumCacheFragment,
  MetadatumCacheFragmentDoc,
  AnalysisTableCacheFragment,
  AnalysisTableCacheFragmentDoc,
  ApplicationUserMembershipCacheFragment,
  ApplicationUserMembershipCacheFragmentDoc,
  NotificationCacheFragment,
  NotificationCacheFragmentDoc,
  AnalysisTableGroupCacheFragment,
  AnalysisTableGroupCacheFragmentDoc,
} from "graphql/_Types";
import { client } from "providers/ApolloProvider/ApolloProvider";

/**
 * Represents any cache fragment
 */
type CacheFragment =
  | AnalysisTableRowCacheFragment
  | ApplicationUserMembershipCacheFragment
  | DatasetCacheFragment
  | DatasetRecordingsTableCacheFragment
  | DatasetRecordingsTableColumnCacheFragment
  | DatasetRecordingsTableColumnOrderCacheFragment
  | FileCacheFragment
  | MetadatumCacheFragment
  | NotificationCacheFragment
  | ProjectCacheFragment
  | FileRecordingGroupCacheFragment
  | RecordingGroupFileCacheFragment
  | AnalysisTableCacheFragment
  | AnalysisTableGroupCacheFragment;

/**
 * Represents a map from cache fragment __typenames to cache fragment types
 */
type FragmentMap = {
  [T in CacheFragment as T["__typename"]]: T;
};

/**
 * Represents the __typename of any cache fragment
 */
type __Typename = CacheFragment["__typename"];

/**
 * A map from cache fragment __typenames to cache fragment document nodes
 */
const fragmentDocMap: Record<__Typename, DocumentNode> = {
  AnalysisTableRow: AnalysisTableRowCacheFragmentDoc,
  ApplicationUserMembership: ApplicationUserMembershipCacheFragmentDoc,
  Dataset: DatasetCacheFragmentDoc,
  DatasetRecordingsTable: DatasetRecordingsTableCacheFragmentDoc,
  DatasetRecordingsTableColumn: DatasetRecordingsTableColumnCacheFragmentDoc,
  DatasetRecordingsTableColumnOrder:
    DatasetRecordingsTableColumnOrderCacheFragmentDoc,
  File: FileCacheFragmentDoc,
  Metadatum: MetadatumCacheFragmentDoc,
  Notification: NotificationCacheFragmentDoc,
  Project: ProjectCacheFragmentDoc,
  FileRecordingGroup: FileRecordingGroupCacheFragmentDoc,
  RecordingGroupFile: RecordingGroupFileCacheFragmentDoc,
  AnalysisTable: AnalysisTableCacheFragmentDoc,
  AnalysisTableGroup: AnalysisTableGroupCacheFragmentDoc,
};

/**
 * Represents the options passed to {@link readCacheFragment}
 */
type ReadCacheFragmentOptions<T extends __Typename> = {
  __typename: T;
  id: FragmentMap[T]["id"];
};

/**
 * Reads a cache record using cache fragments
 * @param options
 * @returns The cache record fields or `null` for cache misses
 */
export const readCacheFragment = <T extends __Typename>(
  options: ReadCacheFragmentOptions<T>,
) => {
  const { __typename, id } = options;

  const data = client.cache.readFragment<Partial<FragmentMap[T]>>({
    id: `${__typename}:${id}`,
    fragment: fragmentDocMap[__typename],
    returnPartialData: true,
  });

  if (data === null) {
    const msg = `Received cache miss when reading '${__typename}:${id}'`;
    captureException(msg);
  }

  return data;
};

/**
 * Represents the options passed to {@link writeCacheFragment}
 */
type WriteCacheFragmentOptions<T extends __Typename> = {
  __typename: T;
  id: FragmentMap[T]["id"];
  data: FragmentMap[T];
};

/**
 * Writes a cache record using cache fragments
 * @param options
 * @returns A reference to the new cache record
 */
export const writeCacheFragment = <T extends __Typename>(
  options: WriteCacheFragmentOptions<T>,
) => {
  const { __typename, id, data } = options;
  return client.cache.writeFragment<FragmentMap[T]>({
    id: `${__typename}:${id}`,
    fragment: fragmentDocMap[__typename],
    data,
  });
};

/**
 * Represents the options passed to {@link updateCacheFragment}
 */
type UpdateCacheFragmentOptions<T extends __Typename> = {
  __typename: T;
  id: FragmentMap[T]["id"];
  update: (data: Partial<FragmentMap[T]>) => Partial<FragmentMap[T]>;
};

/**
 * Updates a cache record using cache fragments
 * @param options
 * @returns The updated fields or `null` for cache misses
 */
export const updateCacheFragment = <T extends __Typename>(
  options: UpdateCacheFragmentOptions<T>,
) => {
  const { __typename, id, update } = options;
  return client.cache.updateFragment<Partial<FragmentMap[T]>>(
    {
      id: `${__typename}:${id}`,
      fragment: fragmentDocMap[__typename],
      returnPartialData: true,
    },
    (data) => {
      if (data === null) {
        const msg = `Received cache miss when updating '${__typename}:${id}'`;
        captureException(msg);
        return null;
      } else {
        return update(data);
      }
    },
  );
};

/**
 * Represents the options passed to {@link evictCacheFragment}
 */
type EvictCacheFragmentOptions<T extends __Typename> = {
  __typename: T;
  id: FragmentMap[T]["id"];
  fieldName?: Exclude<keyof FragmentMap[T] & string, "__typename" | "id">;
};

/**
 * Evicts a cache record or a single field from a cached record when a field name is specified
 * @param options
 * @returns A boolean representing whether the record, or field, was successfully evicted
 */
export const evictCacheFragment = <T extends __Typename>(
  options: EvictCacheFragmentOptions<T>,
) => {
  const { __typename, id, fieldName } = options;
  return client.cache.evict({ id: `${__typename}:${id}`, fieldName });
};

/**
 * Represents the options passed to {@link useCacheFragment}
 */
type UseCacheFragmentOptions<T extends __Typename> = {
  __typename: T;
  id: FragmentMap[T]["id"];
};

/**
 * Reads a cache record using cache fragments and watches for any changes to
 * the cached data.
 * @param options
 * @returns An always-up-to-date view of whatever data the cache currently
 * contains for a given cache fragment.
 * @see https://www.apollographql.com/docs/react/data/fragments/#usefragment
 */
export const useCacheFragment = <T extends __Typename>(
  options: UseCacheFragmentOptions<T>,
) => {
  const { __typename, id } = options;
  return useFragment<FragmentMap[T]>({
    fragment: fragmentDocMap[__typename],
    from: { __typename, id },
  });
};
