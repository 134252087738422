import { forwardRef, useImperativeHandle, useState } from "react";
import { ICellEditorParams } from "ag-grid-community";
import { DataTableRowData } from "../layout/DataTable";

interface CellEditorGenericProps extends ICellEditorParams<DataTableRowData> {
  index: number;
}

/**
 * Cell editor for cells in formula columns
 */
export const CellEditorFormula = forwardRef(function CellEditorFormula(
  { data, index }: CellEditorGenericProps,
  ref,
) {
  const [newFormula, setNewFormula] = useState(data.cells[index].formula);

  useImperativeHandle(ref, () => ({
    getValue: () => newFormula,
  }));

  return (
    <input
      type="text"
      style={{
        padding: "0px 11px",
        color: "green",
        fontFamily: "monospace",
        border: "none",
        backgroundColor: "transparent",
        width: "100%",
      }}
      value={newFormula ?? ""}
      onChange={(e) => setNewFormula(e.target.value)}
      autoFocus
    />
  );
});
