import { EuiButtonEmpty } from "@inscopix/ideas-eui";

interface ColumnHeaderActionRemoveColumnProps {
  onClick: () => void;
  isDisabled?: boolean;
}

export const ColumnHeaderActionRemoveColumn = ({
  onClick,
  isDisabled = false,
}: ColumnHeaderActionRemoveColumnProps) => {
  return (
    <EuiButtonEmpty
      aria-label="Remove column"
      onClick={onClick}
      iconType="trash"
      color="text"
      size="s"
      disabled={isDisabled}
    >
      Remove column
    </EuiButtonEmpty>
  );
};
