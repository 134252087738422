import { UserAvatar, UserAvatarProps } from "components/UserAvatar/UserAvatar";
import { ApplicationUser } from "graphql/_Types";
type UserAvatarRendererProps = {
  userId: ApplicationUser["id"];
} & Omit<UserAvatarProps, "user">;

/**
 * loads user by id and renders avatar
 */

export const UserAvatarRenderer = ({
  userId,
  ...props
}: UserAvatarRendererProps) => {
  return (
    <UserAvatar size="s" aria-label="User Avatar" userId={userId} {...props} />
  );
};
