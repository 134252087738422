import { ToolboxInformationSchema } from "types/ToolboxInformationSchema";
import { TaskStatus } from "../../types/constants";
import {
  File as DrsFile,
  Maybe,
  FileRecordingGroup,
  Task,
  Dataset,
  Interval,
  Metadatum,
  ToolVersion,
} from "graphql/_Types";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { SetRequired } from "type-fest";
import { TAnySelectionItem } from "./DataSelector/DataSelector.types";
import { ResourceTier } from "types/ResourceTiers";

/*************************************/
// DERIVED TYPES FROM TOOLBOX SCHEMA //
/*************************************/

export type ToolSpec = ToolboxInformationSchema["tools"][number] & {
  credits: number;
};

export type ToolParam = ToolSpec["params"][number];

export interface ToolPathParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolPathParam" }>;
}

export interface ToolCroppingFrameParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolCroppingFrameParam" }>;
}

export interface ToolRoiFrameParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolRoiFrameParam" }>;
}

export interface ToolCellStatusParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolCellStatusParam" }>;
}

export interface ToolStringParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolStringParam" }>;
}

export interface ToolIntRangeParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolIntRangeParam" }>;
}

export interface ToolFloatRangeParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolFloatRangeParam" }>;
}

export interface ToolChoiceParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolChoiceParam" }>;
}

export interface ToolBooleanParam extends ToolParam {
  type: Extract<ToolParam["type"], { param_type: "ToolBooleanParam" }>;
}

export type ToolParamValue = ToolParam["default"] | string[];

export type SelectorPath = {
  id: string;
  colType: TAnySelectionItem["colType"];
};

export type SelectorPathByDrsFileId = Record<DrsFile["id"], SelectorPath[]>;

export type AnalysisTableIdentifiers = string[];

export type RecordingMetadatumReference = {
  type: "recording";
  metadatum_key: Metadatum["key"];
};

export type FileMetadatumReference = {
  type: "file";
  source: string;
  metadatum_key: Metadatum["key"];
};

export type MetadatumReference =
  | RecordingMetadatumReference
  | FileMetadatumReference;

/*************************************/
// GRID-RELATED TYPES                //
/*************************************/

export interface ToolParamsGridRowDatum {
  id: string;
  params: Record<string, ToolParamValue>;
  recordings?: FileRecordingGroup["id"][];
  datasets?: Dataset["id"][];
  selected: boolean;
  task_id?: Task["id"];
  task_user_id?: Task["userId"];
  task_status?: TaskStatus;
  task_date_created?: string;
  task_compute_credit?: Task["credits"];
  task_cloned?: Task["cloned"];
  task_duration?: Interval;
  resource_tier: ResourceTier["id"] | null;
  output_group_files?: Pick<DrsFile, "id" | "key">[];
  selections?: Maybe<
    Record<ToolSpec["params"][number]["key"], SelectorPathByDrsFileId>
  >;
  attachResults: boolean;
  metadatumReferences: Record<string, MetadatumReference | undefined>;
  toolVersion: Pick<ToolVersion, "id" | "version">;
}

export type ToolParamsGridCellEditRequestNewValue =
  | ToolParamValue
  // null is passed to cell edit request when user hits delete key on a cell
  | null;

export type ToolParamsGridColDef = Omit<
  SetRequired<ColDef<ToolParamsGridRowDatum>, "colId"> & {
    /**
     * Triggered when a cell in the column is edited
     * @returns The value to send to the grid.
     */
    onCellEdit?: (
      newValue: ToolParamsGridCellEditRequestNewValue,
      rowDatum: ToolParamsGridRowDatum,
    ) => void;

    /**
     * Triggered when a cell in the column is modified by a fill operation
     * @returns The value to send to the grid.
     */
    onCellFill:
      | ((
          newValue: ToolParamsGridCellEditRequestNewValue,
          rowDatum: ToolParamsGridRowDatum,
        ) => ToolParamValue)
      | null;

    /**
     * Triggered when a cell in the column is modified by a paste operation
     * @returns The value to send to the grid.
     */
    onCellPaste?: (
      newValue: ToolParamsGridCellEditRequestNewValue,
      rowDatum: ToolParamsGridRowDatum,
    ) => ToolParamValue;
  } & {
    // https://inscopix.atlassian.net/browse/ID-1546
    valueGetterField?: string;
  },
  "field"
>;

export const RESOURCE_TIER_COL = {
  id: "resource_tier" as const,
  label: "Compute Resources",
};

export interface ResourceTierColDef
  extends Omit<
    ToolParamsGridColDef,
    "onCellFill" | "onCellEdit" | "onCellPaste"
  > {
  onCellFill: (
    newValue: ToolParamValue,
    rowDatum: ToolParamsGridRowDatum,
  ) => ResourceTier["id"] | null;
  onCellEdit: (
    newValue: ToolParamValue,
    rowDatum: ToolParamsGridRowDatum,
  ) => ResourceTier["id"] | null;
  onCellPaste: (
    newValue: ToolParamValue,
    rowDatum: ToolParamsGridRowDatum,
  ) => ResourceTier["id"] | null;
}

export interface ToolParamsGridColGroupDef
  extends ColGroupDef<ToolParamsGridRowDatum> {
  children: (
    | ToolParamsGridColDef
    | ToolParamsGridColGroupDef
    | ResourceTierColDef
  )[];
}

export interface AnalysisTableRowAttachments {
  datasets: Dataset["id"][];
  recordings: FileRecordingGroup["id"][];
}

export const TASK_INFO_COLS = {
  ID: { id: "task_id" as const, label: "ID" },
  LOGS: { id: "task_logs" as const, label: "Log" },
  DATE_STARTED: { id: "task_date_created" as const, label: "Date Started" },
  COMPUTE_CREDITS: {
    id: "task_compute_credit" as const,
    label: "Compute Credits",
  },
  DURATION: { id: "task_duration" as const, label: "Duration" },
  USER: { id: "task_user" as const, label: "User" },
};

export type TaskInfoColId =
  (typeof TASK_INFO_COLS)[keyof typeof TASK_INFO_COLS]["id"];
