import axios from "axios";
import { ProjectFieldsFragment } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";

export type CreateProjectInput = {
  id: string;
  short_description: string;
  description: string;
  name: string;
  key: string;
  icon_color: string;
  icon_text: string | null;
  user: string;
  tenant: number;
};

export type CreateProjectDjangoResponse = {
  data: {
    id: string;
    name: string;
    key: string;
    short_description: string;
    description: string;
    icon: null;
    icon_color: string;
    icon_text: string | null;
    deleted: false;
    date_deleted: null;
    date_data_deleted: null;
    archived: boolean;
    date_archived: null;
    date_created: string;
    date_updated: string;
    user: string;
    tenant: number;
    status: number;
    date_last_active: string;
  };
};

export type CreateProjectResult = ProjectFieldsFragment;

export const useCreateProjectDjango = () => {
  const createProjectDjango = async (
    input: CreateProjectInput,
  ): Promise<CreateProjectResult> => {
    const headers = await getRequestHeaders({ tenantId: input.tenant });
    const url = getEnvVar("URL_LIBRARY_PROJECT");
    const response = await axios.post<
      CreateProjectInput,
      CreateProjectDjangoResponse
    >(url, input, {
      headers,
    });

    const {
      id,
      date_archived,
      date_created,
      date_deleted,
      date_data_deleted,
      short_description,
      description,
      key,
      name,
      tenant,
      user,
      icon,
      icon_color,
      icon_text,
      status,
      date_last_active,
    } = response.data;

    return {
      __typename: "Project",
      id,
      dateArchived: date_archived,
      dateCreated: date_created,
      dateDeleted: date_deleted,
      dateDataDeleted: date_data_deleted,
      key,
      name,
      tenantId: tenant,
      userId: user,
      shortDescription: short_description,
      description,
      icon,
      iconColor: icon_color,
      iconText: icon_text,
      activeStorageSize: "0",
      archivedStorageSize: "0",
      status: status,
      usedCredits: 0,
      defaultUserAccessLevel: USER_ACCESS_LEVELS_BY_KEY["restricted"].id,
      pinned: false,
      dateLastActive: date_last_active,
    };
  };
  return { createProjectDjango };
};
