import { TUserRole } from "components/ModalProjectSharing/ModalProjectSharing";
import { Project } from "graphql/_Types";
import { useFetchAndSetProjectAccess } from "hooks/useFetchAndSetProjectAccess";
import { UserProjectAccessDjango } from "hooks/useProjectPermission";
import { find, isEqual, keyBy, max } from "lodash";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useCallback } from "react";
import {
  UserProjectAccess,
  UserPermission,
  USER_ACCESS_LEVELS,
  USER_PERMISSIONS,
  USER_ACCESS_LEVELS_BY_ID,
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_KEY,
} from "types/UserAccessLevels";

/**
 * Get the user role from the list of permissions
 * @param permissions List of permissions
 * @returns The user role
 * @example
 * const role = getRoleFromPermissions(["view", "edit"]);
 * // role === "editor"
 */
export const getRoleFromPermissions = (
  permissions: UserPermission[],
): TUserRole => {
  // Convert to a set for easier comparison
  const userPermissions = new Set(permissions);

  if (userPermissions.size === 0) {
    return "restricted";
  }

  /* Check if the user's permissions are identical to the permissions of a known
     access level */
  for (const accessLevel of Object.values(USER_ACCESS_LEVELS)) {
    const accessLevelPermissions = new Set(accessLevel.permissions);
    if (isEqual(userPermissions, accessLevelPermissions)) {
      return accessLevel.key;
    }
  }

  return "custom";
};

//
// Extract the list of permissions from the userProjectAccess object
//
/**
   * Extract the list of permissions from the userProjectAccess object
   * @param userProjectAccess The userProjectAccess object
   * @returns The list of permissions
   * @example
   * const permissions = extractPermissionsList({
   *    "id": "179ef2ce-e25f-4ebb-a7e3-f58c259901ea",
        "userId": "0f58e38b-6dd2-4d08-a6b9-d26ae015b8be",
        "managed": false,
        "view": true,
        "download": true,
        "copy": false,
        "edit": false,
        "execute": true,
        "grantAccess": false,
        "upload": false,
   * });
   * // permissions === ["view", "download"]
   */
export const extractPermissionsList = (
  userProjectAccess: Partial<UserProjectAccess>,
) => {
  return USER_PERMISSIONS.filter((permission) => userProjectAccess[permission]);
};

export const useGetUserRole = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentUserTenants = useUserContext((s) => s.tenants);
  const { permissions } = useFetchAndSetProjectAccess();
  const currentUserPermissionsByProjectId: Record<
    Project["id"],
    UserProjectAccessDjango | undefined
  > = keyBy(
    permissions?.filter(({ user }) => user === currentUser.id),
    (permission) => permission.project,
  );

  /**
   * Extract the current user's role from the project
   * @param project The project
   * @returns The user's current role
   *
   */
  const getUserRole = useCallback(
    (
      project: Pick<
        Project,
        "id" | "defaultUserAccessLevel" | "userId" | "tenantId"
      >,
    ) => {
      const currentUserPermission =
        currentUserPermissionsByProjectId[project.id];
      const userPermissions = extractPermissionsList(
        currentUserPermission ?? {},
      );

      // Project Owner always has the highest access level
      if (project.userId === currentUser.id) {
        return {
          role: "owner" as const,
          userPermissions,
        };
      }

      const role = getRoleFromPermissions(userPermissions);

      const individualAccessLevel = USER_ACCESS_LEVELS_BY_KEY[role]?.id;

      // If an user is a member of the tenant and the default access level is higher than the individual access level,
      // the user is always given the most permissive access level
      const tenantRole = find(currentUserTenants, { id: project.tenantId });

      const isMemberOfTenant = tenantRole !== undefined;
      const defaultAccessLevel = isMemberOfTenant
        ? project.defaultUserAccessLevel
        : USER_ACCESS_LEVELS_BY_KEY["restricted"]["id"];

      const accessLevel = max([defaultAccessLevel, individualAccessLevel]);

      if (accessLevel !== undefined) {
        return {
          role: USER_ACCESS_LEVELS_BY_ID[accessLevel as UserAccessLevel["id"]]
            .key,
          userPermissions,
        };
      }

      return { role, userPermissions };
    },
    [currentUser.id, currentUserPermissionsByProjectId, currentUserTenants],
  );
  return { getUserRole };
};
