import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldDefaultFormulaInteger } from "../../fields/FieldDefaultFormulaInteger";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import assert from "assert";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  default_formula: {
    value: string | undefined;
    isValid: boolean;
  };
};

interface PanelCreateColumnIntegerProps {
  onClose: () => void;
}

export const PanelCreateColumnInteger = ({
  onClose,
}: PanelCreateColumnIntegerProps) => {
  const createColumn = useDataTableContext((s) => s.createColumn);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: "",
      isValid: false,
    },
    default_formula: {
      value: undefined,
      isValid: true,
    },
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    assert(selectedTableId !== undefined);
    const { error } = await createColumn({
      tableId: selectedTableId,
      name: fields.name.value,
      defaultFormula: fields.default_formula.value,
      definition: {
        kind: "integer",
        min: null,
        max: null,
      },
      editable: true,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column created");
    } else {
      addUtilityToastFailure("Failed to create column");
    }

    onClose();
  };

  return (
    <EuiForm style={{ padding: 16 }}>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => handleFieldChange("name", field)}
      />

      <FieldDefaultFormulaInteger
        value={fields.default_formula.value}
        onChange={(field) => handleFieldChange("default_formula", field)}
      />

      <EuiSpacer />

      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            isLoading={isLoading}
            fill
            onClick={() => void handleSubmit()}
            disabled={Object.values(fields).some((field) => !field.isValid)}
          >
            Insert
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
};
