import { AnalysisTableGroup } from "../../graphql/_Types";
import { isDefined } from "utils/isDefined";
import { ModalConfirmDestructiveAction } from "components/ModalConfirmDestructiveAction/ModalConfirmDestructiveAction";
import { useState } from "react";
import { libraryAnalysisTableGroupDjango } from "hooks/libraryAnalysisTableGroupDjango";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

type ModalDeleteAnalysisTableGroupProps = {
  analysisTableGroupId: AnalysisTableGroup["id"];
  onClose?: () => void;
};

function ModalDeleteAnalysisTableGroup({
  analysisTableGroupId,
  onClose,
}: ModalDeleteAnalysisTableGroupProps) {
  const closeModal = () => {
    if (isDefined(onClose)) {
      onClose();
    }
  };

  const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);

  const deleteAnalysisTableGroup = async () => {
    try {
      await libraryAnalysisTableGroupDjango.DELETE(analysisTableGroupId);
      addUtilityToastSuccess("Successfully deleted analysis table group");
    } catch (err) {
      addUtilityToastFailure("Failed to delete analysis table group");
    }
  };

  const handleConfirm = async () => {
    setIsDeletionInProgress(true);
    await deleteAnalysisTableGroup();
    setIsDeletionInProgress(false);
    closeModal();
  };

  return (
    <ModalConfirmDestructiveAction
      title="Delete Analysis Table"
      onCancel={closeModal}
      onConfirm={(e) => {
        e.preventDefault();
        void handleConfirm();
      }}
      confirmButtonText="Delete Analysis Table"
      isLoading={isDeletionInProgress}
      confirmCheckboxPrompt="I wish to delete this Analysis Table"
      body={
        <>
          <p>
            You are about to delete an Analysis Table permanently along with all
            its data - this operation cannot be undone.
          </p>
        </>
      }
    />
  );
}

export default ModalDeleteAnalysisTableGroup;
